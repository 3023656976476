// components/VVIPCustomer.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './VVIPCustomer.css';
import { toast } from 'react-toastify';
import { FaStar, FaEdit, FaSave } from 'react-icons/fa';

const VVIPCustomer = ({ therapistId }) => {
  const [vvipCustomers, setVvipCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingNote, setEditingNote] = useState(null);
  const [tempNote, setTempNote] = useState('');

  useEffect(() => {
    if (therapistId) {
      fetchVVIPCustomers();
    }
  }, [therapistId]);

  const fetchVVIPCustomers = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/vvip-customers/${therapistId}`,
        {
          headers: {
            'x-auth-token': token,
          },
        }
      );

      setVvipCustomers(response.data);
    } catch (error) {
      console.error('Error fetching VVIP customers:', error);
      setError('Gagal memuat data pelanggan VVIP');
      toast.error('Gagal memuat data pelanggan VVIP');
    } finally {
      setLoading(false);
    }
  };

  const handleEditNote = (customerId, currentNote) => {
    setEditingNote(customerId);
    setTempNote(currentNote || '');
  };

  const handleSaveNote = async (customerId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/vvip-customers/${customerId}/notes`,
        { specialNotes: tempNote },
        {
          headers: {
            'x-auth-token': token,
          },
        }
      );

      setVvipCustomers(customers =>
        customers.map(customer =>
          customer._id === customerId
            ? { ...customer, specialNotes: tempNote }
            : customer
        )
      );

      setEditingNote(null);
      toast.success('Nota berjaya dikemaskini');
    } catch (error) {
      console.error('Error saving note:', error);
      toast.error('Gagal mengemaskini nota');
    }
  };

  const formatDate = (date) => {
    if (!date) return '-';
    return new Date(date).toLocaleString('ms-MY', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
  };

  if (loading) return <div className="loading-state">Memuatkan data pelanggan VVIP...</div>;
  if (error) return <div className="error-state">{error}</div>;
  if (!vvipCustomers.length) return <div className="empty-state">Tiada pelanggan VVIP ditemui.</div>;

  return (
    <div className="vvip-customer-list">
      <h2>
        <FaStar className="vvip-icon" /> Pelanggan VVIP
      </h2>
      <div className="table-container">
        <table className="vvip-table">
          <thead>
            <tr>
              <th>Nama</th>
              <th>No Telefon</th>
              <th>Jumlah Tempahan</th>
              <th>Tempahan Terakhir</th>
              <th>Rawatan Terkini</th>
              <th>Catatan Khas</th>
              <th>Tindakan</th>
            </tr>
          </thead>
          <tbody>
            {vvipCustomers.map((customer) => (
              <tr key={customer._id}>
                <td>
                  <div className="customer-name">
                    <FaStar className="vvip-star" />
                    {customer.name}
                  </div>
                </td>
                <td>{customer.phone}</td>
                <td>
                  <span className="booking-count">
                    {customer.totalBookings} kali
                  </span>
                </td>
                <td>{formatDate(customer.lastBookingDate)}</td>
                <td>
                  {customer.bookingHistory.length > 0
                    ? customer.bookingHistory[customer.bookingHistory.length - 1].treatment
                    : '-'}
                </td>
                <td>
                  {editingNote === customer._id ? (
                    <textarea
                      value={tempNote}
                      onChange={(e) => setTempNote(e.target.value)}
                      className="note-editor"
                      rows="2"
                    />
                  ) : (
                    <div className="special-notes">
                      {customer.specialNotes || '-'}
                    </div>
                  )}
                </td>
                <td>
                  {editingNote === customer._id ? (
                    <button
                      className="action-button save"
                      onClick={() => handleSaveNote(customer._id)}
                    >
                      <FaSave /> Simpan
                    </button>
                  ) : (
                    <button
                      className="action-button edit"
                      onClick={() => handleEditNote(customer._id, customer.specialNotes)}
                    >
                      <FaEdit /> Edit Nota
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default VVIPCustomer;