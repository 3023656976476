import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import './ResetPassword.css';
import config from '../config';



function ResetPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');
  const [expiryTime, setExpiryTime] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      await axios.post(`${config.API_URL}/therapists/reset-password`, { token, newPassword });
      setSuccess('Password berjaya ditukar, sila login.');
    } catch (error) {
      setError('Gagal untuk reset password, token tidak sah atau tamat tempoh.');
    }
  };

  useEffect(() => {
    const decodedToken = parseJwt(token);
    if (decodedToken && decodedToken.exp) {
      const expTime = decodedToken.exp * 1000; // Convert to milliseconds
      setExpiryTime(expTime);
    }
  }, [token]);

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    if (expiryTime) {
      const interval = setInterval(() => {
        const currentTime = Date.now();
        const remainingTime = expiryTime - currentTime;
        if (remainingTime <= 0) {
          clearInterval(interval);
          setTimeRemaining('Token has expired. Please request a new one.');
        } else {
          const minutes = Math.floor((remainingTime / 1000 / 60) % 60);
          const seconds = Math.floor((remainingTime / 1000) % 60);
          setTimeRemaining(`${minutes}m ${seconds}s remaining`);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [expiryTime]);

  return (
    <div className="reset-password-container">
      <div className="reset-password-form">
        <h2>Reset Password</h2>
        <form onSubmit={handleResetPassword}>
          <div>
            <label htmlFor="newPassword">Password Baru:</label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="confirmPassword">Sahkan Password Baru:</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit">Reset Password</button>
        </form>
        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">{success}</p>}
        {timeRemaining && <p className="time-remaining">{timeRemaining}</p>}
        <button onClick={() => navigate('/login')} className="back-button">Kembali ke Login</button>
      </div>
    </div>
  );
}

export default ResetPassword;
