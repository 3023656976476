// AdminDashboard.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ManageTherapists from './ManageTherapists';
import ManageBookings from './ManageBookings';
import DashboardStatistics from './DashboardStatistics';
import ManageTherapistContacts from './ManageTherapistContacts';
import WhatsappIntegrationAdmin from './WhatsappIntegrationAdmin';
import ManageSlots from './ManageSlots';
import ManageProspects from './ManageProspects';
import { 
  FaChartBar, 
  FaUserMd, 
  FaCalendarCheck, 
  FaUsers, 
  FaSignOutAlt, 
  FaWhatsapp, 
  FaClipboardList, 
  FaBars, 
  FaTimes,
  FaSearch
} from 'react-icons/fa';
import { Rings } from 'react-loader-spinner';
import './AdminDashboard.css';

function AdminDashboard() {
  const [activeTab, setActiveTab] = useState('dashboard');
  const [isLoading, setIsLoading] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State untuk Sidebar
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('userType');
    navigate('/login');
  };

  const handleTabClick = (tab) => {
    setIsLoading(true);
    setActiveTab(tab);
    setIsSidebarOpen(false); // Tutup Sidebar apabila klik tab
    // Simulasi pemuatan data, ganti dengan logik sebenar jika perlu
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  // Senarai semua tab
  const tabs = [
    { id: 'dashboard', label: 'Dashboard', icon: <FaChartBar /> },
    { id: 'manageTherapists', label: 'Urus Terapis', icon: <FaUserMd /> },
    { id: 'manageBookings', label: 'Urus Tempahan', icon: <FaCalendarCheck /> },
    { id: 'therapistContacts', label: 'Contact', icon: <FaUsers /> },
    { id: 'whatsappIntegrationAdmin', label: 'WhatsApp Integration', icon: <FaWhatsapp /> },
    { id: 'manageProspects', label: 'Prospects', icon: <FaClipboardList /> },
    { id: 'manageSlots', label: 'Slot', icon: <FaCalendarCheck /> },
  ];

  return (
    <div className={`admin-dashboard ${isSidebarOpen ? 'sidebar-open' : ''}`}>
      {/* Header dengan Hamburger Menu */}
<header className="dashboard-header">
  <button className="hamburger-menu" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
    {isSidebarOpen ? <FaTimes /> : <FaBars />}
  </button>
  <h1>Selamat datang, Admin!</h1>
  {/* Tombol Logout di Desktop */}
  <button className="logout-button desktop-only" onClick={handleLogout}>
    <FaSignOutAlt className="logout-icon" /> Logout
  </button>
</header>

      {/* Sidebar Navigation */}
      <aside className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <div className="sidebar-header">
          <h2>Dashboard Admin</h2>
          <button className="close-sidebar" onClick={() => setIsSidebarOpen(false)}>
            <FaTimes />
          </button>
        </div>
        <div className="sidebar-search">
          <FaSearch className="search-icon" />
          <input type="text" placeholder="Cari..." />
        </div>
        <nav className="sidebar-menu">
          {tabs.map(tab => (
            <button
              key={tab.id}
              onClick={() => handleTabClick(tab.id)}
              className={`sidebar-menu-item ${activeTab === tab.id ? 'active' : ''}`}
            >
              <span className="menu-icon">{tab.icon}</span>
              <span className="menu-label">{tab.label}</span>
            </button>
          ))}
          {/* Tambahkan Butang Logout dalam Sidebar */}
          <button
            onClick={handleLogout}
            className="sidebar-menu-item logout-button-sidebar"
          >
            <FaSignOutAlt className="menu-icon" /> Logout
          </button>
        </nav>
      </aside>

      {/* Overlay untuk menutup Sidebar apabila klik di luar */}
      {isSidebarOpen && <div className="overlay" onClick={() => setIsSidebarOpen(false)}></div>}

      {/* Spinner Global */}
      {isLoading && (
        <div className="spinner-overlay">
          <Rings
            height={80}
            width={80}
            color="#007bff"
            radius={6}
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="rings-loading"
          />
        </div>
      )}

      {/* Konten Tab */}
      <main className="tab-content">
        {activeTab === 'dashboard' && !isLoading && (
          <div className="content">
            <DashboardStatistics />
            <p>Selamat datang ke dashboard admin. Anda boleh mengurus sistem dari sini.</p>
          </div>
        )}
        {activeTab === 'manageTherapists' && !isLoading && <ManageTherapists />}
        {activeTab === 'therapistContacts' && !isLoading && <ManageTherapistContacts />}
        {activeTab === 'manageBookings' && !isLoading && <ManageBookings />}
        {activeTab === 'whatsappIntegrationAdmin' && !isLoading && <WhatsappIntegrationAdmin />}
        {activeTab === 'manageProspects' && !isLoading && <ManageProspects />}
        {activeTab === 'manageSlots' && !isLoading && <ManageSlots />}
      </main>
    </div>
  );
}

export default AdminDashboard;
