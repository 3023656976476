// EditProfileForm.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './EditProfileForm.css';
import InputField from './InputField';
import LocationSelector from './LocationSelector';
import config from '../config';

function EditProfileForm({ therapist, onUpdate, onCancel }) {
  const [formData, setFormData] = useState({
    name: therapist.name || '',
    email: therapist.email || '',
    phone: therapist.phone || '',
    location: therapist.location || { state: '', city: '' },
    jenisRawatan: therapist.jenisRawatan || {
      lelaki: false,
      wanita: false,
      rawatanLain: [],
    },
    image: therapist.image || '/images/user.png',
    bookingCutoffTime: therapist.bookingCutoffTime || 30,
    inCall: therapist.inCall || false,
    outCall: therapist.outCall || false,
    alamatKedai: therapist.alamatKedai || '',
    transportCharges: therapist.transportCharges || [],
    conflictGroups: therapist.conflictGroups || [],
  });

  const [newTreatment, setNewTreatment] = useState({ name: '', price: '' });
  const [newTransportCharge, setNewTransportCharge] = useState({
    km: '',
    price: '',
    isFlatRate: false,
  });
  const [newConflictGroupId, setNewConflictGroupId] = useState('');
  const [newConflictGroupTreatments, setNewConflictGroupTreatments] = useState([]);
  const [errors, setErrors] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);

  // State untuk rawatan sedia ada
  const [availableTreatments, setAvailableTreatments] = useState(
    therapist.jenisRawatan.rawatanLain || []
  );

  // State untuk notifikasi overlay
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    // Parse location jika ia adalah string
    if (typeof therapist.location === 'string') {
      const [state, city] = therapist.location.split(',').map(item => item.trim());
      setFormData(prevData => ({
        ...prevData,
        location: { state, city },
      }));
    } else if (therapist.location && typeof therapist.location === 'object') {
      setFormData(prevData => ({
        ...prevData,
        location: therapist.location,
      }));
    }

    // Pemeriksaan awal untuk plan free_trial
    if (therapist.subscriptionPlan === 'free_trial') {
      if (availableTreatments.length > 3) {
        const trimmedTreatments = availableTreatments.slice(0, 3);
        setFormData(prevData => ({
          ...prevData,
          jenisRawatan: {
            ...prevData.jenisRawatan,
            rawatanLain: trimmedTreatments,
          },
        }));
        setAvailableTreatments(trimmedTreatments);
        alert('Anda berada pada plan free_trial, rawatan tambahan telah dihapus dan hanya 3 rawatan yang disimpan.');
      }
    }
  }, [therapist, availableTreatments.length]);

  const validate = () => {
    const newErrors = {};

    if (!formData.name.trim()) newErrors.name = 'Nama diperlukan.';
    if (!formData.email.trim()) newErrors.email = 'Email diperlukan.';
    else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Format email tidak sah.';
    if (!formData.phone.trim()) newErrors.phone = 'Nombor telefon diperlukan.';
    if (!formData.location.state || !formData.location.city) newErrors.location = 'Sila pilih negeri dan bandar.';

    const { lelaki, wanita, rawatanLain } = formData.jenisRawatan;
    if (!lelaki && !wanita) newErrors.jenisRawatan = 'Sila pilih sekurang-kurangnya satu jenis gender untuk rawatan.';
    if (rawatanLain.length === 0) newErrors.rawatanLain = 'Sila pilih sekurang-kurangnya satu jenis rawatan.';

    if (formData.inCall && !formData.alamatKedai.trim()) {
      newErrors.alamatKedai = 'Alamat kedai diperlukan untuk in-call.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Fungsi untuk meng-handle perubahan input
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      jenisRawatan: { ...prevData.jenisRawatan, [name]: checked },
    }));
  };

  const handleToggleChange = (e) => {
    const { name, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleAddTreatment = () => {
    if (therapist.subscriptionPlan === 'free_trial' && availableTreatments.length >= 3) {
      setShowNotification(true);
      return;
    }

    if (newTreatment.name && newTreatment.price) {
      const newTreatmentString = `${newTreatment.name} RM${newTreatment.price}`;
      setFormData(prevData => ({
        ...prevData,
        jenisRawatan: {
          ...prevData.jenisRawatan,
          rawatanLain: [...prevData.jenisRawatan.rawatanLain, newTreatmentString],
        },
      }));
      setAvailableTreatments(prevTreatments => [...prevTreatments, newTreatmentString]);
      setNewTreatment({ name: '', price: '' });
    }
  };

  // Fungsi untuk memanggil endpoint penghapusan slot
  const deleteSlotsForTreatment = async (treatment) => {
    try {
      const therapistId = therapist._id;
      const token = localStorage.getItem('token');
      console.log('Token:', token); // Tambahkan log ini

      const response = await axios.delete(
        `${config.API_URL}/slots/clear/${therapistId}/${encodeURIComponent(treatment)}`,
        {
          headers: {
            'x-auth-token': token,
          },
        }
      );

      if (response.status === 200) {
        console.log(`All slots for treatment ${treatment} deleted successfully.`);
      } else {
        console.error('Error deleting slots:', response.data.error);
      }
    } catch (error) {
      console.error('Error deleting slots:', error.response?.data?.error || error.message);
      alert('Terjadi kesalahan saat menghapus rawatan dan slot terkait.');
    }
  };

  // Ubah handleDeleteTreatment menjadi async
  const handleDeleteTreatment = async (treatment) => {
    // Konfirmasi sebelum menghapus
    const confirmDelete = window.confirm(`Anda pasti ingin menghapus rawatan "${treatment}"? Semua slot terkait akan dihapus.`);
    if (!confirmDelete) return;

    try {
      // Panggil fungsi untuk menghapus slot
      await deleteSlotsForTreatment(treatment);

      // Hapus rawatan dari state
      setFormData(prevData => ({
        ...prevData,
        jenisRawatan: {
          ...prevData.jenisRawatan,
          rawatanLain: prevData.jenisRawatan.rawatanLain.filter(item => item !== treatment),
        },
      }));
      setAvailableTreatments(prevTreatments => prevTreatments.filter(item => item !== treatment));

      // Tampilkan pesan sukses jika diperlukan
      alert(`Rawatan "${treatment}" dan slot terkait telah dihapus.`);
    } catch (error) {
      console.error('Error deleting treatment and slots:', error);
      alert('Terjadi kesalahan saat menghapus rawatan dan slot terkait.');
    }
  };

  const handleAddTransportCharge = () => {
    if (newTransportCharge.price) {
      const charge = {
        km: newTransportCharge.isFlatRate
          ? null
          : newTransportCharge.km
          ? parseInt(newTransportCharge.km)
          : null,
        price: parseFloat(newTransportCharge.price),
        isFlatRate: newTransportCharge.isFlatRate,
      };

      setFormData(prevData => ({
        ...prevData,
        transportCharges: [...prevData.transportCharges, charge],
      }));
      setNewTransportCharge({ km: '', price: '', isFlatRate: false });
    } else {
      alert('Sila isi harga.');
    }
  };

  const handleDeleteTransportCharge = (indexToDelete) => {
    setFormData(prevData => ({
      ...prevData,
      transportCharges: prevData.transportCharges.filter((_, index) => index !== indexToDelete),
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    console.log('File selected:', file); // Log file details
  };

  const handleLocationChange = (newLocation) => {
    setFormData(prevData => ({
      ...prevData,
      location: newLocation,
    }));
  };

  // Fungsi untuk menambah conflict group baru
  const handleAddConflictGroup = () => {
    if (newConflictGroupId.trim() && newConflictGroupTreatments.length > 0) {
      const newGroup = {
        groupId: newConflictGroupId.trim(),
        treatments: [...newConflictGroupTreatments],
      };
      setFormData(prevData => ({
        ...prevData,
        conflictGroups: [...prevData.conflictGroups, newGroup],
      }));
      setNewConflictGroupId('');
      setNewConflictGroupTreatments([]);
    } else {
      alert('Sila masukkan ID kumpulan dan sekurang-kurangnya satu rawatan.');
    }
  };

  // Fungsi untuk mengubah pemilihan rawatan dalam conflict group
  const handleTreatmentSelection = (treatment) => {
    setNewConflictGroupTreatments(prevTreatments => {
      if (prevTreatments.includes(treatment)) {
        return prevTreatments.filter(t => t !== treatment);
      } else {
        return [...prevTreatments, treatment];
      }
    });
  };

  // Fungsi untuk menghapus conflict group
  const handleDeleteConflictGroup = (groupIdToDelete) => {
    setFormData(prevData => ({
      ...prevData,
      conflictGroups: prevData.conflictGroups.filter(group => group.groupId !== groupIdToDelete),
    }));
  };

  // Fungsi untuk menghapus rawatan dari conflict group
  const handleDeleteTreatmentFromConflictGroup = (groupId, treatmentToDelete) => {
    setFormData(prevData => ({
      ...prevData,
      conflictGroups: prevData.conflictGroups.map(group => {
        if (group.groupId === groupId) {
          return {
            ...group,
            treatments: group.treatments.filter(treatment => treatment !== treatmentToDelete),
          };
        }
        return group;
      }),
    }));
  };

  // Fungsi untuk mengendalikan toggle Flat Rate
  const handleToggleFlatRate = () => {
    setNewTransportCharge(prev => ({
      ...prev,
      isFlatRate: !prev.isFlatRate,
      price: '', // Reset harga apabila toggle diubah
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const formDataToSend = new FormData();

      // Append data ke FormData
      for (const key in formData) {
        if (key === 'jenisRawatan' || key === 'location' || key === 'conflictGroups') {
          formDataToSend.append(key, JSON.stringify(formData[key]));
        } else if (key === 'transportCharges') {
          formDataToSend.append('transportCharges', JSON.stringify(formData.transportCharges));
        } else {
          formDataToSend.append(key, formData[key]);
        }
      }

      if (selectedFile) {
        formDataToSend.append('profileImage', selectedFile);
      }

      // Tambahkan log untuk periksa semua data yang dihantar
      console.log('FormData content:');
      for (let pair of formDataToSend.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }

      onUpdate(formDataToSend);
    }
  };

  // Fungsi untuk menutup notifikasi
  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  return (
    <>
      {/* Notifikasi Overlay */}
      {showNotification && (
        <div className="notification-overlay">
          <div className="notification-content">
            <p>Anda berada pada plan free_trial, anda dibenarkan untuk tambah 3 sahaja rawatan, sila upgrade jika ingin tambah lebih rawatan.</p>
            <div className="notification-buttons">
              <button onClick={handleCloseNotification} className="close-button">Close</button>
              <a href={`https://wa.me/601160598203`} target="_blank" rel="noopener noreferrer" className="whatsapp-button">
                WhatsApp
              </a>
            </div>
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit} className="edit-profile-form">
        <h2>Edit Profile</h2>

        <InputField
          label="Name:"
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          required
          error={errors.name}
        />

        <InputField
          label="Email:"
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          required
          error={errors.email}
        />

        <InputField
          label="Phone:"
          type="tel"
          id="phone"
          name="phone"
          value={formData.phone}
          onChange={handleInputChange}
          required
          error={errors.phone}
        />

        <LocationSelector
          selectedState={formData.location.state}
          selectedCity={formData.location.city}
          onChange={handleLocationChange}
        />
        {errors.location && <span className="error-message">{errors.location}</span>}

        <fieldset className="form-group">
          <legend>Jenis Rawatan yang Disediakan untuk:</legend>
          <div className="toggle-group">
            <label className="toggle-label">
              Lelaki
              <div className="toggle-switch">
                <input
                  type="checkbox"
                  name="lelaki"
                  checked={formData.jenisRawatan.lelaki}
                  onChange={handleCheckboxChange}
                />
                <span className="toggle-slider"></span>
              </div>
            </label>
            <label className="toggle-label">
              Wanita
              <div className="toggle-switch">
                <input
                  type="checkbox"
                  name="wanita"
                  checked={formData.jenisRawatan.wanita}
                  onChange={handleCheckboxChange}
                />
                <span className="toggle-slider"></span>
              </div>
            </label>
          </div>
          {errors.jenisRawatan && <span className="error-message">{errors.jenisRawatan}</span>}
        </fieldset>

        <fieldset className="form-group">
          <legend>Jenis Rawatan:</legend>
          <div className="button-group">
            {formData.jenisRawatan.rawatanLain.map((rawatan) => (
              <div key={rawatan} className="treatment-item">
                <button
                  type="button"
                  className="active"
                  onClick={() => handleDeleteTreatment(rawatan)}
                >
                  {rawatan} <span className="delete-icon">×</span>
                </button>
              </div>
            ))}
          </div>
          <div className="add-treatment">
            <input
              type="text"
              placeholder="Nama rawatan"
              value={newTreatment.name}
              onChange={(e) => setNewTreatment({ ...newTreatment, name: e.target.value })}
            />
            <input
              type="number"
              placeholder="Harga (RM)"
              value={newTreatment.price}
              onChange={(e) => setNewTreatment({ ...newTreatment, price: e.target.value })}
            />
            <button type="button" onClick={handleAddTreatment}>Tambah</button>
          </div>
          {errors.rawatanLain && <span className="error-message">{errors.rawatanLain}</span>}
        </fieldset>

        <fieldset className="form-group">
          <legend>Conflict Groups:</legend>
          <div className="conflict-groups">
            {formData.conflictGroups.map((group) => (
              <div key={group.groupId} className="conflict-group-item">
                <strong>{group.groupId}</strong>
                <ul>
                  {group.treatments.map((treatment, index) => (
                    <li key={index}>
                      {treatment}
                      <button type="button" onClick={() => handleDeleteTreatmentFromConflictGroup(group.groupId, treatment)}>
                        &times;
                      </button>
                    </li>
                  ))}
                </ul>
                <button type="button" onClick={() => handleDeleteConflictGroup(group.groupId)}>
                  Hapus Group
                </button>
              </div>
            ))}
          </div>

          <div className="add-conflict-group">
            <input
              type="text"
              placeholder="Group ID"
              value={newConflictGroupId}
              onChange={(e) => setNewConflictGroupId(e.target.value)}
            />
            <div className="conflict-group-treatments">
              <label>Pilih Rawatan untuk Conflict Group:</label>
              {availableTreatments.map((treatment, index) => (
                <div key={index} className="toggle-item">
                  <label className="toggle-label">
                    {treatment}
                    <div className="toggle-switch">
                      <input
                        type="checkbox"
                        checked={newConflictGroupTreatments.includes(treatment)}
                        onChange={() => handleTreatmentSelection(treatment)}
                      />
                      <span className="toggle-slider"></span>
                    </div>
                  </label>
                </div>
              ))}
            </div>
            <button type="button" onClick={handleAddConflictGroup}>
              Tambah Conflict Group
            </button>
          </div>
        </fieldset>

        <fieldset className="form-group">
          <legend>Jenis Perkhidmatan:</legend>
          <div className="toggle-group">
            <label className="toggle-label">
              In-Call
              <div className="toggle-switch">
                <input
                  type="checkbox"
                  name="inCall"
                  checked={formData.inCall}
                  onChange={handleToggleChange}
                />
                <span className="toggle-slider"></span>
              </div>
            </label>
            {formData.inCall && (
              <div className="form-group">
                <label htmlFor="alamatKedai">Alamat Kedai:</label>
                <textarea
                  id="alamatKedai"
                  name="alamatKedai"
                  value={formData.alamatKedai}
                  onChange={handleInputChange}
                  rows="4"
                  className="large-textarea"
                  required
                />
                {errors.alamatKedai && <span className="error-message">{errors.alamatKedai}</span>}
              </div>
            )}
            <label className="toggle-label">
              Out-Call
              <div className="toggle-switch">
                <input
                  type="checkbox"
                  name="outCall"
                  checked={formData.outCall}
                  onChange={handleToggleChange}
                />
                <span className="toggle-slider"></span>
              </div>
            </label>
            {formData.outCall && (
              <>
                {/* Tambah Toggle Flat Rate */}
                <div className="flat-rate-toggle">
                  <label className="toggle-label">
                    Flat Rate
                    <div className="toggle-switch">
                      <input
                        type="checkbox"
                        checked={newTransportCharge.isFlatRate}
                        onChange={handleToggleFlatRate}
                      />
                      <span className="toggle-slider"></span>
                    </div>
                  </label>
                </div>

                {/* Input Flat Rate atau KM dan Harga */}
                <div className="add-transport-charge">
                  {newTransportCharge.isFlatRate ? (
                    <>
                      <input
                        type="number"
                        placeholder="Harga Flat Rate (RM)"
                        value={newTransportCharge.price}
                        onChange={(e) =>
                          setNewTransportCharge((prev) => ({
                            ...prev,
                            price: e.target.value,
                          }))
                        }
                        className="small-input"
                      />
                      <button
                        type="button"
                        onClick={handleAddTransportCharge}
                        className="add-button"
                      >
                        Tambah Flat Rate
                      </button>
                    </>
                  ) : (
                    <>
                      <input
                        type="number"
                        placeholder="Jumlah KM"
                        value={newTransportCharge.km}
                        onChange={(e) =>
                          setNewTransportCharge((prev) => ({
                            ...prev,
                            km: e.target.value,
                          }))
                        }
                        className="small-input"
                      />
                      <input
                        type="number"
                        placeholder="Harga (RM)"
                        value={newTransportCharge.price}
                        onChange={(e) =>
                          setNewTransportCharge((prev) => ({
                            ...prev,
                            price: e.target.value,
                          }))
                        }
                        className="small-input"
                      />
                      <button
                        type="button"
                        onClick={handleAddTransportCharge}
                        className="add-button"
                      >
                        Tambah
                      </button>
                    </>
                  )}
                </div>

                {/* Senarai Transport Charges */}
                <div className="button-group">
                  {formData.transportCharges.map((charge, index) => (
                    <div key={index} className="treatment-item">
                      <button
                        type="button"
                        className="active"
                        onClick={() => handleDeleteTransportCharge(index)}
                      >
                        {charge.isFlatRate
                          ? `Flat Rate - RM${charge.price}`
                          : charge.km
                          ? `${charge.km} km - RM${charge.price}`
                          : `Flat Rate - RM${charge.price}`}
                        <span className="delete-icon">×</span>
                      </button>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </fieldset>

        <div className="form-group">
          <label htmlFor="profileImage">Profile Image:</label>
          <input
            type="file"
            id="profileImage"
            name="profileImage"
            onChange={handleFileChange}
            accept="image/*"
          />
        </div>

        <div className="form-group">
          <label>Booking Cutoff Time (minutes):</label>
          <input
            type="number"
            name="bookingCutoffTime"
            value={formData.bookingCutoffTime}
            onChange={handleInputChange}
            min={0}
          />
        </div>

        <div className="form-actions">
          <button type="submit" className="save-button">
            Save Changes
          </button>
          <button type="button" onClick={onCancel} className="cancel-button">
            Cancel
          </button>
        </div>
      </form>
    </>
  );
}

export default EditProfileForm;
