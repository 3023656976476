// Pagination.js
import React from 'react';
import './Pagination.css';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = [];
  const maxDisplayPages = 5; // Bilangan maksimum halaman untuk dipaparkan sebelum ellipsis

  // Tentukan nomor halaman yang akan ditampilkan
  let startPage = Math.max(1, currentPage - Math.floor(maxDisplayPages / 2));
  let endPage = Math.min(totalPages, startPage + maxDisplayPages - 1);

  if (endPage - startPage < maxDisplayPages - 1) {
    startPage = Math.max(1, endPage - maxDisplayPages + 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="pagination-container">
      <button
        disabled={currentPage === 1}
        className="pagination-button"
        onClick={() => onPageChange(1)}
      >
        First
      </button>
      <button
        disabled={currentPage === 1}
        className="pagination-button"
        onClick={() => onPageChange(currentPage - 1)}
      >
        Prev
      </button>
      {startPage > 1 && (
        <button className="pagination-button" onClick={() => onPageChange(1)}>
          1
        </button>
      )}
      {startPage > 2 && <span className="pagination-ellipsis">...</span>}
      {pageNumbers.map(number => (
        <button
          key={number}
          className={`pagination-button ${number === currentPage ? 'active' : ''}`}
          onClick={() => onPageChange(number)}
        >
          {number}
        </button>
      ))}
      {endPage < totalPages - 1 && <span className="pagination-ellipsis">...</span>}
      {endPage < totalPages && (
        <button className="pagination-button" onClick={() => onPageChange(totalPages)}>
          {totalPages}
        </button>
      )}
      <button
        disabled={currentPage === totalPages}
        className="pagination-button"
        onClick={() => onPageChange(currentPage + 1)}
      >
        Next
      </button>
      <button
        disabled={currentPage === totalPages}
        className="pagination-button"
        onClick={() => onPageChange(totalPages)}
      >
        Last
      </button>
    </div>
  );
};

export default Pagination;
