// src/components/SalesOverview.js
import React from 'react';
import './SalesTab.css';
import { Doughnut, Line } from 'react-chartjs-2';
import Pagination from './Pagination';
import { FaSearch } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function SalesOverview(props) {
  // Ekstrak props yang diperlukan
  const {
    salesData,
    filteredData,
    groupedData,
    grandTotal,
    loading,
    error,
    selectedFilter,
    dateRange,
    startDate,
    endDate,
    searchQuery,
    setSelectedFilter, // Mungkin tidak diperlukan jika menggunakan applyPredefinedFilter
    setDateRange, // Mungkin tidak diperlukan jika menggunakan applyDateRangeFilter
    setSearchQuery, // Mungkin tidak diperlukan jika menggunakan applySearchFilter
    spaName,
    spaAddress,
    spaPhone,
    spaLogoUrl,
    showSpaModal,
    setShowSpaModal,
    pendingSaleForPDF,
    setPendingSaleForPDF,
    isEditingSpaInfo,
    setIsEditingSpaInfo,
    monthlyTarget,
    showTargetModal,
    setShowTargetModal,
    isEditingTarget,
    setIsEditingTarget,
    currentPage,
    setCurrentPage,
    itemsPerPage,
    flattenedData,
    totalPages,
    startIndex,
    currentPageData,
    onPageChange,
    donutData,
    donutOptions,
    lineData,
    lineOptions,
    monthlyTargetVal,
    progressPercent,
    handleSaveSpaInfo,
    handleSaveTarget,
    downloadReceipt,
    openEditSpaInfo,
    openTargetModal,
    getBase64FromUrl,
    applyDateRangeFilter, // Pastikan fungsi ini diimport atau didefinisikan
    applyPredefinedFilter, // Tambah ini
    applySearchFilter // Tambah ini
  } = props;

  return (
    <div>
      {(spaName || spaAddress || spaPhone) && (
        <div style={{ textAlign: 'right', marginBottom: '10px' }}>
          <button className="sales-tab-filter-button" onClick={openEditSpaInfo}>Edit Spa Info</button>
        </div>
      )}

      {/* Filters */}
      <div className="sales-tab-filters" style={{ position: 'relative', zIndex: 9999 }}>
        <div className="sales-tab-filter-group">
          <button
            className={`sales-tab-filter-button ${selectedFilter === 'All' ? 'active' : ''}`}
            onClick={() => applyPredefinedFilter('All')} // Ubah di sini
          >
            All
          </button>
          <button
            className={`sales-tab-filter-button ${selectedFilter === 'Today' ? 'active' : ''}`}
            onClick={() => applyPredefinedFilter('Today')} // Ubah di sini
          >
            Today
          </button>
          <button
            className={`sales-tab-filter-button ${selectedFilter === 'This Week' ? 'active' : ''}`}
            onClick={() => applyPredefinedFilter('This Week')} // Ubah di sini
          >
            This Week
          </button>
          <button
            className={`sales-tab-filter-button ${selectedFilter === 'This Month' ? 'active' : ''}`}
            onClick={() => applyPredefinedFilter('This Month')} // Ubah di sini
          >
            This Month
          </button>
          <button
            className={`sales-tab-filter-button ${selectedFilter === 'This Year' ? 'active' : ''}`}
            onClick={() => applyPredefinedFilter('This Year')} // Ubah di sini
          >
            This Year
          </button>
        </div>

        <div className="sales-tab-filter-group" style={{ position: 'relative', zIndex: 10000 }}>
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              // Tetapkan dateRange
              props.setDateRange(update);
              if (update[0] && update[1]) {
                applyDateRangeFilter(update[0], update[1]);
              }
            }}
            isClearable={true}
            placeholderText="Select Date Range"
            className="sales-tab-date-picker"
          />
        </div>

        <div className="sales-tab-filter-group sales-tab-search-group">
          <FaSearch className="sales-tab-search-icon" />
          <input
            type="text"
            placeholder="Cari..."
            value={searchQuery}
            onChange={(e) => applySearchFilter(e.target.value)} // Ubah di sini
            className="sales-tab-search-input"
          />
        </div>
      </div>

      {/* Graph Section */}
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', marginTop: '20px', justifyContent: 'space-between' }}>
        <div style={{ flex: '1 1 300px', minWidth: '300px', background: '#f9f9f9', padding: '20px', borderRadius: '10px' }}>
          <h4 style={{ textAlign: 'center' }}>Total Sales by Therapist</h4>
          {donutData.labels.length > 0 ? (
            <Doughnut data={donutData} options={donutOptions} />
          ) : (
            <p style={{ textAlign: 'center' }}>No data</p>
          )}
        </div>
        <div style={{ flex: '1 1 300px', minWidth: '300px', background: '#f9f9f9', padding: '20px', borderRadius: '10px' }}>
          <h4 style={{ textAlign: 'center' }}>Daily Sales Trend</h4>
          {lineData.labels.length > 0 ? (
            <Line data={lineData} options={lineOptions} />
          ) : (
            <p style={{ textAlign: 'center' }}>No data</p>
          )}
        </div>
        <div style={{ flex: '1 1 300px', minWidth: '300px', background: '#f9f9f9', padding: '20px', borderRadius: '10px', textAlign: 'center' }}>
          <h4>Monthly Target Progress</h4>
          {monthlyTargetVal === 0 && (
            <div>
              <p>Set your monthly sales target:</p>
              <button onClick={() => openTargetModal()} className="sales-tab-filter-button">Set Target</button>
            </div>
          )}
          {monthlyTargetVal > 0 && (
            <>
              <p>Target: RM {monthlyTargetVal.toFixed(2)}</p>
              <div style={{ width: '100%', background: '#eee', height: '20px', borderRadius: '10px', overflow: 'hidden', margin: '10px 0' }}>
                <div style={{
                  width: `${progressPercent}%`,
                  background: '#2ecc71',
                  height: '100%'
                }}></div>
              </div>
              <p>{progressPercent.toFixed(2)}% achieved</p>
              <button onClick={() => openTargetModal()} className="sales-tab-filter-button">Edit Target</button>
            </>
          )}
        </div>
      </div>

      {groupedData && Object.keys(groupedData).length === 0 ? (
        <div className="sales-tab-no-data">Tiada data jualan untuk dipaparkan.</div>
      ) : (
        <>
          {currentPageData.map((item, idx) => {
            const { therapistName, dateString, dailyData } = item;
            return (
              <div key={therapistName + dateString + idx} className="sales-tab-therapist-section">
                <h3 className="sales-tab-therapist-name">{therapistName}</h3>
                <div style={{ marginBottom: '30px' }}>
                  <h4 style={{ margin: '15px 0', color: '#2c3e50' }}>Tarikh: {dateString}</h4>
                  <table className="sales-tab-table">
                    <thead>
                      <tr>
                        <th>Tempahan</th>
                        <th>Nama Client</th>
                        <th>Telefon Client</th>
                        <th>Tarikh Booking</th>
                        <th>Slot Masa</th>
                        <th>Tarikh Slot</th>
                        <th>Nama Rawatan</th>
                        <th>Jumlah (RM)</th>
                        <th>Resit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dailyData.sales.map((sale, i) => (
                        <tr key={i}>
                          <td data-label="Tempahan">{sale.bookingId}</td>
                          <td data-label="Nama Client">{sale.clientName}</td>
                          <td data-label="Telefon Client">{sale.clientPhone}</td>
                          <td data-label="Tarikh Booking">{sale.bookingDate}</td>
                          <td data-label="Slot Masa">{sale.slotTime}</td>
                          <td data-label="Tarikh Slot">{sale.slotDate}</td>
                          <td data-label="Nama Rawatan">{sale.treatmentName}</td>
                          <td data-label="Jumlah">RM {sale.salesAmount.toFixed(2)}</td>
                          <td data-label="Resit">
                            <button onClick={() => downloadReceipt(sale)}>
                              Download PDF
                            </button>
                          </td>
                        </tr>
                      ))}
                      <tr className="sales-tab-total-row">
                        <td colSpan="9" style={{ textAlign: 'right' }}>
                          Jumlah Hari Ini: RM {dailyData.total.toFixed(2)}
                        </td>
                      </tr>
                      {Object.keys(dailyData.byTreatment).map((tName, i2) => {
                        const tData = dailyData.byTreatment[tName];
                        return (
                          <tr key={i2}>
                            <td colSpan="9" style={{ textAlign: 'right', fontStyle: 'italic', color: '#34495e' }}>
                              {tName}: {tData.count} order(s) | RM {tData.amount.toFixed(2)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            );
          })}
          <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />
        </>
      )}
      {grandTotal > 0 && (
        <div className="sales-tab-grand-total">
          <table className="sales-tab-grand-total-table">
            <tbody>
              <tr className="sales-tab-grand-total-row">
                <td>Jumlah Keseluruhan</td>
                <td>RM {grandTotal.toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default SalesOverview;
