// TherapistDashboard.js

import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './TherapistDashboard.css';
import EditProfileForm from './EditProfileForm';
import ChangePassword from './ChangePassword';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AvailableSlot from './AvailableSlot';
import MyBookings from './MyBookings';
import { FaCalendarAlt, FaCogs, FaBook, FaUser, FaLock, FaBars, FaEdit, FaCalendarCheck, FaDollarSign } from 'react-icons/fa';
import GoogleCalendarIntegration from './GoogleCalendarIntegration';
import config from '../config';
import CRMDashboard from './CRMDashboard';
import SubscriptionInfo from './SubscriptionInfo';
import WebhookTab from './WebhookTab';
import SalesTab from './SalesTab';


function TherapistDashboard() {
  const navigate = useNavigate();
  const [therapist, setTherapist] = useState(null);
  const [activeTab, setActiveTab] = useState('profile');
  const [isUpdating, setIsUpdating] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const token = localStorage.getItem('token');

  // Fungsi bantuan untuk memproses location
  const processLocation = (location) => {
    if (typeof location === 'object') {
      const processedLocation = `${location.state || ''}, ${location.city || ''}`.trim();
      return processedLocation === ',' ? 'Not specified' : processedLocation;
    }
    return location || 'Not specified';
  };

  const fetchTherapist = useCallback(async () => {
    try {
      const response = await axios.get(`${config.API_URL}/therapist-profile`, {
        headers: { 'x-auth-token': token },
      });
      const therapistData = response.data;

      // Pastikan location dalam format yang betul
      if (therapistData.location) {
        therapistData.location = processLocation(therapistData.location);
      }

      setTherapist(therapistData);
    } catch (error) {
      console.error('Error fetching therapist profile:', error);
      navigate('/login');
    }
  }, [token, navigate]);

  useEffect(() => {
    fetchTherapist();
  }, [fetchTherapist]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('userType');
    navigate('/login');
  };

  const handleUpdateProfile = async (updatedProfile) => {
    setIsUpdating(true);
    try {
      const response = await axios.put(
        `${config.API_URL}/update-therapist-profile`,
        updatedProfile,
        {
          headers: {
            'x-auth-token': token,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.data && response.data._id) {
        // Proses location
        if (response.data.location) {
          response.data.location = processLocation(response.data.location);
        }

        setTherapist(response.data);
        toast.success('Profil berjaya dikemas kini!');
        setActiveTab('profile');
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Gagal mengemas kini profil. Sila cuba lagi.');
    } finally {
      setIsUpdating(false);
    }
  };

  const handlePasswordChanged = () => {
    toast.success('Kata laluan berjaya ditukar!');
    setActiveTab('profile');
  };

  if (!therapist) {
    return <div>Loading...</div>;
  }

  // Definisikan akses tab berdasarkan subscriptionPlan
  const tabAccess = {
  plus: ['availableSlot', 'crm', 'webhook', 'myBookings', 'sales', 'profile', 'changePassword', 'integrateGoogleCalendar'], // Tambahkan 'sales'
  free_trial: ['availableSlot', 'myBookings', 'profile', 'changePassword'],
  basic: ['availableSlot', 'myBookings', 'profile', 'changePassword'],
};

  const availableTabs = tabAccess[therapist.subscriptionPlan] || [];

  // Definisikan semua tab dengan label dan ikon
  const allTabs = [
    { key: 'availableSlot', label: 'Available Slot', icon: <FaCalendarAlt /> },
    { key: 'crm', label: 'CRM', icon: <FaCogs /> },
    { key: 'webhook', label: 'Webhook', icon: <FaCogs /> },
    { key: 'myBookings', label: 'My Bookings', icon: <FaBook /> },
    { key: 'sales', label: 'Sales', icon: <FaDollarSign /> },
    { key: 'profile', label: 'Profile', icon: <FaUser /> },
    { key: 'changePassword', label: 'Change Password', icon: <FaLock /> },
    { key: 'integrateGoogleCalendar', label: 'Integrate Google Calendar', icon: <FaCalendarCheck /> },
  ];

  // Fungsi untuk menutup menu mobile saat memilih tab
  const handleTabClick = (tabKey) => {
    if (availableTabs.includes(tabKey)) {
      setActiveTab(tabKey);
      setIsMobileMenuOpen(false);
    } else {
      setShowUpgradeModal(true);
    }
  };

  // Fungsi untuk menutup overlay notifikasi
  const handleCloseModal = () => {
    setShowUpgradeModal(false);
  };

  // Fungsi untuk membuka WhatsApp
  const handleWhatsApp = () => {
    window.open('https://wa.me/601160598203', '_blank');
  };

  return (
    <div className="therapist-dashboard">
      <div className="dashboard-header">
        {/* Bahagian Kiri: Tajuk */}
        <div className="header-left desktop-only">
          <h1>Therapist Dashboard</h1>
        </div>

        {/* Bahagian Tengah: Tab Buttons */}
        <div className="header-center desktop-only">
          <div className="tab-buttons">
            {allTabs.map(tab => {
              const isAccessible = availableTabs.includes(tab.key);
              return (
                <button
                  key={tab.key}
                  className={`tab-button ${activeTab === tab.key ? 'active' : ''} ${!isAccessible ? 'disabled' : ''}`}
                  onClick={() => handleTabClick(tab.key)}
                  title={isAccessible ? tab.label : 'Tab ini tidak tersedia pada plan anda'}
                >
                  {tab.icon} {tab.label}
                </button>
              );
            })}
          </div>
        </div>

        {/* Bahagian Kanan: Maklumat Langganan dan Logout */}
        <div className="header-right desktop-only">
          <SubscriptionInfo
            subscriptionPlan={therapist.subscriptionPlan}
            subscriptionStartDate={therapist.subscriptionStartDate}
            subscriptionEndDate={therapist.subscriptionEndDate}
          />
          <button onClick={handleLogout} className="logout-button">
            Logout
          </button>
        </div>

        {/* Butang Menu Mobile */}
        <button className="mobile-menu-button mobile-only" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
          <FaBars />
        </button>
      </div>

      {/* Menu Mobile */}
      {isMobileMenuOpen && (
        <div className="mobile-menu mobile-only">
          <SubscriptionInfo
            subscriptionPlan={therapist.subscriptionPlan}
            subscriptionStartDate={therapist.subscriptionStartDate}
            subscriptionEndDate={therapist.subscriptionEndDate}
          />
          {allTabs.map(tab => {
            const isAccessible = availableTabs.includes(tab.key);
            return (
              <button
                key={tab.key}
                className={`mobile-tab-button ${activeTab === tab.key ? 'active' : ''} ${!isAccessible ? 'disabled' : ''}`}
                onClick={() => handleTabClick(tab.key)}
                title={isAccessible ? tab.label : 'Tab ini tidak tersedia pada plan anda'}
              >
                {tab.icon} {tab.label}
              </button>
            );
          })}
          <button onClick={handleLogout} className="mobile-logout-button">
            <FaLock /> Logout
          </button>
        </div>
      )}

      <div className="dashboard-content">
        {activeTab === 'profile' && (
          <div className="profile-card">
            <div className="profile-header">
              <img
                src={therapist.image || '/images/user.png'}
                alt={`${therapist.name} profile`}
                className="therapist-image"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/images/user.png';
                }}
              />

              <h2>{therapist.name.toUpperCase()}</h2>
            </div>
            <div className="profile-details">
              <div className="detail-item">
                <span className="detail-label">Phone:</span>
                <span className="detail-value">{therapist.phone}</span>
              </div>
              <div className="detail-item">
                <span className="detail-label">Email:</span>
                <span className="detail-value">{therapist.email}</span>
              </div>
              <div className="detail-item">
                <span className="detail-label">Location:</span>
                <span className="detail-value">
                  {therapist.location || 'Not specified'}
                </span>
              </div>
              <div className="detail-item">
                <span className="detail-label">Jenis Perkhidmatan:</span>
                <span className="detail-value">
                  {therapist.inCall && therapist.outCall
                    ? 'In-Call & Out-Call'
                    : therapist.inCall
                    ? 'In-Call'
                    : therapist.outCall
                    ? 'Out-Call'
                    : 'Tidak dinyatakan'}
                </span>
              </div>

              <div className="detail-item">
                <span className="detail-label">Jenis rawatan yang disediakan untuk:</span>
                <span className="detail-value">
                  {therapist.jenisRawatan.lelaki && therapist.jenisRawatan.wanita
                    ? 'Lelaki & Wanita'
                    : therapist.jenisRawatan.lelaki
                    ? 'Lelaki'
                    : 'Wanita'}
                </span>
              </div>
              <div className="detail-item">
                <span className="detail-label">Jenis Rawatan:</span>
                <div className="treatment-types">
                  {therapist.jenisRawatan.rawatanLain.map((rawatan, index) => (
                    <span key={index} className="treatment-tag">
                      {rawatan}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            {/* Butang Edit Profile Ditambahkan di Sini */}
            <div className="profile-actions">
              <button onClick={() => setActiveTab('editProfile')} className="edit-profile-button">
                <FaEdit /> Edit Profile
              </button>
            </div>
          </div>
        )}
        {activeTab === 'availableSlot' && (
          <AvailableSlot therapist={therapist} />
        )}
        {activeTab === 'webhook' && (
          <WebhookTab />
        )}
        {activeTab === 'myBookings' && (
          <MyBookings therapist={therapist} />
        )}
        {activeTab === 'editProfile' && (
          <EditProfileForm
            therapist={therapist}
            onUpdate={handleUpdateProfile}
            onCancel={() => setActiveTab('profile')}
          />
        )}
        {activeTab === 'changePassword' && (
          <ChangePassword 
            therapistId={therapist._id}
            onPasswordChanged={handlePasswordChanged}
          />
        )}
        {activeTab === 'integrateGoogleCalendar' && (
          <GoogleCalendarIntegration token={token} therapistId={therapist._id} />
        )}
        {activeTab === 'crm' && (
          <CRMDashboard />
        )}
        {activeTab === 'sales' && (
    <SalesTab therapistId={therapist._id} token={token} />
  )}
        {isUpdating && <div className="loader">Updating profile...</div>}
      </div>

      {/* Bottom Navigation for Mobile */}
      <div className="bottom-navigation mobile-only">
        {/* Ambil hanya maksimum 4 tab fungsional */}
        {availableTabs.slice(0, 4).map(tabKey => {
          const tab = allTabs.find(t => t.key === tabKey);
          if (!tab) return null;
          return (
            <button
              key={tab.key}
              className={`bottom-nav-button ${activeTab === tab.key ? 'active' : ''}`}
              onClick={() => handleTabClick(tab.key)}
              title={tab.label}
            >
              {tab.icon}
              <span>{tab.label}</span>
            </button>
          );
        })}
        {/* Butang Logout sebagai butang kelima */}
        <button
          className={`bottom-nav-button ${activeTab === 'logout' ? 'active' : ''}`}
          onClick={handleLogout}
          title="Logout"
        >
          <FaLock />
          <span>Logout</span>
        </button>
      </div>

      {/* Overlay Notifikasi untuk Upgrade Plan */}
      {showUpgradeModal && (
        <div className="upgrade-modal-overlay">
          <div className="upgrade-modal">
            <h2>Upgrade Plan</h2>
            <p>
              Anda sekarang berada di plan <strong>{therapist.subscriptionPlan}</strong>. Untuk mendapatkan akses penuh, sila WhatsApp Tuan Akmal untuk upgrade ke plan Plus.
            </p>
            <div className="modal-buttons">
              <button onClick={handleCloseModal} className="close-button">
                Close
              </button>
              <button onClick={handleWhatsApp} className="whatsapp-button">
                WhatsApp
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TherapistDashboard;
