// src/components/SalesTab.js
import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import config from '../config';
import './SalesTab.css';
// Import sub-components
import SalesOverview from './SalesOverview';
import AccountFinancial from './AccountFinancial';
import PinForm from './PinForm'; // Import PinForm
import { toast } from 'react-toastify'; // Import toast
import Spinner from './Spinner'; // Import Spinner

import { Doughnut, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement, Tooltip, Legend,
  CategoryScale, LinearScale, PointElement, LineElement
} from 'chart.js';

import ChartDataLabels from 'chartjs-plugin-datalabels';

// Import yang diperlukan
import moment from 'moment'; // Import moment
import debounce from 'lodash.debounce'; // Import debounce dari lodash.debounce
import jsPDF from 'jspdf'; // Import jsPDF
import 'jspdf-autotable'; // Import jsPDF-AutoTable jika digunakan

ChartJS.register(
  ArcElement, Tooltip, Legend,
  CategoryScale, LinearScale, PointElement, LineElement,
  ChartDataLabels
);

function SalesTab({ therapistId, token }) {
  // State untuk PIN
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showPinForm, setShowPinForm] = useState(false);
  const [pinChecked, setPinChecked] = useState(false);
  const [loadingPin, setLoadingPin] = useState(true); // Spinner state untuk PIN

  // State untuk sub-tab
  const [activeSubTab, setActiveSubTab] = useState('overview');

  // State lain seperti sebelum ini
  const [salesData, setSalesData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [groupedData, setGroupedData] = useState({});
  const [grandTotal, setGrandTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Filter states
  const [selectedFilter, setSelectedFilter] = useState('All');
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [searchQuery, setSearchQuery] = useState('');

  // Spa info states
  const [spaName, setSpaName] = useState('');
  const [spaAddress, setSpaAddress] = useState('');
  const [spaPhone, setSpaPhone] = useState('');
  const [spaLogoUrl, setSpaLogoUrl] = useState('');
  const [showSpaModal, setShowSpaModal] = useState(false);
  const [pendingSaleForPDF, setPendingSaleForPDF] = useState(null);
  const [isEditingSpaInfo, setIsEditingSpaInfo] = useState(false);

  // KPI target monthly
  const [monthlyTarget, setMonthlyTarget] = useState('');
  const [showTargetModal, setShowTargetModal] = useState(false);
  const [isEditingTarget, setIsEditingTarget] = useState(false);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // 5 tarikh per page

  useEffect(() => {
    const storedSpaInfo = JSON.parse(localStorage.getItem('spaInfo'));
    if (storedSpaInfo) {
      setSpaName(storedSpaInfo.spaName || '');
      setSpaAddress(storedSpaInfo.spaAddress || '');
      setSpaPhone(storedSpaInfo.spaPhone || '');
      setSpaLogoUrl(storedSpaInfo.spaLogoUrl || '');
    }
    const storedTarget = localStorage.getItem('monthlyTarget');
    if (storedTarget) {
      setMonthlyTarget(storedTarget);
    }
  }, []);

  const formatObjectId = (id) => {
    return id ? id.substring(id.length - 5) : 'N/A';
  };

  // Definisikan fungsi checkPin di luar useEffect supaya boleh digunakan di mana-mana dalam komponen
  const checkPin = async () => {
    try {
      const res = await fetch('/pin', {
        headers: {
          'Cache-Control': 'no-cache',
          'Therapist-ID': therapistId,
        },
      });
      const data = await res.json();
      if (data.exists) {
        setPinChecked(true);
        setShowPinForm(true); // Selalu tunjukkan PinForm untuk verifikasi jika PIN ada
      } else {
        setPinChecked(false);
        setShowPinForm(true); // Tunjukkan PinForm untuk set PIN baru
      }
    } catch (error) {
      toast.error('Ralat semasa menyemak PIN.');
    } finally {
      setLoadingPin(false);
    }
  };

  // Logika PIN
  useEffect(() => {
    if (therapistId) {
      checkPin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [therapistId]);

  const handlePinSuccess = () => {
    setIsAuthenticated(true);
    setShowPinForm(false);
  };

  // Fetch sales setelah PIN terverifikasi
  useEffect(() => {
    if (isAuthenticated) {
      fetchSales();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const fetchSales = async () => {
    try {
      const storedToken = token || localStorage.getItem('token');
      if (!storedToken) {
        throw new Error('Token tidak wujud.');
      }

      const response = await axios.get(
        `${config.API_URL}/api/sales/therapist/${therapistId}`,
        {
          headers: { 'x-auth-token': storedToken },
        }
      );
      setSalesData(response.data);
      setFilteredData(response.data);
    } catch (err) {
      console.error('Error fetching sales data:', err);
      setError(err.response?.data?.msg || 'Gagal mengambil data jualan.');
    } finally {
      setLoading(false);
    }
  };

  const groupSalesByTherapist = (data) => {
    const grouped = {};
    let total = 0;

    data.forEach((sale) => {
      const saleDateMoment = moment(sale.saleDate || sale.createdAt);
      sale.therapists.forEach((therapistData) => {
        const therapistName = therapistData?.therapist?.name || 'Tidak Diketahui';
        if (!grouped[therapistName]) {
          grouped[therapistName] = {};
        }

        const saleDateStr = saleDateMoment.format('DD/M/YYYY');

        if (!grouped[therapistName][saleDateStr]) {
          grouped[therapistName][saleDateStr] = {
            sales: [],
            total: 0,
            byTreatment: {}
          };
        }

        const bookingData = sale.booking || {};
        const slotData = bookingData.slotId || {};
        const treatmentName = therapistData?.treatment?.name || 'Tidak Diketahui';

        const saleObj = {
          bookingId: formatObjectId(bookingData._id),
          clientName: bookingData.userName || 'Tidak Diketahui',
          clientPhone: bookingData.userPhone || 'Tidak Diketahui',
          bookingDate: bookingData.bookingDate ? moment(bookingData.bookingDate).format('DD/M/YYYY') : 'Tidak Diketahui',
          slotTime: slotData.startTime && slotData.endTime ? `${slotData.startTime} - ${slotData.endTime}` : 'Tidak Diketahui',
          slotDate: slotData.date ? moment(slotData.date).format('DD/M/YYYY') : 'Tidak Diketahui',
          treatmentName: treatmentName,
          salesAmount: therapistData.salesAmount,
          saleDate: saleDateStr,
        };

        grouped[therapistName][saleDateStr].sales.push(saleObj);
        grouped[therapistName][saleDateStr].total += therapistData.salesAmount;
        total += therapistData.salesAmount;

        if (!grouped[therapistName][saleDateStr].byTreatment[treatmentName]) {
          grouped[therapistName][saleDateStr].byTreatment[treatmentName] = {
            count: 0,
            amount: 0
          };
        }

        grouped[therapistName][saleDateStr].byTreatment[treatmentName].count += 1;
        grouped[therapistName][saleDateStr].byTreatment[treatmentName].amount += therapistData.salesAmount;
      });
    });

    setGroupedData(grouped);
    setGrandTotal(total);
  };

  const applyPredefinedFilter = (filter) => {
    setSelectedFilter(filter);
    const today = moment();

    let filtered = salesData.filter((sale) => {
      const saleDate = moment(sale.saleDate || sale.createdAt);
      switch (filter) {
        case 'Today':
          return saleDate.isSame(today, 'day');
        case 'This Week':
          return saleDate.isSame(today, 'week');
        case 'This Month':
          return saleDate.isSame(today, 'month');
        case 'This Year':
          return saleDate.isSame(today, 'year');
        case 'All':
        default:
          return true;
      }
    });

    setFilteredData(filtered);
  };

  const applyDateRangeFilter = (start, end) => {
    if (start && end) {
      setSelectedFilter('Custom');
      const filtered = salesData.filter((sale) => {
        const saleDate = moment(sale.saleDate || sale.createdAt);
        return saleDate.isBetween(moment(start).startOf('day'), moment(end).endOf('day'), null, '[]');
      });
      setFilteredData(filtered);
    } else {
      setSelectedFilter('All');
      setFilteredData(salesData);
    }
  };

  const applySearchFilter = useCallback(
    debounce((query) => {
      setSearchQuery(query);
      if (query.trim() === '') {
        setFilteredData(salesData);
      } else {
        const lowerQuery = query.toLowerCase();
        const searched = salesData.filter((sale) => {
          const bookingData = sale.booking || {};
          const slotData = bookingData.slotId || {};
          const treatmentMatch = sale.therapists.some((therapistData) =>
            therapistData?.treatment?.name.toLowerCase().includes(lowerQuery)
          );

          const saleDateStr = (sale.saleDate ? moment(sale.saleDate) : moment(sale.createdAt)).format('DD/M/YYYY');

          return (
            formatObjectId(bookingData._id).toLowerCase().includes(lowerQuery) ||
            (bookingData.userName && bookingData.userName.toLowerCase().includes(lowerQuery)) ||
            (bookingData.userPhone && bookingData.userPhone.toLowerCase().includes(lowerQuery)) ||
            (bookingData.bookingDate && moment(bookingData.bookingDate).format('DD/M/YYYY').includes(lowerQuery)) ||
            (slotData.startTime && slotData.startTime.toLowerCase().includes(lowerQuery)) ||
            (slotData.endTime && slotData.endTime.toLowerCase().includes(lowerQuery)) ||
            (slotData.date && moment(slotData.date).format('DD/M/YYYY').includes(lowerQuery)) ||
            treatmentMatch ||
            saleDateStr.toLowerCase().includes(lowerQuery)
          );
        });
        setFilteredData(searched);
      }
    }, 300),
    [salesData]
  );

  useEffect(() => {
    if (isAuthenticated) {
      if (filteredData.length > 0) {
        groupSalesByTherapist(filteredData);
      } else {
        setGroupedData({});
        setGrandTotal(0);
      }
      setCurrentPage(1); // reset page if filtered
    }
  }, [filteredData, isAuthenticated]);

  const handleSaveSpaInfo = () => {
    const spaData = {
      spaName,
      spaAddress,
      spaPhone,
      spaLogoUrl
    };
    localStorage.setItem('spaInfo', JSON.stringify(spaData));
    setShowSpaModal(false);
    setIsEditingSpaInfo(false);

    if (pendingSaleForPDF) {
      generateReceiptPDF(pendingSaleForPDF);
      setPendingSaleForPDF(null);
    }
  };

  const handleSaveTarget = () => {
    localStorage.setItem('monthlyTarget', monthlyTarget);
    setShowTargetModal(false);
    setIsEditingTarget(false);
  };

  const generateReceiptPDF = async (sale) => {
    const currentDate = moment().format('DD/M/YYYY, h:mm A');
    const doc = new jsPDF('p', 'pt', 'a4');

    let yOffset = 40;
    if (spaLogoUrl) {
      try {
        const imgData = await getBase64FromUrl(spaLogoUrl); 
        doc.addImage(imgData, 'JPEG', 40, yOffset, 50, 50); 
      } catch (e) {
        console.warn("Gagal load logo, pastikan URL betul atau guna base64!");
      }
    }

    doc.setFontSize(16);
    doc.setFont('helvetica', 'bold');
    const textX = spaLogoUrl ? 100 : 40;
    doc.text(spaName, textX, yOffset + 20);

    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal');

    const wrapWidth = 400; 
    const addressLines = doc.splitTextToSize(spaAddress, wrapWidth);
    let currentY = yOffset + 35;
    addressLines.forEach(line => {
      doc.text(line, textX, currentY);
      currentY += 12;
    });
    doc.text(spaPhone, textX, currentY);

    currentY += 20;
    doc.setDrawColor(0);
    doc.setLineWidth(1);
    doc.line(40, currentY, 550, currentY);

    currentY += 20;
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text("Resit Pembayaran", 40, currentY);
    currentY += 20;

    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal');

    const bookingInfo = [
      ["No Tempahan:", sale.bookingId],
      ["Nama Client:", sale.clientName],
      ["Telefon Client:", sale.clientPhone],
      ["Tarikh Booking:", sale.bookingDate],
      ["Tarikh Slot:", sale.slotDate],
      ["Slot Masa:", sale.slotTime]
    ];

    bookingInfo.forEach((row) => {
      doc.setFont('helvetica', 'bold');
      doc.text(row[0], 40, currentY);
      doc.setFont('helvetica', 'normal');
      doc.text(row[1], 150, currentY);
      currentY += 15;
    });

    currentY += 10;

    const treatmentData = [
      [
        { content: "Nama Rawatan", styles: { halign: 'left', fontStyle: 'bold', fillColor: [41,128,185], textColor: 255 } },
        { content: "Harga (RM)", styles: { halign: 'right', fontStyle: 'bold', fillColor: [41,128,185], textColor: 255 } }
      ],
      [
        { content: sale.treatmentName, styles: { halign: 'left' } },
        { content: sale.salesAmount.toFixed(2), styles: { halign: 'right' } }
      ]
    ];

    doc.autoTable({
      startY: currentY,
      margin: { left: 40, right: 40 },
      bodyStyles: { font: 'helvetica', fontSize: 10 },
      theme: 'grid',
      body: treatmentData
    });

    let finalY = doc.lastAutoTable.finalY + 20;

    doc.setFont('helvetica', 'bold');
    doc.text(`Jumlah: RM ${sale.salesAmount.toFixed(2)}`, 40, finalY);
    doc.setFont('helvetica', 'normal');
    doc.text(`Tarikh Cetak: ${currentDate}`, 40, finalY + 20);

    doc.setFont('helvetica', 'italic');
    doc.text("Terima kasih atas sokongan anda!", 40, finalY + 40);

    doc.save(`Resit_${sale.bookingId}.pdf`);
  };

  async function getBase64FromUrl(url) {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => { resolve(reader.result); };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  const downloadReceipt = (sale) => {
    if (!spaName || !spaAddress || !spaPhone) {
      setPendingSaleForPDF(sale);
      setShowSpaModal(true);
      setIsEditingSpaInfo(false);
    } else {
      generateReceiptPDF(sale);
    }
  };

  const openEditSpaInfo = () => {
    setIsEditingSpaInfo(true);
    setShowSpaModal(true);
    setPendingSaleForPDF(null);
  };

  const openTargetModal = () => {
    setIsEditingTarget(true);
    setShowTargetModal(true);
  };

  // Flatten data for pagination
  const flattenedData = [];
  Object.entries(groupedData).forEach(([therapistName, datesObj]) => {
    Object.entries(datesObj).forEach(([dateString, dailyData]) => {
      flattenedData.push({ therapistName, dateString, dailyData });
    });
  });

  const totalPages = Math.ceil(flattenedData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentPageData = flattenedData.slice(startIndex, startIndex + itemsPerPage);

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  // Prepare data for graphs
  // Donut chart per therapist total
  const therapistTotals = {};
  Object.entries(groupedData).forEach(([therapistName, datesObj]) => {
    let sum = 0;
    Object.values(datesObj).forEach(d => {
      sum += d.total;
    });
    therapistTotals[therapistName] = sum;
  });
  const therapistNames = Object.keys(therapistTotals);
  const therapistValues = Object.values(therapistTotals);

  const donutData = {
    labels: therapistNames,
    datasets: [
      {
        data: therapistValues,
        backgroundColor: ['#3498db','#e74c3c','#2ecc71','#9b59b6','#f1c40f'],
      }
    ]
  };

  const donutOptions = {
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        display: true,
        color: '#000',
        font: {
          weight: 'bold'
        },
        formatter: (value, ctx) => {
          return `RM ${value}`;
        }
      }
    }
  };

  // Daily total for line chart
  const dailyTotalsMap = {};
  flattenedData.forEach(item => {
    const { dateString, dailyData } = item;
    const dateKey = moment(dateString, 'DD/M/YYYY').format('YYYY-MM-DD');
    if (!dailyTotalsMap[dateKey]) dailyTotalsMap[dateKey] = 0;
    dailyTotalsMap[dateKey] += dailyData.total;
  });

  const sortedDates = Object.keys(dailyTotalsMap).sort((a,b) => moment(a).diff(moment(b)));
  const lineData = {
    labels: sortedDates.map(d=>moment(d).format('DD/M/YYYY')),
    datasets: [
      {
        label: 'Daily Sales',
        data: sortedDates.map(d => dailyTotalsMap[d]),
        borderColor: '#2980b9',
        fill: false,
        tension:0.1
      }
    ]
  };

  const lineOptions = {
    plugins: {
      legend: {
        display: true
      },
      datalabels: {
        display: true,
        color: '#000',
        align: 'top',
        font: {
          weight: 'bold'
        },
        formatter: (value) => `RM ${value}`
      }
    },
    scales: {
      x: { display: true },
      y: { display: true, beginAtZero: true }
    }
  };

  // KPI progress
  const monthlyTargetVal = Number(monthlyTarget) || 0;
  const progressPercent = monthlyTargetVal > 0 ? Math.min((grandTotal/monthlyTargetVal)*100, 100) : 0;

  // Kondisi Render

  // Jika masih memeriksa PIN, tunjukkan spinner
  if (loadingPin) {
    return (
      <div className="spinner-container">
        <Spinner type="ClipLoader" size={50} color="#2980b9" label="Memuatkan..." />
      </div>
    );
  }

  // Jika PIN perlu dimasukkan/verifikasi, render PinForm
  if (showPinForm) {
    return (
      <PinForm
        therapistId={therapistId}
        onSuccess={handlePinSuccess}
        isSetting={!pinChecked}
      />
    );
  }

  // Jika data sedang dimuat, tunjukkan spinner
  if (loading) {
    return (
      <div className="spinner-container">
        <Spinner type="ClipLoader" size={50} color="#2980b9" label="Memuatkan..." />
      </div>
    );
  }

  // Jika terjadi error
  if (error) {
    return (
      <div className="sales-tab-error">
        <p>{error}</p>
      </div>
    );
  }

  // Jika data jualan tidak ada
  if (!loading && salesData.length === 0) {
    return (
      <div className="sales-tab-no-data">
        <p>Tiada data jualan tersedia.</p>
      </div>
    );
  }

  return (
    <div className="sales-tab">
      <h2 className="sales-tab-heading">Sales</h2>

      {/* Sub-Tab Navigation */}
      <div className="sales-tab-subtabs">
        <button
          className={`sales-tab-subtab-button ${activeSubTab === 'overview' ? 'active' : ''}`}
          onClick={() => setActiveSubTab('overview')}
        >
          Sales Overview
        </button>
        <button
          className={`sales-tab-subtab-button ${activeSubTab === 'financial' ? 'active' : ''}`}
          onClick={() => setActiveSubTab('financial')}
        >
          Account Financial
        </button>
      </div>

      {/* Render sub-tab content */}
      <div className="sales-tab-subtab-content">
        {activeSubTab === 'overview' && (
          <SalesOverview
            // Pass necessary props here
            therapistId={therapistId}
            token={token}
            salesData={salesData}
            filteredData={filteredData}
            groupedData={groupedData}
            grandTotal={grandTotal}
            loading={loading}
            error={error}
            selectedFilter={selectedFilter}
            dateRange={dateRange}
            startDate={startDate}
            endDate={endDate}
            searchQuery={searchQuery}
            setSelectedFilter={setSelectedFilter}
            setDateRange={setDateRange}
            setSearchQuery={setSearchQuery}
            spaName={spaName}
            spaAddress={spaAddress}
            spaPhone={spaPhone}
            spaLogoUrl={spaLogoUrl}
            showSpaModal={showSpaModal}
            setShowSpaModal={setShowSpaModal}
            pendingSaleForPDF={pendingSaleForPDF}
            setPendingSaleForPDF={setPendingSaleForPDF}
            isEditingSpaInfo={isEditingSpaInfo}
            setIsEditingSpaInfo={setIsEditingSpaInfo}
            monthlyTarget={monthlyTarget}
            showTargetModal={showTargetModal}
            setShowTargetModal={setShowTargetModal}
            isEditingTarget={isEditingTarget}
            setIsEditingTarget={setIsEditingTarget}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            itemsPerPage={itemsPerPage}
            flattenedData={flattenedData}
            totalPages={totalPages}
            startIndex={startIndex}
            currentPageData={currentPageData}
            onPageChange={onPageChange}
            donutData={donutData}
            donutOptions={donutOptions}
            lineData={lineData}
            lineOptions={lineOptions}
            monthlyTargetVal={monthlyTargetVal}
            progressPercent={progressPercent}
            handleSaveSpaInfo={handleSaveSpaInfo}
            handleSaveTarget={handleSaveTarget}
            downloadReceipt={downloadReceipt}
            openEditSpaInfo={openEditSpaInfo}
            openTargetModal={openTargetModal}
            getBase64FromUrl={getBase64FromUrl}
            applyDateRangeFilter={applyDateRangeFilter}
            applyPredefinedFilter={applyPredefinedFilter}
            applySearchFilter={applySearchFilter}
          />
        )}
        {activeSubTab === 'financial' && (
          <AccountFinancial
            // Anda boleh menambah props di sini nanti
          />
        )}
      </div>

      {/* Modal Spa Info */}
      {showSpaModal && (
        <div className="sales-tab-modal-overlay">
          <div className="sales-tab-modal">
            <h3>{isEditingSpaInfo ? 'Edit Maklumat Spa' : 'Masukkan Maklumat Spa'}</h3>
            <label>Nama Spa:</label>
            <input type="text" value={spaName} onChange={(e) => setSpaName(e.target.value)} />
            <label>Alamat Spa:</label>
            <textarea value={spaAddress} onChange={(e) => setSpaAddress(e.target.value)}></textarea>
            <label>Telefon Spa:</label>
            <input type="text" value={spaPhone} onChange={(e) => setSpaPhone(e.target.value)} />
            <label>Logo URL (pilihan):</label>
            <input type="text" value={spaLogoUrl} onChange={(e) => setSpaLogoUrl(e.target.value)} />
            <p style={{fontSize:'0.8em', color:'#555', marginTop:'5px'}}>
              *Untuk upload logo gunakan <a href="https://postimages.org/" target="_blank" rel="noreferrer">postimages.org</a>, 
              pilih "Choose image", upload, kemudian copy "Direct link" dan paste disini.
            </p>

            <button onClick={handleSaveSpaInfo}>Simpan</button>
            <button onClick={() => {setShowSpaModal(false); setPendingSaleForPDF(null); setIsEditingSpaInfo(false);}}>Batal</button>
          </div>
        </div>
      )}

      {/* Modal KPI Target */}
      {showTargetModal && (
        <div className="sales-tab-modal-overlay">
          <div className="sales-tab-modal">
            <h3>{isEditingTarget ? 'Edit Monthly Target' : 'Set Monthly Target'}</h3>
            <label>Monthly Sales Target (RM):</label>
            <input type="number" value={monthlyTarget} onChange={(e) => setMonthlyTarget(e.target.value)} />
            <button onClick={handleSaveTarget}>Simpan</button>
            <button onClick={() => {setShowTargetModal(false); setIsEditingTarget(false);}}>Batal</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SalesTab;
