// TherapistList.js

import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import './TherapistList.css';
import BookingForm from './BookingForm';
import UserLocationSelector from './UserLocationSelector';
import config from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faConciergeBell } from '@fortawesome/free-solid-svg-icons';

const TherapistList = ({ searchTerm }) => { // Receive searchTerm as a prop
  const [therapists, setTherapists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTherapist, setSelectedTherapist] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [availableTherapists, setAvailableTherapists] = useState({});
  const [selectedLocation, setSelectedLocation] = useState({ state: '', city: '' });
  const [loadingSlots, setLoadingSlots] = useState(false);
  const [checkedCount, setCheckedCount] = useState(0);
  const [collapsedItems, setCollapsedItems] = useState({});

  useEffect(() => {
    const fetchTherapists = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${config.API_URL}/therapists`);
        setTherapists(response.data);
        checkAvailability(response.data);
      } catch (error) {
        console.error('Error fetching therapists:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTherapists();
  }, []);

  const checkAvailability = async (therapistList) => {
    setLoadingSlots(true);
    const today = moment().tz('Asia/Kuala_Lumpur').startOf('day');
    setCheckedCount(0);

    const requests = therapistList.map(async (therapist) => {
      try {
        const response = await axios.get(`${config.API_URL}/slots/${therapist._id}`);
        const availableSlots = response.data.filter((slot) => {
          const slotDate = moment.tz(slot.date, 'Asia/Kuala_Lumpur').startOf('day');
          return !slot.isBooked && slotDate.isSameOrAfter(today) && slot.treatment;
        });

        setAvailableTherapists((prev) => ({
          ...prev,
          [therapist._id]: availableSlots.length > 0,
        }));
      } catch (error) {
        console.error(`Error checking availability for therapist ${therapist._id}:`, error);
        setAvailableTherapists((prev) => ({
          ...prev,
          [therapist._id]: false,
        }));
      } finally {
        setCheckedCount((prev) => prev + 1);
      }
    });

    await Promise.allSettled(requests);
    setLoadingSlots(false);
  };

  const handleBooking = (therapist) => {
    if (availableTherapists[therapist._id]) {
      setSelectedTherapist(therapist);
      setIsModalOpen(true);
    }
  };

  const handleCloseBookingForm = () => {
    setSelectedTherapist(null);
    setIsModalOpen(false);
  };

  const handleLocationChange = (location) => {
    setSelectedLocation(location);
  };

  const filteredTherapists = therapists.filter((therapist) => {
    // Filter based on location
    if (selectedLocation.state && therapist.location.state !== selectedLocation.state) {
      return false;
    }
    if (selectedLocation.city && therapist.location.city !== selectedLocation.city) {
      return false;
    }
    // Filter based on search term from props
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      const nameMatch = therapist.name.toLowerCase().includes(term);
      const locationMatch =
        (therapist.location.state && therapist.location.state.toLowerCase().includes(term)) ||
        (therapist.location.city && therapist.location.city.toLowerCase().includes(term));
      const serviceMatch =
        (therapist.inCall && 'in-call'.includes(term)) ||
        (therapist.outCall && 'out-call'.includes(term));
      return nameMatch || locationMatch || serviceMatch;
    }
    return true;
  });

  // Sort therapists based on availability
  const sortedTherapists = filteredTherapists.sort((a, b) => {
    const aAvailable = availableTherapists[a._id] ? 1 : 0;
    const bAvailable = availableTherapists[b._id] ? 1 : 0;
    return bAvailable - aAvailable;
  });

  const handleShareLink = useCallback((therapist) => {
    const therapistLink = `${window.location.origin}/therapist/${therapist._id}`;
    navigator.clipboard
      .writeText(therapistLink)
      .then(() => {
        alert('Link telah disalin ke clipboard. Anda boleh paste dan kongsikan.');
      })
      .catch((error) => {
        console.error('Gagal untuk copy link:', error);
      });
  }, []);

  const toggleCollapse = (id) => {
    setCollapsedItems((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <div className="therapist-list-container">
      {/* Header with Location Filter */}
      <div className="therapist-list-header">
        <div className="filter-container">
          
          <UserLocationSelector
            selectedState={selectedLocation.state}
            selectedCity={selectedLocation.city}
            onChange={handleLocationChange}
          />
        </div>
      </div>
      
      {/* Progress Bar for Availability Checking */}
      <div className="progress-bar-container">
        {loadingSlots && (
          <div className="progress-bar">
            <p>
              Memeriksa slot ketersediaan... {checkedCount}/{filteredTherapists.length} therapist telah diperiksa
            </p>
            <div className="progress">
              <div
                className="progress-filled"
                style={{ width: `${(checkedCount / filteredTherapists.length) * 100}%` }}
              ></div>
            </div>
          </div>
        )}
      </div>

      {/* Therapist Cards */}
      <div className="card-container">
        {loading ? (
          <div className="loading-container">
            <div className="spinner"></div>
            <p>Sedang memuatkan senarai therapist, sila tunggu...</p>
          </div>
        ) : (
          sortedTherapists.map((therapist) => (
            <div
              key={therapist._id}
              className={`therapist-item ${
                availableTherapists[therapist._id] === false ? 'unavailable' : ''
              }`}
            >
              <img
                src={therapist.image || '/images/user.png'}
                alt={`${therapist.name} profile`}
                className="therapist-image"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/images/user.png';
                }}
              />
              <div className="therapist-info">
                <h2 className="therapist-name">{therapist.name}</h2>
                <p className="therapist-location">
                  <FontAwesomeIcon icon={faMapMarkerAlt} /> {therapist.location?.state || 'Tidak dinyatakan'}, {therapist.location?.city || 'Tidak dinyatakan'}
                </p>
                <div className="therapist-services">
                  <strong>Jenis Rawatan:</strong>
                  <ul className={collapsedItems[therapist._id] ? 'expanded' : ''}>
                    {therapist.jenisRawatan?.rawatanLain &&
                    therapist.jenisRawatan.rawatanLain.length > 0 ? (
                      therapist.jenisRawatan.rawatanLain
                        .slice(0, collapsedItems[therapist._id] ? undefined : 3)
                        .map((rawatan, index) => <li key={index}>{rawatan}</li>)
                    ) : (
                      <li>Tiada rawatan yang tersedia</li>
                    )}
                  </ul>
                  {therapist.jenisRawatan?.rawatanLain.length > 3 && (
                    <button
                      className="collapse-button"
                      onClick={() => toggleCollapse(therapist._id)}
                    >
                      {collapsedItems[therapist._id] ? "Lihat Kurang" : "Lihat Lebih"}
                    </button>
                  )}
                </div>
                <p className="therapist-service-type">
                  <FontAwesomeIcon icon={faConciergeBell} /> {therapist.inCall && therapist.outCall
                    ? 'In-Call & Out-Call'
                    : therapist.inCall
                    ? 'In-Call'
                    : therapist.outCall
                    ? 'Out-Call'
                    : 'Tidak dinyatakan'}
                </p>
                {availableTherapists[therapist._id] === undefined ? (
                  <div className="loading-placeholder">Memeriksa ketersediaan...</div>
                ) : availableTherapists[therapist._id] ? (
                  <div className="button-group">
                    <button className="btn book-btn" onClick={() => handleBooking(therapist)}>
                      Tempah Sekarang
                    </button>
                    <button className="btn share-btn" onClick={() => handleShareLink(therapist)}>
                      <FontAwesomeIcon icon={faMapMarkerAlt} /> Kongsi Pautan
                    </button>
                  </div>
                ) : (
                  <div className="no-slots-message">Tiada slot tersedia</div>
                )}
              </div>
            </div>
          ))
        )}
      </div>

{/* Booking Form Modal */}
{isModalOpen && (
  <div className="booking-form-overlay" onClick={handleCloseBookingForm}>
    <div
      className="booking-form-modal"
      onClick={(e) => e.stopPropagation()} // Supaya klik di modal tidak tutup overlay
    >
      {/* Borang Tempahan */}
      <BookingForm
        selectedTherapist={selectedTherapist}
        onClose={handleCloseBookingForm}
      />
    </div>
  </div>
)}


    </div>
  );
};

export default TherapistList;