import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import './GoogleCalendarIntegration.css';
import config from '../config';


function GoogleCalendarIntegration({ token, therapistId }) {
  const [googleEmail, setGoogleEmail] = useState('');
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    const fetchIntegrationStatus = async () => {
      if (!therapistId) {
        console.warn('No therapistId provided, skipping API call');
        return;
      }

      try {
        const response = await axios.get(`${config.API_URL}/api/google-auth/status/${therapistId}`, {
          headers: {
            'x-auth-token': token,
          }
        });

        if (response && response.data) {
          setIsConnected(response.data.connected);
        } else {
          console.warn('API response does not contain data');
        }
      } catch (error) {
        console.error('Error fetching Google Calendar integration status:', error);
      }
    };

    fetchIntegrationStatus();
  }, [therapistId, token]);

  const handleAuthorize = async () => {
    if (!googleEmail) {
      toast.error('Please enter your Google email.');
      return;
    }

    try {
      const response = await axios.post(`${config.API_URL}/api/google-auth`, 
        { email: googleEmail },
        {
          headers: {
            'x-auth-token': token,
          }
        }
      );

      if (response.data && response.data.url) {
        window.location.href = response.data.url;
      } else {
        throw new Error('Authorization failed.');
      }
    } catch (error) {
      console.error('Error during Google authorization:', error);
      toast.error('Failed to authorize with Google. Please try again.');
    }
  };

  const handleRemoveAuthorization = async () => {
    try {
      await axios.post(`${config.API_URL}/api/google-auth/remove/${therapistId}`, {}, {
        headers: {
          'x-auth-token': token,
        }
      });
      setIsConnected(false);
      toast.success('Google Calendar authorization removed.');
    } catch (error) {
      console.error('Error removing Google Calendar authorization:', error);
      toast.error('Failed to remove authorization. Please try again.');
    }
  };



  return (
  <div className="integrate-calendar-card">
    <h2>Integrate Google Calendar</h2>
    {isConnected ? (
      <div>
        <p className="connection-status connected">Connected Now</p>
        <button type="button" className="remove-authorization-button" onClick={handleRemoveAuthorization}>
          Remove Authorization
        </button>
      </div>
    ) : (
      <div>
        <p className="connection-status not-connected">Not Connected</p>
        <form className="calendar-form">
          <div className="form-group">
            <label htmlFor="googleEmail">Google Email:</label>
            <input
              type="email"
              id="googleEmail"
              value={googleEmail}
              onChange={(e) => setGoogleEmail(e.target.value)}
              placeholder="Enter your Google email"
              required
            />
          </div>
          <button type="button" className="authorize-button" onClick={handleAuthorize}>
            Authorize with Google
          </button>
        </form>
      </div>
    )}
  </div>
);
}

export default GoogleCalendarIntegration;