// src/components/Broadcast.js
import React, { useState, useEffect } from 'react';
import {
  FaExclamationTriangle,
  FaPaperPlane,
  FaTrashAlt,
  FaSpinner,
  FaClock,
  FaCheckCircle,
  FaPauseCircle,
  FaHourglassHalf,
} from 'react-icons/fa';
import { toast } from 'react-toastify';
import './Broadcast.css';
import config from '../config';
import axios from 'axios';
import moment from 'moment-timezone';

const Broadcast = ({ therapistId }) => {
  // State untuk jenis sasaran
  const [targetType, setTargetType] = useState('group'); // 'group' atau 'label'
  const [campaignName, setCampaignName] = useState('');
  const [targetGroup, setTargetGroup] = useState('');
  const [labels, setLabels] = useState([]); // Senarai label yang diterima dari backend
  const [selectedLabelIds, setSelectedLabelIds] = useState([]); // ID label yang dipilih
  const [message, setMessage] = useState('');
  const [minInterval, setMinInterval] = useState(60);
  const [estimatedCompletion, setEstimatedCompletion] = useState('');
  const [scheduleType, setScheduleType] = useState('instant');
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [schedule, setSchedule] = useState({
    startDate: '',
  });
  const [testNumber, setTestNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [contactCounts, setContactCounts] = useState({
    prospect: 0,
    customer: 0,
    vvip: 0,
  });
  const [filterStartDate, setFilterStartDate] = useState('');
  const [filterEndDate, setFilterEndDate] = useState('');
  const [seeding, setSeeding] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [activeTab, setActiveTab] = useState('instant'); // 'instant' atau 'scheduled'

  const timeSlots = Array.from({ length: 24 }, (_, i) => {
    const startHour = i.toString().padStart(2, '0') + ':00';
    const endHour = ((i + 1) % 24).toString().padStart(2, '0') + ':00';
    return `${startHour}-${endHour}`;
  });

  // Fungsi untuk toggle slot masa
  const toggleTimeSlot = (slot) => {
    setSelectedTimeSlots((prev) => {
      if (prev.includes(slot)) {
        return prev.filter((s) => s !== slot);
      } else {
        return [...prev, slot];
      }
    });
  };

  // Fungsi untuk mendapatkan kiraan kontak
  const fetchContactCounts = async (therapistId, filterStartDate, filterEndDate) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${config.API_URL}/api/contacts/counts/${therapistId}`, {
        headers: { 'x-auth-token': token },
        params: { filterStartDate, filterEndDate },
      });
      setContactCounts(response.data);
    } catch (error) {
      console.error('Failed to fetch contact counts:', error);
      toast.error('Gagal mendapatkan kiraan kontak.');
    }
  };

  // Fungsi untuk mendapatkan senarai label
  const fetchLabels = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`/api/whatsapp/getLabels/${therapistId}`, null, {
        headers: {
          'x-auth-token': token,
        },
      });

      if (response.data.success) {
        const labelsData = response.data.labels || [];
        setLabels(labelsData);
      } else {
        throw new Error(response.data.error || 'Gagal mendapatkan label');
      }
    } catch (error) {
      console.error('Error fetching labels:', error);
      toast.error('Gagal mendapatkan label.');
    }
  };

  useEffect(() => {
    if (therapistId) {
      fetchLabels();
    }
  }, [therapistId]);

  useEffect(() => {
    if (therapistId) {
      fetchContactCounts(therapistId, filterStartDate, filterEndDate);
    }
  }, [therapistId, filterStartDate, filterEndDate]);

  // Fungsi untuk mengira anggaran masa siap
  useEffect(() => {
    const calculateEstimatedCompletion = () => {
      let groupCount = 0;
      if (targetType === 'group') {
        groupCount = contactCounts[targetGroup] || 0;
      } else if (targetType === 'label') {
        // Dapatkan jumlah kontak dalam label yang dipilih
        groupCount = selectedLabelIds.length * 50; // Anggaran 50 kontak per label
      }

      const totalSeconds = groupCount * minInterval;

      // Kira jumlah slot masa yang dipilih dalam sehari (dalam saat)
      const totalSlotSeconds = selectedTimeSlots.length * 3600;

      if (totalSlotSeconds === 0) {
        setEstimatedCompletion('Sila pilih slot masa');
        return;
      }

      // Urutkan slot masa yang dipilih
      const sortedSlots = selectedTimeSlots.sort();

      // Dapatkan masa mula slot pertama
      const firstSlotStart = sortedSlots[0].split('-')[0];

      // Dapatkan zon waktu pengguna
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      // Dapatkan tarikh mula dengan masa mula slot pertama dalam zon waktu pengguna
      const startDateTime = moment.tz(`${schedule.startDate} ${firstSlotStart}`, 'YYYY-MM-DD HH:mm', timeZone);

      // Kira jumlah hari yang diperlukan
      const daysNeeded = Math.floor(totalSeconds / totalSlotSeconds) + 1;

      // Kira masa anggaran siap
      let estimatedCompletionDate = startDateTime.clone().add(daysNeeded - 1, 'days');

      setEstimatedCompletion(estimatedCompletionDate.format('DD/MM/YYYY HH:mm'));
    };

    if (scheduleType === 'scheduled' && selectedTimeSlots.length > 0 && schedule.startDate) {
      calculateEstimatedCompletion();
    }
  }, [targetGroup, selectedLabelIds, targetType, minInterval, contactCounts, scheduleType, schedule, selectedTimeSlots]);

  // Fungsi untuk menghantar mesej ujian
  const handleTestSend = async () => {
    if (!testNumber || (!message && !imageFile)) {
      toast.error('Sila isi nombor telefon dan mesej atau pilih imej untuk ujian');
      return;
    }

    setIsLoading(true);

    try {
      const token = localStorage.getItem('token');

      const formData = new FormData();
      formData.append('therapistId', therapistId);
      formData.append('testNumber', testNumber);
      formData.append('message', message);
      if (imageFile) {
        formData.append('image', imageFile);
      }

      const response = await axios.post('/api/broadcast/test-send', formData, {
        headers: {
          'x-auth-token': token,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        toast.success('Mesej ujian berjaya dihantar');
      } else {
        throw new Error(response.data.error || 'Gagal menghantar mesej ujian');
      }
    } catch (error) {
      console.error('Test send error:', error);
      toast.error(error.message || 'Gagal menghantar mesej ujian');
    }

    setIsLoading(false);
  };

  // Fungsi untuk toggle pemilihan kempen
  const toggleCampaignSelection = (campaignId) => {
    setSelectedCampaigns((prevSelected) => {
      if (prevSelected.includes(campaignId)) {
        return prevSelected.filter((id) => id !== campaignId);
      } else {
        return [...prevSelected, campaignId];
      }
    });
  };

  // Fungsi untuk memadam kempen yang dipilih
  const handleDeleteCampaigns = async () => {
    if (selectedCampaigns.length === 0) {
      toast.error('Sila pilih kempen untuk dipadam');
      return;
    }

    if (!window.confirm('Adakah anda pasti mahu memadam kempen yang dipilih?')) {
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete('/api/broadcast/campaigns', {
        headers: {
          'x-auth-token': token,
        },
        data: { campaignIds: selectedCampaigns },
      });

      if (response.data.success) {
        toast.success('Kempen berjaya dipadam');
        // Kemas kini senarai kempen
        setCampaigns((prevCampaigns) =>
          prevCampaigns.filter((campaign) => !selectedCampaigns.includes(campaign._id))
        );
        setSelectedCampaigns([]);
      } else {
        throw new Error(response.data.error || 'Gagal memadam kempen');
      }
    } catch (error) {
      console.error('Delete campaign error:', error);
      toast.error(error.message || 'Gagal memadam kempen');
    }
  };

  // Fungsi untuk mencipta kempen broadcast
  const handleCreateCampaign = async (e) => {
    e.preventDefault();
    // Validasi
    if (scheduleType === 'scheduled' && selectedTimeSlots.length === 0) {
      toast.error('Sila pilih sekurang-kurangnya satu slot masa');
      return;
    }

    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      let startDateTime = null;
      let timeZone = null;

      if (scheduleType === 'scheduled') {
        // Dapatkan slot masa pertama yang dipilih
        const sortedSlots = [...selectedTimeSlots].sort();
        const firstSlotStart = sortedSlots[0].split('-')[0];

        // Dapatkan zon waktu pengguna
        timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Gabungkan tarikh mula dengan masa mula slot pertama
        const startDateTimeString = `${schedule.startDate} ${firstSlotStart}`;

        // Tukar kepada ISO string dalam zon waktu pengguna
        startDateTime = moment.tz(startDateTimeString, 'YYYY-MM-DD HH:mm', timeZone).format();
      }

      const formData = new FormData();
      formData.append('therapistId', therapistId);
      formData.append('campaignName', campaignName);
      formData.append('message', message);
      formData.append('minInterval', minInterval);
      formData.append('scheduleType', scheduleType);
      formData.append('seeding', seeding);
      formData.append('filterStartDate', filterStartDate || null);
      formData.append('filterEndDate', filterEndDate || null);

      // Tambahkan targetType dan targetGroup/targetLabels
      formData.append('targetType', targetType);
      if (targetType === 'group') {
        formData.append('targetGroup', targetGroup);
      } else if (targetType === 'label') {
        formData.append('targetLabels', JSON.stringify(selectedLabelIds));
      }

      if (scheduleType === 'scheduled') {
        formData.append(
          'schedule',
          JSON.stringify({
            ...schedule,
            startDate: startDateTime,
            timeSlots: selectedTimeSlots,
          })
        );
      }
      if (imageFile) {
        formData.append('image', imageFile);
      }

      const response = await axios.post('/api/broadcast/campaign', formData, {
        headers: {
          'x-auth-token': token,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        toast.success('Kempen broadcast berjaya dicipta dan dihantar');
        // Resetkan borang jika perlu
        setCampaignName('');
        setTargetGroup('');
        setSelectedLabelIds([]);
        setMessage('');
        setSchedule({ startDate: '' });
        setSelectedTimeSlots([]);
        setFilterStartDate('');
        setFilterEndDate('');
        setImageFile(null);
        // Muat semula senarai kempen
        fetchCampaigns();
      } else {
        throw new Error(response.data.error || 'Gagal mencipta kempen broadcast');
      }
    } catch (error) {
      console.error('Campaign creation error:', error);
      toast.error(error.message || 'Gagal mencipta kempen broadcast');
    }
    setIsLoading(false);
  };

  // Fungsi untuk mendapatkan senarai kempen
  const fetchCampaigns = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`/api/broadcast/campaigns/${therapistId}`, {
        headers: {
          'x-auth-token': token,
        },
        params: {
          filterStartDate,
          filterEndDate,
        },
      });
      setCampaigns(response.data.campaigns);
    } catch (error) {
      console.error('Failed to fetch campaigns:', error);
      toast.error('Gagal mendapatkan senarai kempen.');
    }
  };

  useEffect(() => {
    if (therapistId) {
      fetchCampaigns();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [therapistId, filterStartDate, filterEndDate]);

  return (
    <div className="broadcast-container">
      <div className="broadcast-warning">
        <FaExclamationTriangle className="warning-icon" />
        <p>
          PERHATIAN: Sila berhati-hati dalam menggunakan fungsi broadcast. Pastikan nombor telah cukup matang untuk
          broadcast dan ikut limit masa yang disarankan. Minimum interval yang disarankan adalah 60 saat.
        </p>
      </div>

      <div className="broadcast-form">
        <h3>Cipta Kempen Broadcast Baru</h3>

        <div className="form-group">
          <label>Nama Kempen:</label>
          <input
            type="text"
            value={campaignName}
            onChange={(e) => setCampaignName(e.target.value)}
            placeholder="Masukkan nama kempen"
          />
        </div>

        <div className="form-group">
          <label>Pilih Jenis Sasaran:</label>
          <select value={targetType} onChange={(e) => setTargetType(e.target.value)}>
            <option value="group">Kumpulan Data</option>
            <option value="label">Label Kontak</option>
          </select>
        </div>

        {targetType === 'group' && (
          <div className="form-group">
            <label>Pilih Kumpulan Sasaran:</label>
            <select value={targetGroup} onChange={(e) => setTargetGroup(e.target.value)}>
              <option value="">Pilih kumpulan</option>
              {contactCounts.prospect > 0 && (
                <option value="prospect">Prospek ({contactCounts.prospect})</option>
              )}
              {contactCounts.customer > 0 && (
                <option value="customer">Pelanggan ({contactCounts.customer})</option>
              )}
              {contactCounts.vvip > 0 && <option value="vvip">VVIP ({contactCounts.vvip})</option>}
            </select>
          </div>
        )}

        {targetType === 'label' && (
          <div className="form-group">
            <label>Pilih Label Kontak:</label>
            <select
              multiple
              value={selectedLabelIds}
              onChange={(e) =>
                setSelectedLabelIds(Array.from(e.target.selectedOptions, (option) => option.value))
              }
            >
              {labels.map((label) => (
                <option key={label.id} value={label.id}>
                  {label.name}
                </option>
              ))}
            </select>
          </div>
        )}

        <div className="form-group">
          <label>Jenis Seeding:</label>
          <select value={seeding} onChange={(e) => setSeeding(e.target.value)}>
            <option value="">Pilih jenis seeding</option>
            {[...Array(20)].map((_, i) => (
              <option key={i} value={`seeding ${i + 1}`}>
                Seeding {i + 1}
              </option>
            ))}
          </select>
        </div>

        {/* Medan Penapis Julat Tarikh */}
        <div className="form-group">
          <label>Pilih Julat Tarikh Prospek:</label>
          <div className="date-range">
            <input
              type="date"
              value={filterStartDate}
              onChange={(e) => setFilterStartDate(e.target.value)}
              placeholder="Tarikh Mula"
            />
            <span> - </span>
            <input
              type="date"
              value={filterEndDate}
              onChange={(e) => setFilterEndDate(e.target.value)}
              placeholder="Tarikh Akhir"
            />
          </div>
        </div>

        <div className="form-group">
          <label>Mesej:</label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Masukkan mesej broadcast"
            rows={4}
          />
        </div>

        <div className="form-group">
          <label>Muat Naik Imej (pilihan):</label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setImageFile(e.target.files[0])}
          />
        </div>

        <div className="test-section">
          <h4>Ujian Broadcast</h4>
          <div className="form-group">
            <input
              type="text"
              value={testNumber}
              onChange={(e) => setTestNumber(e.target.value)}
              placeholder="Masukkan nombor telefon untuk ujian"
            />
            <button
              onClick={handleTestSend}
              disabled={isLoading}
              className="test-button"
            >
              <FaPaperPlane /> Hantar Ujian
            </button>
          </div>
        </div>

        <div className="form-group">
          <label>
            <FaClock /> Minimum Interval (saat):
          </label>
          <input
            type="number"
            min={60}
            value={minInterval}
            onChange={(e) => setMinInterval(Number(e.target.value))}
          />
        </div>

        <div className="form-group">
          <label>Jenis Jadual:</label>
          <select value={scheduleType} onChange={(e) => setScheduleType(e.target.value)}>
            <option value="instant">Broadcast Segera</option>
            <option value="scheduled">Broadcast Berjadual</option>
          </select>
        </div>

        {scheduleType === 'scheduled' && (
          <>
            <div className="form-group">
              <label>Pilih Slot Masa:</label>
              <div className="time-slots">
                {timeSlots.map((slot) => (
                  <div
                    key={slot}
                    className={`time-slot ${selectedTimeSlots.includes(slot) ? 'selected' : ''}`}
                    onClick={() => toggleTimeSlot(slot)}
                  >
                    {slot}
                  </div>
                ))}
              </div>
            </div>

            <div className="form-group">
              <label>Tarikh Bermula:</label>
              <input
                type="date"
                value={schedule.startDate}
                onChange={(e) => setSchedule({ ...schedule, startDate: e.target.value })}
              />
            </div>

            <div className="form-group">
              <label>Anggaran Masa Siap:</label>
              <p>{estimatedCompletion}</p>
            </div>
          </>
        )}

        <button
          onClick={handleCreateCampaign}
          disabled={isLoading}
          className="create-campaign-button"
        >
          Cipta Kempen Broadcast
        </button>
      </div>

      {/* Paparkan senarai kempen */}
      <div className="campaign-list">
        <h3>Senarai Kempen Broadcast</h3>
        <div className="tabs">
          <button
            className={`tab ${activeTab === 'instant' ? 'active' : ''}`}
            onClick={() => setActiveTab('instant')}
          >
            Broadcast Instant
          </button>
          <button
            className={`tab ${activeTab === 'scheduled' ? 'active' : ''}`}
            onClick={() => setActiveTab('scheduled')}
          >
            Broadcast Berjadual
          </button>
        </div>

        <button
          onClick={handleDeleteCampaigns}
          disabled={selectedCampaigns.length === 0}
          className="delete-campaign-button"
        >
          <FaTrashAlt /> Padam Kempen Terpilih
        </button>
        <div className="table-responsive">
          <table className="campaign-table">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        const filteredCampaigns = campaigns.filter(c => c.scheduleType === activeTab);
                        setSelectedCampaigns(filteredCampaigns.map((c) => c._id));
                      } else {
                        setSelectedCampaigns([]);
                      }
                    }}
                    checked={
                      campaigns.filter(c => c.scheduleType === activeTab).length > 0 &&
                      selectedCampaigns.length === campaigns.filter(c => c.scheduleType === activeTab).length
                    }
                  />
                </th>
                <th>Nama Kempen</th>
                <th>Kumpulan Sasaran</th>
                <th>Tarikh Mula</th>
                <th>Slot Masa</th>
                <th>Jenis Broadcast</th>
                <th>Julat Tarikh Prospek</th>
                <th>Jenis Seeding</th>
                <th>Status</th>
                <th>Kemajuan</th>
              </tr>
            </thead>

            <tbody>
              {campaigns
                .filter(campaign => campaign.scheduleType === activeTab)
                .map((campaign) => {
                  const successCount = campaign.recipients.filter(r => r.status === 'sent').length;
                  const failedCount = campaign.recipients.filter(r => r.status === 'failed').length;

                  return (
                    <tr key={campaign._id}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedCampaigns.includes(campaign._id)}
                          onChange={() => toggleCampaignSelection(campaign._id)}
                        />
                      </td>
                      <td>{campaign.name}</td>
                      <td>
                        {campaign.targetType === 'group' && campaign.targetGroup}
                        {campaign.targetType === 'label' && 'Label Kontak'}
                      </td>
                      <td>{campaign.schedule?.startDate ? moment(campaign.schedule.startDate).format('DD/MM/YYYY') : '-'}</td>
                      <td>{campaign.schedule?.timeSlots?.join(', ') || '-'}</td>
                      <td>{campaign.scheduleType || '-'}</td>
                      <td>
                        {campaign.filterStartDate && campaign.filterEndDate
                          ? `${moment(campaign.filterStartDate).format('DD/MM/YYYY')} - ${moment(campaign.filterEndDate).format('DD/MM/YYYY')}`
                          : '-'}
                      </td>
                      <td>{campaign.seeding || '-'}</td>
                      <td className={`status ${campaign.status}`}>
                        {campaign.status === 'pending' && <FaHourglassHalf />}
                        {campaign.status === 'active' && <FaSpinner className="spin-icon" />}
                        {campaign.status === 'completed' && <FaCheckCircle />}
                        {campaign.status === 'paused' && <FaPauseCircle />}
                        &nbsp;{campaign.status}
                      </td>
                      <td>
                        {successCount} Berjaya / {failedCount} Gagal
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Broadcast;
