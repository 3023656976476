import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ManageBookings.css';
import config from '../config';


function ManageBookings() {
  const [bookings, setBookings] = useState({});

  useEffect(() => {
    fetchBookings();
  }, []);

  const fetchBookings = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/admin/bookings`);
      const groupedBookings = groupBookingsByTherapist(response.data);
      setBookings(groupedBookings);
    } catch (error) {
      console.error('Error fetching bookings:', error);
    }
  };

  const groupBookingsByTherapist = (bookingsData) => {
    return bookingsData.reduce((acc, booking) => {
      const therapistId = booking.therapistId._id;
      if (!acc[therapistId]) {
        acc[therapistId] = {
          therapistName: booking.therapistId.name,
          bookings: []
        };
      }
      acc[therapistId].bookings.push(booking);
      return acc;
    }, {});
  };

  const handleDeleteBooking = async (bookingId) => {
    if (window.confirm('Are you sure you want to delete this booking?')) {
      try {
        await axios.delete(`${config.API_URL}/admin/bookings/${bookingId}`);
        fetchBookings(); // Refresh the bookings list
      } catch (error) {
        console.error('Error deleting booking:', error);
      }
    }
  };

  const formatTime = (timeString) => {
    if (!timeString) return 'N/A';
    const [hours, minutes] = timeString.split(':');
    return `${hours}:${minutes}`;
  };

  return (
    <div className="manage-bookings">
      <h2>Manage Bookings</h2>
      {Object.entries(bookings).map(([therapistId, therapistData]) => (
        <div key={therapistId} className="therapist-bookings">
          <h3>{therapistData.therapistName}'s Bookings</h3>
          <table>
            <thead>
              <tr>
                <th>Client</th>
                <th>Date</th>
                <th>Slot Time</th>
                <th>Treatment</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {therapistData.bookings.map((booking) => (
                <tr key={booking._id}>
                  <td>{booking.userName}</td>
                  <td>{new Date(booking.bookingDate).toLocaleDateString()}</td>
                  <td>
  {booking.slotId && booking.slotId.startTime && booking.slotId.endTime 
    ? `${formatTime(booking.slotId.startTime)} - ${formatTime(booking.slotId.endTime)}` 
    : 'Slot time not available'}
</td>
                  <td>{booking.treatment}</td>
                  <td>{booking.status}</td>
                  <td>
                    <button onClick={() => handleDeleteBooking(booking._id)} className="delete-btn">
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
}

export default ManageBookings;