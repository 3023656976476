// ManageProspects.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ManageProspects.css'; // Styling khusus untuk ManageProspects
import { toast } from 'react-toastify';
import config from '../config'; // Pastikan path betul

function ManageProspects() {
  const [therapists, setTherapists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Tambah state untuk pagination
  const [currentPage, setCurrentPage] = useState(1);
  const therapistsPerPage = 5; // Tukar mengikut keperluan

  // Tambah state untuk collapsible
  const [openTherapists, setOpenTherapists] = useState({});

  useEffect(() => {
    fetchTherapistsWithProspects();
  }, []);

  const fetchTherapistsWithProspects = async () => {
    try {
      const token = localStorage.getItem('authToken'); // Jika endpoint dilindungi
      const response = await axios.get(`${config.API_URL}/therapists`, {
        headers: {
          'x-auth-token': token,
        },
      });

      const therapistData = Array.isArray(response.data.therapists)
        ? response.data.therapists
        : Array.isArray(response.data)
          ? response.data
          : [];

      console.log('Therapists Data:', therapistData); // Debugging

      // Untuk setiap therapist, ambil prospects
      const therapistsWithProspects = await Promise.all(
        therapistData.map(async (therapist) => {
          try {
            const prospectsResponse = await axios.get(`${config.API_URL}/api/prospects/by-therapist/${therapist._id}`, {
              headers: {
                'x-auth-token': token,
              },
            });
            console.log(`Prospects for Therapist ${therapist._id}:`, prospectsResponse.data); // Debugging
            return {
              ...therapist,
              prospects: prospectsResponse.data,
            };
          } catch (prospectError) {
            console.error(`Error fetching prospects for therapist ${therapist._id}:`, prospectError);
            return {
              ...therapist,
              prospects: [],
            };
          }
        })
      );

      // Filter therapists yang mempunyai prospect
      const therapistsWithProspectsFiltered = therapistsWithProspects.filter(
        (therapist) => therapist.prospects && therapist.prospects.length > 0
      );

      setTherapists(therapistsWithProspectsFiltered);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching therapists:', err);
      setError('Gagal mendapatkan data therapists. Sila cuba lagi.');
      setLoading(false);
    }
  };

  // Fungsi untuk menghitung jumlah halaman
  const totalPages = Math.ceil(therapists.length / therapistsPerPage);

  // Dapatkan therapists untuk halaman semasa
  const indexOfLastTherapist = currentPage * therapistsPerPage;
  const indexOfFirstTherapist = indexOfLastTherapist - therapistsPerPage;
  const currentTherapists = therapists.slice(indexOfFirstTherapist, indexOfLastTherapist);

  // Fungsi untuk menukar halaman
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Fungsi untuk toggle collapsible
  const toggleTherapist = (therapistId) => {
    setOpenTherapists((prevState) => ({
      ...prevState,
      [therapistId]: !prevState[therapistId],
    }));
  };

  if (loading) {
    return (
      <div className="manage-prospects-loading">
        <p>Memuatkan data...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="manage-prospects-error">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="manage-prospects-container">
      <h2>Data Prospects</h2>
      {therapists.length === 0 ? (
        <p>Tiada prospects dijumpai.</p>
      ) : (
        <>
          {currentTherapists.map((therapist) => (
            <div key={therapist._id} className="therapist-prospects">
              <h3 onClick={() => toggleTherapist(therapist._id)} className="therapist-name">
                {therapist.name} {openTherapists[therapist._id] ? '▲' : '▼'}
              </h3>
              {openTherapists[therapist._id] && (
                <div className="table-container">
                  <table className="prospects-table">
                    <thead>
                      <tr>
                        <th>Nama</th>
                        <th>Telefon</th>
                        <th>Last Message</th> {/* Tambah kolum Last Message */}
                      </tr>
                    </thead>
                    <tbody>
                      {therapist.prospects.map((prospect) => (
                        <tr key={prospect._id}>
                          <td data-label="Nama">{prospect.whatsappName}</td> {/* Ubah dari prospect.name ke prospect.whatsappName */}
                          <td data-label="Telefon">{prospect.phoneNumber}</td>
                          <td data-label="Last Message">
                            {prospect.lastMessage ? prospect.lastMessage.text : 'Tiada Mesej'} {/* Paparkan Last Message */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          ))}

          {/* Pagination Controls */}
          <div className="pagination">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="pagination-button"
            >
              Sebelumnya
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => paginate(index + 1)}
                className={`pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="pagination-button"
            >
              Seterusnya
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default ManageProspects;
