// ManageSlots.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ManageSlot.css'; // Import fail CSS
import Pagination from './Pagination'; // Import komponen Pagination

function ManageSlots() {
  const [slots, setSlots] = useState([]);
  const [today, setToday] = useState('');
  const [filter, setFilter] = useState('');
  const [filteredSlots, setFilteredSlots] = useState([]);
  const [therapists, setTherapists] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedSlots, setSelectedSlots] = useState([]); // Untuk menyimpan slot yang dipilih
  const [currentPage, setCurrentPage] = useState({}); // Menyimpan halaman saat ini per therapist
  const [deleting, setDeleting] = useState(false); // State untuk loading saat menghapus
  const slotsPerPage = 5;

  useEffect(() => {
    // Fetch slots from API
    const fetchSlots = async () => {
      try {
        const response = await axios.get('/api/admin/slots', {
          headers: { 'x-auth-token': localStorage.getItem('authToken') },
        });
        setSlots(response.data);
        setFilteredSlots(response.data);
      } catch (error) {
        console.error('Error fetching slots:', error);
        setError('Gagal mengambil data slot.');
      }
    };

    // Set today's date
    const todayDate = new Date().toISOString().split('T')[0];
    setToday(todayDate);

    fetchSlots();
  }, []);

  useEffect(() => {
    // Setelah slots diambil, fetch semua therapists
    const fetchTherapists = async () => {
      try {
        const response = await axios.get('/therapists', { // Pastikan endpointnya benar
          headers: { 'x-auth-token': localStorage.getItem('authToken') },
        });
        const therapistsData = {};
        response.data.forEach(therapist => {
          therapistsData[therapist._id] = therapist.name; // Asumsi nama therapist disimpan dalam `name`
        });
        setTherapists(therapistsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching therapists:', error);
        setError('Gagal mengambil data therapist.');
        setLoading(false);
      }
    };

    if (slots.length > 0) {
      fetchTherapists();
    } else {
      setLoading(false);
    }
  }, [slots]);

  // Handle filter change
  const handleFilterChange = (e) => {
    const value = e.target.value;
    setFilter(value);
    if (value === '') {
      setFilteredSlots(slots);
    } else {
      const filtered = slots.filter(slot =>
        therapists[slot.therapistId]?.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredSlots(filtered);
    }
    setSelectedSlots([]); // Reset selected slots saat filter berubah
  };

  // Group slots by therapist
  const groupedByTherapist = filteredSlots.reduce((groups, slot) => {
    const therapistName = therapists[slot.therapistId] || 'Loading...';
    if (!groups[therapistName]) {
      groups[therapistName] = [];
    }
    groups[therapistName].push(slot);
    return groups;
  }, {});

  // Handle checkbox change
  const handleCheckboxChange = (e, slotId) => {
    if (e.target.checked) {
      setSelectedSlots([...selectedSlots, slotId]);
    } else {
      setSelectedSlots(selectedSlots.filter(id => id !== slotId));
    }
  };

  // Handle select all checkboxes for a therapist
  const handleSelectAll = (e, therapistName) => {
    if (e.target.checked) {
      const therapistSlots = groupedByTherapist[therapistName].map(slot => slot._id);
      const newSelected = [...new Set([...selectedSlots, ...therapistSlots])];
      setSelectedSlots(newSelected);
    } else {
      const therapistSlots = groupedByTherapist[therapistName].map(slot => slot._id);
      setSelectedSlots(selectedSlots.filter(id => !therapistSlots.includes(id)));
    }
  };

  // Handle page change per therapist
  const handlePageChange = (therapistName, pageNumber) => {
    setCurrentPage(prev => ({ ...prev, [therapistName]: pageNumber }));
  };

  // Handle delete selected slots
  const handleDelete = async () => {
    if (selectedSlots.length === 0) {
      alert('Sila pilih slot yang ingin dihapuskan.');
      return;
    }

    if (!window.confirm('Adakah anda pasti mahu menghapuskan slot yang dipilih?')) {
      return;
    }

    try {
      setDeleting(true);
      await axios.delete('/api/admin/slots', {
        headers: { 'x-auth-token': localStorage.getItem('authToken') },
        data: { slotIds: selectedSlots },
      });
      // Refresh slots
      const response = await axios.get('/api/admin/slots', {
        headers: { 'x-auth-token': localStorage.getItem('authToken') },
      });
      setSlots(response.data);
      setFilteredSlots(response.data);
      setSelectedSlots([]);
      alert('Slot berjaya dihapuskan.');
    } catch (error) {
      console.error('Error deleting slots:', error);
      setError('Gagal menghapuskan slot.');
    } finally {
      setDeleting(false);
    }
  };

  if (loading) {
    return <div className="manage-slots-container"><p>Loading...</p></div>;
  }

  if (error) {
    return <div className="manage-slots-container"><p className="error">{error}</p></div>;
  }

  return (
    <div className="manage-slots-container">
      <h2 className="title">Manage Therapist Slots</h2>
      <p className="today-date">Tarikh hari ini: <strong>{today}</strong></p>

      <div className="filter-container">
        <label htmlFor="therapistFilter">Cari Nama Therapist:</label>
        <input
          type="text"
          id="therapistFilter"
          value={filter}
          onChange={handleFilterChange}
          placeholder="Masukkan nama therapist..."
          className="filter-input"
        />
      </div>

      <button
        className="delete-button"
        onClick={handleDelete}
        disabled={deleting || selectedSlots.length === 0}
      >
        {deleting ? 'Deleting...' : 'Delete Selected Slots'}
      </button>

      <h3 className="slot-list-title">Senarai Slot Therapist (Sebelum Hari Ini dan Belum Dibooking)</h3>
      {filteredSlots.length > 0 ? (
        Object.keys(groupedByTherapist).sort().map(therapistName => {
          const totalSlots = groupedByTherapist[therapistName].length;
          const totalPages = Math.ceil(totalSlots / slotsPerPage);
          const currentTherapistPage = currentPage[therapistName] || 1;
          const indexOfLastSlot = currentTherapistPage * slotsPerPage;
          const indexOfFirstSlot = indexOfLastSlot - slotsPerPage;
          const currentSlots = groupedByTherapist[therapistName].slice(indexOfFirstSlot, indexOfLastSlot);

          return (
            <div key={therapistName} className="therapist-group">
              <h4 className="therapist-header">{therapistName}</h4>
              <table className="slots-table">
                <thead>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        onChange={(e) => handleSelectAll(e, therapistName)}
                        checked={
                          currentSlots.every(slot => selectedSlots.includes(slot._id)) &&
                          currentSlots.length > 0
                        }
                      />
                    </th>
                    <th>Tarikh</th>
                    <th>Waktu Mula</th>
                    <th>Waktu Tamat</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {currentSlots.map(slot => (
                    <tr key={slot._id} className={slot.isBooked ? 'booked' : 'available'}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedSlots.includes(slot._id)}
                          onChange={(e) => handleCheckboxChange(e, slot._id)}
                        />
                      </td>
                      <td>{slot.date}</td>
                      <td>{slot.startTime}</td>
                      <td>{slot.endTime}</td>
                      <td>{slot.isBooked ? 'Booked' : 'Available'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {totalPages > 1 && (
                <Pagination
                  currentPage={currentTherapistPage}
                  totalPages={totalPages}
                  onPageChange={(page) => handlePageChange(therapistName, page)}
                />
              )}
            </div>
          );
        })
      ) : (
        <p>Tiada slot tersedia.</p>
      )}
    </div>
  );
}

export default ManageSlots;
