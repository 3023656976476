// ProspectList.js

import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import './ProspectList.css';
import { toast } from 'react-toastify';

const ProspectList = () => {
  const [prospects, setProspects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deletingId, setDeletingId] = useState(null);
  const [selectedProspects, setSelectedProspects] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const therapistId = localStorage.getItem('userId');

const fetchProspects = useCallback(async () => {
  try {
    setLoading(true);
    setSelectedProspects([]);
    const token = localStorage.getItem('token');

    const response = await axios.get(`/api/prospects/by-therapist/${therapistId}`, {
      headers: {
        'x-auth-token': token,
        'Content-Type': 'application/json',
      },
      params: { _: new Date().getTime() },
    });

    let prospectsData = Array.isArray(response.data) ? response.data : [];

    // Sort prospects by createdAt (or lastMessage date) in descending order (latest first)
    prospectsData = prospectsData.sort((a, b) => {
  const dateA = new Date(a.createdAt || a.lastMessage?.date);
  const dateB = new Date(b.createdAt || b.lastMessage?.date);
  return dateB - dateA; // Terkini paling atas
});


    setProspects(prospectsData);
    setError(null);
  } catch (error) {
    console.error('[fetchProspects] Error fetching prospects:', error);
    setError('Gagal mendapatkan prospek. Sila cuba lagi.');
    toast.error(error.response?.data?.message || 'Gagal mendapatkan senarai prospek. Sila cuba lagi.');
  } finally {
    setLoading(false);
  }
}, [therapistId]);


  useEffect(() => {
    fetchProspects();
  }, [fetchProspects]);

  const handleSelectProspect = (prospectId) => {
    setSelectedProspects((prev) => {
      if (prev.includes(prospectId)) {
        return prev.filter((id) => id !== prospectId);
      } else {
        return [...prev, prospectId];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedProspects.length === prospects.length) {
      setSelectedProspects([]);
    } else {
      setSelectedProspects(prospects.map((p) => p._id));
    }
  };

  const deleteMultipleProspects = async () => {
    if (selectedProspects.length === 0) {
      toast.warning('Sila pilih prospek untuk dipadamkan.');
      return;
    }

    if (!window.confirm(`Adakah anda pasti untuk memadam ${selectedProspects.length} prospek yang dipilih?`)) {
      return;
    }

    try {
      setIsDeleting(true);
      const token = localStorage.getItem('token');

      await axios.post(
        '/api/prospects/delete-multiple',
        {
          prospectIds: selectedProspects,
        },
        {
          headers: {
            'x-auth-token': token,
          },
        }
      );

      setProspects(prospects.filter((p) => !selectedProspects.includes(p._id)));
      setSelectedProspects([]);
      toast.success('Prospek berjaya dipadamkan.');
    } catch (error) {
      console.error('[deleteMultipleProspects] Error deleting prospects:', error);
      toast.error('Gagal memadam prospek. Sila cuba lagi.');
    } finally {
      setIsDeleting(false);
    }
  };

  const deleteProspect = async (id) => {
    try {
      setDeletingId(id);
      const token = localStorage.getItem('token');

      await axios.delete(`/api/prospects/${id}`, {
        headers: {
          'x-auth-token': token,
        },
      });

      setProspects(prospects.filter((prospect) => prospect._id !== id));
      toast.success('Prospek berjaya dipadamkan.');
    } catch (error) {
      console.error(`[deleteProspect] Error deleting prospect with ID: ${id}`, error);
      toast.error('Gagal memadam prospek. Sila cuba lagi.');
    } finally {
      setDeletingId(null);
    }
  };

  const confirmDelete = (id, name) => {
    if (window.confirm(`Adakah anda pasti untuk memadam prospek ${name || 'ini'}?`)) {
      deleteProspect(id);
    }
  };

  const formatDate = (date) => {
  if (!date) return '-';
  let formattedDate = new Date(date).toLocaleString('ms-MY', { hour12: true });
  formattedDate = formattedDate.replace(' PG', ' AM').replace(' PTG', ' PM');
  return formattedDate;
};


  const openChat = (phoneNumber) => {
    const formattedNumber = phoneNumber.replace(/\D/g, '');
    window.open(`https://wa.me/${formattedNumber}`, '_blank');
  };

  // Fungsi untuk menapis prospek berdasarkan searchQuery
  const filteredProspects = prospects.filter((prospect) => {
    const query = searchQuery.toLowerCase();
    return prospect.whatsappName.toLowerCase().includes(query) || prospect.phoneNumber.includes(query);
  });

  // Fungsi untuk mengelompokkan prospek mengikut tarikh
const groupProspectsByDate = (prospectsArray) => {
  return prospectsArray.reduce((groups, prospect) => {
    const date = new Date(prospect.createdAt || prospect.lastMessage?.date).toLocaleDateString('ms-MY'); // Hanya tarikh
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(prospect);
    return groups;
  }, {});
};


const groupedProspects = groupProspectsByDate(filteredProspects);

  // Fungsi untuk mengendalikan perubahan input pencarian
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  if (loading)
    return (
      <div className="prospect-list loading">
        <div className="loading-spinner" aria-label="Memuatkan prospek"></div>
        <p>
          Sila tunggu sebentar. Kami sedang memuatkan senarai prospek untuk anda. Anggaran masa 5-10 minit bergantung
          pada saiz data anda. Data juga akan auto kemaskini setiap 15 minit.
        </p>
      </div>
    );

  if (error)
    return (
      <div className="prospect-list error">
        <div className="error-message">
          <p>Ralat: {error}</p>
          <button onClick={fetchProspects} className="retry-button" aria-label="Cuba lagi untuk memuatkan prospek">
            Cuba Lagi
          </button>
        </div>
      </div>
    );

  if (!prospects.length)
    return (
      <div className="prospect-list empty">
        <div className="empty-message">
          <p>Tiada prospek ditemui pada masa ini.</p>
          <button onClick={fetchProspects} className="refresh-button" aria-label="Segarkan senarai prospek">
            Segarkan Senarai
          </button>
        </div>
      </div>
    );

  return (
    <div className="prospect-list">
      <div className="list-header">
        <h2>Senarai Prospek</h2>
        <div className="header-actions">
          {/* Input Pencarian */}
          <input
            type="text"
            placeholder="Cari nama atau no telefon..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="search-input"
            aria-label="Cari nama atau nombor telefon prospek"
          />
          <div className="header-buttons">
            <button
              onClick={fetchProspects}
              className="refresh-button"
              title="Segarkan senarai prospek terkini"
              aria-label="Segarkan senarai prospek"
            >
              <span role="img" aria-label="Refresh">
                🔄
              </span>{' '}
              Segarkan
            </button>
            {selectedProspects.length > 0 && (
              <button
                onClick={deleteMultipleProspects}
                className="delete-selected-button"
                disabled={isDeleting}
                title="Padam semua prospek yang dipilih"
                aria-label="Padam prospek terpilih"
              >
                {isDeleting ? 'Memadam...' : `Padam (${selectedProspects.length})`}
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="table-container">
        {Object.entries(groupedProspects).map(([date, prospectsForDate]) => (
          <div key={date} className="prospect-date-group">
            <h3>
              {date} (Jumlah Prospek: {prospectsForDate.length})
            </h3>
            <table className="prospect-table">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      checked={selectedProspects.length === prospects.length}
                      onChange={handleSelectAll}
                      className="checkbox-input"
                      aria-label={
                        selectedProspects.length === prospects.length ? 'Nyahpilih semua prospek' : 'Pilih semua prospek'
                      }
                      title={selectedProspects.length === prospects.length ? 'Nyahpilih semua prospek' : 'Pilih semua prospek'}
                    />
                  </th>
                  <th>Nama WhatsApp</th>
                  <th>No Telefon</th>
                  <th>Mesej Terakhir</th>
                  <th>Tarikh Terakhir</th>
                  <th>Tindakan</th>
                </tr>
              </thead>
              <tbody>
                {prospectsForDate.map((prospect) => (
                  <tr
                    key={prospect._id}
                    className={selectedProspects.includes(prospect._id) ? 'selected-row' : ''}
                  >
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedProspects.includes(prospect._id)}
                        onChange={() => handleSelectProspect(prospect._id)}
                        className="checkbox-input"
                        aria-label={`Pilih prospek ${prospect.whatsappName}`}
                        title={`Pilih prospek ${prospect.whatsappName}`}
                      />
                    </td>
                    <td>{prospect.whatsappName || '-'}</td>
                    <td>{prospect.phoneNumber}</td>
                    <td className="message-cell">{prospect.lastMessage?.text || '-'}</td>
                    <td>{formatDate(prospect.lastMessage?.date)}</td>
                    <td>
                      <div className="action-buttons">
                        <button
                          onClick={() => openChat(prospect.phoneNumber)}
                          className="chat-button"
                          title={`Mulakan perbualan dengan ${prospect.whatsappName}`}
                          aria-label={`Mulakan perbualan dengan ${prospect.whatsappName}`}
                        >
                          Chat
                        </button>
                        <button
                          onClick={() => confirmDelete(prospect._id, prospect.whatsappName)}
                          className="delete-button"
                          disabled={deletingId === prospect._id}
                          title={`Padam prospek ${prospect.whatsappName}`}
                          aria-label={`Padam prospek ${prospect.whatsappName}`}
                        >
                          {deletingId === prospect._id ? 'Memadam...' : 'Padam'}
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </div>
      <div className="info-section">
        <p>
          <strong>Nota:</strong> Anda boleh menyegarkan senarai prospek untuk mendapatkan data terkini atau padam
          prospek yang tidak diperlukan.
        </p>
        <p>
          Gunakan butang <span className="button-label">Chat</span> untuk berkomunikasi secara langsung dengan prospek
          melalui WhatsApp.
        </p>
      </div>
    </div>
  );
};

export default ProspectList;
