// ChangePassword.js

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './ChangePassword.css';
import config from '../config';


function ChangePassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChangePassword = async (e) => {
  e.preventDefault();
  if (newPassword !== confirmPassword) {
    setError('Passwords do not match');
    return;
  }

  try {
    const therapistId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');

    await axios.put(
      `${config.API_URL}/therapists/change-password/${therapistId}`,
      { newPassword },
      { headers: { 'x-auth-token': token } }
    );

    alert('Password updated successfully!');
    navigate('/therapist-dashboard');
  } catch (error) {
    setError(error.response?.data?.error || 'An error occurred while changing the password');
  }
};

  return (
    <div className="change-password-form">
      <h2>Change Password</h2>
      <form onSubmit={handleChangePassword}>
        <div className="form-group">
          <label htmlFor="newPassword">New Password:</label>
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">Confirm Password:</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        {error && <p className="error-message">{error}</p>}
        <button type="submit" className="change-password-button">Change Password</button>
      </form>
    </div>
  );
}

export default ChangePassword;
