// src/components/PinForm.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PinForm.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Spinner from './Spinner'; // Import Spinner

const PinForm = ({ therapistId, onSuccess, isSetting: initialIsSetting }) => {
  const [pin, setPin] = useState('');
  const [isSetting, setIsSetting] = useState(initialIsSetting || false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkPin = async () => {
      try {
        const res = await axios.get('/pin', {
          headers: {
            'Cache-Control': 'no-cache',
            'Therapist-ID': therapistId,
          },
        });

        if (res.data.exists) {
          setIsSetting(false);
        } else {
          setIsSetting(true);
        }
        setLoading(false);
      } catch (error) {
        toast.error('Ralat semasa menyemak PIN.');
        setLoading(false);
      }
    };

    if (therapistId) {
      checkPin();
    } else {
      toast.error('Therapist ID tidak ditemui.');
      setLoading(false);
    }
  }, [therapistId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (pin.length !== 6 || !/^\d+$/.test(pin)) {
      toast.error('PIN mesti 6 digit numerik.');
      return;
    }

    try {
      if (isSetting) {
        // Tetapkan PIN baru
        const res = await axios.post(
          '/pin',
          { pin },
          {
            headers: {
              'Therapist-ID': therapistId,
            },
          }
        );

        if (res.data.success) {
          toast.success('PIN ditetapkan dengan berjaya.');
          onSuccess();
        } else {
          toast.error(res.data.message || 'Gagal menetapkan PIN.');
        }
      } else {
        // Verifikasi PIN
        const res = await axios.post(
          '/pin/verify',
          { pin },
          {
            headers: {
              'Therapist-ID': therapistId,
            },
          }
        );

        if (res.data.message === 'PIN sah.') {
          toast.success('PIN sah.');
          onSuccess();
        } else {
          toast.error(res.data.message || 'PIN tidak sah.');
        }
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Ralat semasa proses PIN.');
      }
    }
  };

  if (loading) {
    return (
      <div className="spinner-container">
        <Spinner type="ClipLoader" size={50} color="#2980b9" label="Memuatkan..." />
      </div>
    );
  }

  return (
    <div className="pin-form-container">
      <h2>{isSetting ? 'Tetapkan PIN' : 'Masukkan PIN'}</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          maxLength="6"
          placeholder="6 Digit PIN"
          value={pin}
          onChange={(e) => setPin(e.target.value)}
          required
          pattern="\d{6}"
        />
        <button type="submit">{isSetting ? 'Set PIN' : 'Submit'}</button>
      </form>
    </div>
  );
};

export default PinForm;
