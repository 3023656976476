// SubscriptionInfo.js

import React from 'react';
import { FaRegCalendarAlt, FaRegCalendarCheck, FaGem } from 'react-icons/fa';
import './SubscriptionInfo.css';

function SubscriptionInfo({ subscriptionPlan, subscriptionStartDate, subscriptionEndDate }) {
  // Format tarikh
const formatDate = (date) => {
  if (!date) return 'Tidak ditetapkan';
  const day = String(new Date(date).getDate()).padStart(2, '0');
  const month = String(new Date(date).getMonth() + 1).padStart(2, '0');
  const year = new Date(date).getFullYear();
  return `${day}/${month}/${year}`;
};


  // Menentukan ikon berdasarkan pelan langganan
  const getPlanIcon = (plan) => {
    switch (plan) {
      case 'free_trial':
        return <FaRegCalendarAlt />;
      case 'basic':
        return <FaGem />;
      case 'plus':
        return <FaRegCalendarCheck />;
      default:
        return <FaGem />;
    }
  };

  // Menentukan warna berdasarkan pelan langganan
  const getPlanColor = (plan) => {
    switch (plan) {
      case 'free_trial':
        return '#17a2b8'; // Biru
      case 'basic':
        return '#28a745'; // Hijau
      case 'plus':
        return '#ffc107'; // Kuning
      default:
        return '#6c757d'; // Kelabu
    }
  };

  return (
    <div className="subscription-info" style={{ borderLeft: `5px solid ${getPlanColor(subscriptionPlan)}` }}>
      <div className="subscription-icon" style={{ color: getPlanColor(subscriptionPlan) }}>
        {getPlanIcon(subscriptionPlan)}
      </div>
      <div className="subscription-details">
        <p><strong>Pelan:</strong> {subscriptionPlan.replace('_', ' ').toUpperCase()}</p>
        <p><strong>Mula:</strong> {formatDate(subscriptionStartDate)}</p>
        <p><strong>Tamat:</strong> {formatDate(subscriptionEndDate)}</p>
      </div>
    </div>
  );
}

export default SubscriptionInfo;
