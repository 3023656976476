import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './LocationSelector.css';
import config from '../config';


const malaysianStates = [
  'Johor', 'Kedah', 'Kelantan', 'Melaka', 'Negeri Sembilan', 'Pahang', 'Perak', 'Perlis', 
  'Pulau Pinang', 'Sabah', 'Sarawak', 'Selangor', 'Terengganu', 'Wilayah Persekutuan'
];

const LocationSelector = ({ selectedState, selectedCity, onChange }) => {
  const [cities, setCities] = useState([]);
  const [newCity, setNewCity] = useState('');

  useEffect(() => {
    if (selectedState) {
      fetchCities(selectedState);
    }
  }, [selectedState]);

  const fetchCities = async (state) => {
    try {
      const response = await axios.get(`${config.API_URL}/api/cities/${state}`);
      setCities(response.data);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  const handleStateChange = (e) => {
    const state = e.target.value;
    onChange({ state, city: '' });
  };

  const handleCityChange = (e) => {
    const city = e.target.value;
    onChange({ state: selectedState, city });
  };

  const handleAddCity = async () => {
    if (newCity && selectedState) {
      try {
        await axios.post(`${config.API_URL}/api/cities`, { name: newCity, state: selectedState });
        setNewCity('');
        fetchCities(selectedState);
      } catch (error) {
        console.error('Error adding new city:', error);
      }
    }
  };

  return (
    <div className="location-selector">
      <div className="state-selector">
        <select value={selectedState} onChange={handleStateChange}>
          <option value="">Pilih Negeri</option>
          {malaysianStates.map((state) => (
            <option key={state} value={state}>{state}</option>
          ))}
        </select>
      </div>
      
      {selectedState && (
        <div className="city-selector">
          <select value={selectedCity} onChange={handleCityChange}>
            <option value="">Pilih Bandar</option>
            {cities.map((city) => (
              <option key={city} value={city}>{city}</option>
            ))}
          </select>
          <div className="add-new">
            <input
              type="text"
              value={newCity}
              onChange={(e) => setNewCity(e.target.value)}
              placeholder="Tambah bandar baru"
            />
            <button type="button" onClick={handleAddCity}>Tambah</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LocationSelector;