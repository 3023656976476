// src/components/WhatsappIntegration.jsx

import React, { useState, useEffect, useRef } from 'react';
import QRCode from "react-qr-code";
import './WhatsappIntegration.css';
import { ClipLoader } from 'react-spinners';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const WhatsappIntegration = ({ therapistId }) => {
  const [qrCodeData, setQrCodeData] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('disconnected');
  const [showGetQrButton, setShowGetQrButton] = useState(false);
  const sessionId = therapistId;
  const terminateTimeoutRef = useRef(null); 
  const [isEditing, setIsEditing] = useState(false);

  // **Tambahkan Deklarasi `editMode` dan `setEditMode` di sini**
  const [editMode, setEditMode] = useState(false);

  // State untuk toggles dan template pesan
  const [sendBookingMessage, setSendBookingMessage] = useState(false);
  const [sendCancelMessage, setSendCancelMessage] = useState(false);
  const [bookingMessageTemplate, setBookingMessageTemplate] = useState('');
  const [cancelMessageTemplate, setCancelMessageTemplate] = useState('');

  // Enum untuk status
  const STATUS = {
    DISCONNECTED: 'disconnected',
    SESSION_STARTED: 'session_started',
    QR_PENDING: 'qr_pending',
    CONNECTED: 'connected',
    FAILED: 'failed',
  };

  // Tambahkan useRef untuk isSaving
  const isSavingRef = useRef(false);

  // Fungsi untuk mengelakkan caching dengan menambah timestamp
  const getNoCacheHeaders = () => ({
    'x-auth-token': localStorage.getItem('token'),
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
  });

  // Fungsi untuk mengambil message templates dari backend
  const fetchMessageTemplates = async () => {
    try {
      const response = await axios.get('/api/therapists/message-templates', {
        headers: getNoCacheHeaders(),
      });

      const templates = response.data.data;

      // Debug: Log templates yang diambil
      console.log('Templates diambil dari backend:', templates);

      setSendBookingMessage(templates.sendBookingMessage);
      setBookingMessageTemplate(templates.bookingMessageTemplate);
      setSendCancelMessage(templates.sendCancelMessage);
      setCancelMessageTemplate(templates.cancelMessageTemplate);
    } catch (error) {
      console.error('Error fetching message templates:', error);
      toast.error('Gagal mengambil template pesan. Sila cuba lagi.');
    }
  };

  // Semak status sesi pada load pertama dan ambil message templates
  useEffect(() => {
    const checkInitialStatus = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`/api/whatsapp/session-status/${sessionId}`, {
          headers: getNoCacheHeaders(),
        });
        let currentStatus = response.data.status;
        console.log('Status Sesi Semasa (Initial Check):', currentStatus);

        // Tukar currentStatus kepada huruf kecil jika ia bukan null
        currentStatus = currentStatus ? currentStatus.toLowerCase() : null;

        if (!currentStatus) {
          // Jika status null, anggap sesi tiada dan beri mesej mesra
          setStatus(STATUS.DISCONNECTED);
          toast.info('Tiada sesi aktif. Sila sambung WhatsApp untuk memulakan sesi baru.');
        } else if (currentStatus === STATUS.CONNECTED) {
          setStatus(STATUS.CONNECTED);
          toast.success('WhatsApp sudah berjaya disambungkan.');
        } else if (currentStatus === STATUS.FAILED || currentStatus === STATUS.DISCONNECTED) {
          setStatus(STATUS.DISCONNECTED);
          toast.info('Sambungan telah tamat. Sila sambung WhatsApp untuk memulakan semula.');
        } else if (currentStatus === STATUS.SESSION_STARTED) {
          setStatus(STATUS.SESSION_STARTED);
          toast.info('Sesi dimulakan. Sila dapatkan QR code.');
        } else if (currentStatus === STATUS.QR_PENDING) {
          setStatus(STATUS.QR_PENDING);
          toast.info('QR code tersedia. Sila imbas dalam masa 60 saat.');
        }

        // Setelah memeriksa status, ambil message templates
        await fetchMessageTemplates();

      } catch (error) {
        console.error('Ralat semasa menyemak status awal:', error);
        toast.error('Gagal menyemak status sesi. Sila cuba lagi.');
        setStatus(STATUS.DISCONNECTED);
      } finally {
        setLoading(false);
      }
    };

    checkInitialStatus();
  }, [sessionId]);

  // Tambahkan useEffect untuk menguruskan timeout terminate selepas status QR_PENDING
  useEffect(() => {
    if (status === STATUS.QR_PENDING) {
      console.log('Mula timeout terminate selepas status QR_PENDING');
      // Tetapkan timeout selama 1 minit (60,000ms) untuk ujian
      terminateTimeoutRef.current = setTimeout(async () => {
        console.log('Timeout terminate dipanggil selepas 1 minit');
        try {
          const response = await axios.get(`/api/whatsapp/session-status/${sessionId}`, {
            headers: getNoCacheHeaders(),
          });
          let currentStatus = response.data.status;
          console.log('Status Sesi Semasa (Dalam Timeout):', currentStatus);

          // Tukar currentStatus kepada huruf kecil jika ia bukan null
          currentStatus = currentStatus ? currentStatus.toLowerCase() : null;

          if (currentStatus !== STATUS.CONNECTED) {
            console.log('Status tidak CONNECTED. Menamatkan sesi...');
            const terminateResponse = await axios.get(`/api/whatsapp/terminate-session/${sessionId}`, {
              headers: getNoCacheHeaders(),
            });
            console.log('Response Terminate:', terminateResponse.status);

            // Pastikan sesi ditamatkan walaupun respons adalah 304
            setStatus(STATUS.DISCONNECTED);
            setQrCodeData('');
            setShowGetQrButton(false);
            toast.info('Sesi tidak aktif telah ditamatkan.');
          } else {
            console.log('Status CONNECTED. Tidak menamatkan sesi.');
          }
        } catch (error) {
          console.error('Ralat semasa auto-terminate:', error);
          toast.error('Gagal menamatkan sesi secara automatik.');
        }
      }, 60000); // 60,000ms = 1 minit untuk ujian
    }

    // Bersihkan timeout apabila status berubah atau komponen dimusnahkan
    return () => {
      if (terminateTimeoutRef.current) {
        console.log('Membersihkan timeout terminate');
        clearTimeout(terminateTimeoutRef.current);
        terminateTimeoutRef.current = null;
      }
    };
  }, [status, sessionId]);

  // Sambung WhatsApp
  const handleConnectButtonClick = async () => {
    if (status !== STATUS.DISCONNECTED) {
      toast.info('Sesi sedang berjalan. Sila reset sambungan terlebih dahulu jika ingin memulakan sesi baru.');
      return;
    }

    try {
      setLoading(true);
      await axios.get(`/api/whatsapp/start-session/${sessionId}`, {
        headers: getNoCacheHeaders(),
      });
      console.log('Sesi dimulakan dengan jayanya');
      setStatus(STATUS.SESSION_STARTED);

      // Tambah delay 5 saat sebelum butang "Dapatkan QR Code" muncul
      setTimeout(() => {
        setShowGetQrButton(true);
      }, 5000);
    } catch (error) {
      console.error('Ralat semasa menyambung:', error);
      if (error.response && error.response.status === 500) {
        toast.error('Sesi lama wujud. Sila reset sambungan untuk memulakan semula.');
      } else {
        toast.error('Gagal menyambung ke WhatsApp. Sila cuba lagi.');
      }
    } finally {
      setLoading(false);
    }
  };

  // Tambahkan masa untuk paparan QR Code
  const handleGetQrCodeClick = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/api/whatsapp/session-qr/${sessionId}`, {
        headers: getNoCacheHeaders(),
      });
      const qrData = response.data.qrCodeData;
      if (qrData) {
        setQrCodeData(qrData);
        setStatus(STATUS.QR_PENDING);
        setShowGetQrButton(false);
      } else {
        console.error('Ralat: Data QR tidak tersedia');
        toast.error('QR Code tidak tersedia. Sila cuba semula.');
        setShowGetQrButton(true);
      }
    } catch (error) {
      console.error('Ralat semasa mendapatkan QR code:', error);
      toast.error('Gagal mendapatkan QR Code. Sila cuba lagi.');
      setShowGetQrButton(true);
    } finally {
      setLoading(false);
    }
  };

  // Reset sambungan
  const handleResetConnectionClick = async () => {
    try {
      setLoading(true);
      await axios.get(`/api/whatsapp/terminate-session/${sessionId}`, {
        headers: getNoCacheHeaders(),
      });
      console.log('Semua sesi telah ditamatkan');
      setStatus(STATUS.DISCONNECTED);
      setQrCodeData('');
      setShowGetQrButton(false);
      toast.success('Sambungan telah direset.');
      // Ambil semula message templates untuk memastikan state adalah konsisten
      await fetchMessageTemplates();
    } catch (error) {
      console.error('Ralat semasa reset sambungan:', error);
      toast.error('Gagal reset sambungan. Sila cuba lagi.');
    } finally {
      setLoading(false);
    }
  };

  // Putuskan sambungan
  const handleDisconnectButtonClick = async () => {
    try {
      setLoading(true);
      await axios.get(`/api/whatsapp/terminate-session/${sessionId}`, {
        headers: getNoCacheHeaders(),
      });
      console.log('Sesi telah diputuskan');
      setStatus(STATUS.DISCONNECTED);
      setQrCodeData('');
      setShowGetQrButton(false);
      toast.success('Sambungan telah diputuskan.');
      // Ambil semula message templates untuk memastikan state adalah konsisten
      await fetchMessageTemplates();
    } catch (error) {
      console.error('Ralat semasa putuskan sambungan:', error);
      toast.error('Gagal putuskan sambungan. Sila cuba lagi.');
    } finally {
      setLoading(false);
    }
  };

  // Tambah butang Edit dan Batalkan Penyuntingan
  const handleEditClick = () => {
    setEditMode(true);
    setIsEditing(true);
    // Tidak perlu menghentikan polling kerana polling telah dihapuskan
  };

  const handleCancelEdit = async () => {
    setEditMode(false);
    setIsEditing(false);
    // Ambil semula data dari server untuk membatalkan perubahan
    await fetchMessageTemplates();
  };

  // Fungsi untuk menangani perubahan toggle "Send Mesej Booking Berjaya"
  const handleSendBookingMessageToggle = (e) => {
    setSendBookingMessage(e.target.checked);
  };

  // Fungsi untuk menangani perubahan toggle "Send Mesej Cancel Berjaya"
  const handleSendCancelMessageToggle = (e) => {
    setSendCancelMessage(e.target.checked);
  };

  // Fungsi untuk menangani perubahan template pesan booking
  const handleBookingMessageTemplateChange = (e) => {
    setBookingMessageTemplate(e.target.value);
  };

  // Fungsi untuk menangani perubahan template pesan cancel
  const handleCancelMessageTemplateChange = (e) => {
    setCancelMessageTemplate(e.target.value);
  };

  const handleSaveTemplates = async () => {
    try {
      setLoading(true);
      isSavingRef.current = true; // Mulakan proses penyimpanan
      const payload = {
        sendBookingMessage,
        bookingMessageTemplate,
        sendCancelMessage,
        cancelMessageTemplate,
      };

      // Debug: Log payload sebelum menghantar
      console.log('Payload sebelum simpan:', payload);

      // Pastikan bahwa jika toggle dihidupkan, template tidak kosong
      if (sendBookingMessage && (!bookingMessageTemplate || bookingMessageTemplate.trim() === '')) {
        toast.error('Template pesan booking diperlukan ketika toggle dihidupkan.');
        setLoading(false);
        isSavingRef.current = false;
        return;
      }
      if (sendCancelMessage && (!cancelMessageTemplate || cancelMessageTemplate.trim() === '')) {
        toast.error('Template pesan cancel diperlukan ketika toggle dihidupkan.');
        setLoading(false);
        isSavingRef.current = false;
        return;
      }

      const response = await axios.put('/api/therapists/message-templates', payload, {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('token'),
        },
      });

      // Debug: Log response
      console.log('Response selepas simpan:', response.data);

      toast.success('Template pesan berhasil disimpan.');
      setEditMode(false);
      setIsEditing(false);
      await fetchMessageTemplates(); // Refresh data dari server
    } catch (error) {
      console.error('Ralat semasa menyimpan template pesan:', error);
      if (error.response) {
        toast.error(`Gagal menyimpan template pesan: ${error.response.data.message}`);
      } else {
        toast.error('Gagal menyimpan template pesan. Sila cuba lagi.');
      }
    } finally {
      setLoading(false);
      isSavingRef.current = false; // Tamatkan proses penyimpanan
    }
  };

  return (
    <div className="whatsapp-integration-container">
      <h2>Integrasi WhatsApp</h2>
      <p>
        Status:{' '}
        {loading
          ? 'Sila tunggu, sedang menyemak status sambungan anda...'
          : status === STATUS.CONNECTED
          ? 'WhatsApp Berjaya Disambung'
          : status === STATUS.QR_PENDING
          ? 'Dalam Proses Sambungan (QR Diperlukan)'
          : status === STATUS.SESSION_STARTED
          ? 'Sesi Sedang Dimulakan. Sila tunggu butang dapatkan QR Code.'
          : 'Tidak Disambung'}
      </p>

      {/* Spinner semasa loading */}
      {loading && (
        <div className="spinner-container">
          <ClipLoader color="#4CAF50" loading={loading} size={50} />
        </div>
      )}

      {/* Butang "Sambung WhatsApp" */}
      <button
        id="connectButton"
        onClick={handleConnectButtonClick}
        disabled={loading || status !== STATUS.DISCONNECTED}
      >
        Sambung WhatsApp
      </button>

      {/* Butang "Dapatkan QR Code" */}
      {showGetQrButton && status === STATUS.SESSION_STARTED && (
        <button
          id="getQrButton"
          onClick={handleGetQrCodeClick}
          disabled={loading}
        >
          Dapatkan QR Code
        </button>
      )}

      {/* Butang "Putuskan Sambungan" */}
      {status === STATUS.CONNECTED && !editMode && (
        <button id="disconnectButton" onClick={handleDisconnectButtonClick} disabled={loading}>
          Putuskan Sambungan
        </button>
      )}

      {/* Butang "Reset Sambungan" */}
      <button
        id="resetButton"
        onClick={handleResetConnectionClick}
        disabled={loading}
      >
        Reset Sambungan
      </button>

      {/* Paparkan QR Code */}
      {qrCodeData && status === STATUS.QR_PENDING && (
        <div id="qrCodeContainer">
          <QRCode value={qrCodeData} size={200} />
          <p>Sila imbas QR Code ini dengan WhatsApp anda dalam masa 60 saat.</p>
          <p>Semakan status sambungan sedang dijalankan...</p>
        </div>
      )}

      {/* Bagian Tambahan: Toggle dan Template Pesan */}
      {status === STATUS.CONNECTED && (
        <div className="message-settings">
          <h3>Pengaturan Pesan</h3>

          {/* Butang Edit */}
          {!editMode && (
            <button
              id="editButton"
              onClick={handleEditClick}
              disabled={loading}
              className="edit-button"
            >
              Edit Template
            </button>
          )}

          {/* Toggle untuk "Send Mesej Booking Berjaya" */}
          <div className="whatsapp-toggle-container">
            <label className="whatsapp-toggle-label">
              Send Mesej Booking Berjaya
              <input
                type="checkbox"
                checked={sendBookingMessage}
                onChange={handleSendBookingMessageToggle}
                disabled={loading}
              />
              <span className="whatsapp-toggle-switch"></span>
            </label>
          </div>

          {/* Field Template untuk "Send Mesej Booking Berjaya" */}
          {sendBookingMessage && (
            <div className="template-field">
              <label htmlFor="bookingTemplate">Template Pesan Booking:</label>
              <textarea
                id="bookingTemplate"
                value={bookingMessageTemplate}
                onChange={handleBookingMessageTemplateChange}
                placeholder="Masukkan template pesan booking di sini..."
                rows="3"
                disabled={!editMode}
              ></textarea>
              <p className="template-info">
                Sila gunakan medan yang ada: <br />
                <code>{'{{userName}}'}</code>, <code>{'{{treatment}}'}</code>, <code>{'{{bookingDate}}'}</code>, <code>{'{{userPhone}}'}</code>, <code>{'{{startTime}}'}</code>, <code>{'{{endTime}}'}</code>
              </p>
            </div>
          )}

          {/* Toggle untuk "Send Mesej Cancel Berjaya" */}
          <div className="whatsapp-toggle-container">
            <label className="whatsapp-toggle-label">
              Send Mesej Cancel Berjaya
              <input
                type="checkbox"
                checked={sendCancelMessage}
                onChange={handleSendCancelMessageToggle}
                disabled={loading}
              />
              <span className="whatsapp-toggle-switch"></span>
            </label>
          </div>

          {/* Field Template untuk "Send Mesej Cancel Berjaya" */}
          {sendCancelMessage && (
            <div className="template-field">
              <label htmlFor="cancelTemplate">Template Pesan Cancel:</label>
              <textarea
                id="cancelTemplate"
                value={cancelMessageTemplate}
                onChange={handleCancelMessageTemplateChange}
                placeholder="Masukkan template pesan cancel di sini..."
                rows="3"
                disabled={!editMode}
              ></textarea>
              <p className="template-info">
                Sila gunakan medan yang ada: <br />
                <code>{'{{userName}}'}</code>, <code>{'{{treatment}}'}</code>, <code>{'{{bookingDate}}'}</code>, <code>{'{{userPhone}}'}</code>, <code>{'{{startTime}}'}</code>, <code>{'{{endTime}}'}</code> 
              </p>
            </div>
          )}

          {/* Tombol untuk menyimpan atau membatalkan template pesan */}
          {editMode && (
            <div className="edit-button-container">
              <button
                id="saveTemplatesButton"
                onClick={handleSaveTemplates}
                disabled={loading}
                className="save-button"
              >
                Simpan Template Pesan
              </button>
              <button
                id="cancelEditButton"
                onClick={handleCancelEdit}
                disabled={loading}
                className="cancel-button"
              >
                Batalkan
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default WhatsappIntegration;
