import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import './DashboardStatistics.css';
import config from '../config';

function DashboardStatistics() {
  const [stats, setStats] = useState({
    totalTherapists: 0,
    totalBookings: 0,
    todayBookings: 0,
    activeTherapists: 0,
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchStatistics();
  }, []);

  const fetchStatistics = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/admin/dashboard-stats`);
      console.log('Received stats:', response.data);

      const today = moment().startOf('day');

      const todayBookings = response.data.bookings.filter((booking) => {
        const bookingDate = moment(booking.bookingDate);
        return bookingDate.isSame(today, 'day');
      }).length;

      setStats({
        totalTherapists: response.data.totalTherapists,
        totalBookings: response.data.bookings.length,
        todayBookings: todayBookings,
        activeTherapists: response.data.activeTherapists,
      });
      setError(null);
    } catch (error) {
      console.error('Error fetching dashboard statistics:', error);
      setError('Failed to load statistics. Please try again later.');
    }
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="dashboard-statistics">
      <h2>Dashboard Statistics</h2>
      <div className="stats-container">
        <div className="stat-card">
          <h3>Total Therapists</h3>
          <p>{stats.totalTherapists}</p>
        </div>
        <div className="stat-card">
          <h3>Total Bookings</h3>
          <p>{stats.totalBookings}</p>
        </div>
        <div className="stat-card">
          <h3>Today's Bookings</h3>
          <p>{stats.todayBookings}</p>
        </div>
        <div className="stat-card">
          <h3>Therapists with Available Slots</h3>
          <p>{stats.activeTherapists}</p>
        </div>
      </div>
    </div>
  );
}

export default DashboardStatistics;