import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Customer.css';
import { toast } from 'react-toastify';

const Customer = ({ therapistId }) => {
  const [customers, setCustomers] = useState([]);
  const [groupedCustomers, setGroupedCustomers] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [repeatedBookings, setRepeatedBookings] = useState({});
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDate, setSelectedDate] = useState(''); // Tambah state untuk tarikh dipilih

  useEffect(() => {
    if (therapistId) {
      fetchCustomers();
    }
  }, [therapistId, selectedDate]); // Tambah selectedDate sebagai dependency

  const fetchCustomers = async () => {
    try {
      setLoading(true);
      setSelectedCustomers([]); // Reset pilihan apabila memuat semula
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/customers/${therapistId}`,
        {
          headers: {
            'x-auth-token': token,
            'Content-Type': 'application/json',
          },
        }
      );

      if (Array.isArray(response.data)) {
        let customersData = response.data;

        // Jika tarikh dipilih, tapis pelanggan berdasarkan tarikh tersebut
        if (selectedDate) {
          const selected = new Date(selectedDate);
          customersData = customersData.filter(customer =>
            isSameDay(new Date(customer.bookingDate), selected)
          );
        }

        // Susun pelanggan mengikut tarikh secara menaik
        customersData.sort((a, b) => new Date(a.bookingDate) - new Date(b.bookingDate));

        // Mengumpulkan pelanggan mengikut tarikh
        const grouped = customersData.reduce((acc, customer) => {
          const date = formatDate(customer.bookingDate);
          if (!acc[date]) {
            acc[date] = [];
          }
          acc[date].push(customer);
          return acc;
        }, {});

        // Menyusun pelanggan dalam setiap tarikh mengikut masa
        Object.keys(grouped).forEach(date => {
          grouped[date].sort((a, b) => a.startTime.localeCompare(b.startTime));
        });

        setGroupedCustomers(grouped);
        setCustomers(customersData);

        // Mendapatkan jumlah tempahan berulang bagi setiap pelanggan
        const repeatedData = {};
        await Promise.all(
          customersData.map(async (customer) => {
            try {
              const repeatedResponse = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/customers/repeated/${customer.phone}`,
                {
                  headers: { 'x-auth-token': token }
                }
              );
              repeatedData[customer.phone] = repeatedResponse.data.count;
            } catch (error) {
              console.error(`Error fetching repeated count for ${customer.phone}:`, error);
              repeatedData[customer.phone] = 0;
            }
          })
        );
        setRepeatedBookings(repeatedData);
      }
    } catch (error) {
      console.error('Error fetching customers:', error);
      setError(true);
      toast.error('Gagal memuat data pelanggan');
    } finally {
      setLoading(false);
    }
  };

  // Fungsi untuk menyemak sama ada dua tarikh adalah hari yang sama
  const isSameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  // Fungsi carian pelanggan
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Fungsi memilih tarikh
  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  // Menapis pelanggan berdasarkan input carian dan tarikh
  const filteredGroupedCustomers = Object.keys(groupedCustomers).reduce((acc, date) => {
    const filteredCustomers = groupedCustomers[date].filter(customer =>
      customer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      customer.phone.includes(searchTerm)
    );
    if (filteredCustomers.length > 0) {
      acc[date] = filteredCustomers;
    }
    return acc;
  }, {});

  // Handle checkbox selection
  const handleSelectCustomer = (customerId) => {
    setSelectedCustomers(prev => {
      if (prev.includes(customerId)) {
        return prev.filter(id => id !== customerId);
      } else {
        return [...prev, customerId];
      }
    });
  };

  // Handle select all
  const handleSelectAll = () => {
    const allCustomerIds = Object.values(filteredGroupedCustomers).flat().map(c => c._id);
    if (selectedCustomers.length === allCustomerIds.length) {
      setSelectedCustomers([]);
    } else {
      setSelectedCustomers(allCustomerIds);
    }
  };

  // Delete multiple customers
  const deleteMultipleCustomers = async () => {
    if (selectedCustomers.length === 0) {
      toast.warning('Sila pilih pelanggan untuk dipadamkan');
      return;
    }

    if (!window.confirm(`Adakah anda pasti untuk memadam ${selectedCustomers.length} pelanggan yang dipilih?`)) {
      return;
    }

    try {
      setIsDeleting(true);
      const token = localStorage.getItem('token');
      
      await axios.post('/api/customers/delete-multiple', {
        customerIds: selectedCustomers
      }, {
        headers: {
          'x-auth-token': token
        }
      });

      // Kemaskini state pelanggan
      const updatedCustomers = customers.filter(c => !selectedCustomers.includes(c._id));
      setCustomers(updatedCustomers);

      // Kemaskini pelanggan yang dikumpulkan
      const updatedGroupedCustomers = updatedCustomers.reduce((acc, customer) => {
        const date = formatDate(customer.bookingDate);
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(customer);
        return acc;
      }, {});
      setGroupedCustomers(updatedGroupedCustomers);

      setSelectedCustomers([]);
      toast.success('Pelanggan berjaya dipadamkan');
    } catch (error) {
      console.error('Error deleting customers:', error);
      toast.error('Gagal memadam pelanggan');
    } finally {
      setIsDeleting(false);
    }
  };

  // Single delete customer function
  const deleteCustomer = async (id) => {
    try {
      setDeletingId(id);
      const token = localStorage.getItem('token');
      
      await axios.delete(`/api/customers/${id}`, {
        headers: {
          'x-auth-token': token
        }
      });

      const updatedCustomers = customers.filter(customer => customer._id !== id);
      setCustomers(updatedCustomers);

      // Kemaskini pelanggan yang dikumpulkan
      const updatedGroupedCustomers = updatedCustomers.reduce((acc, customer) => {
        const date = formatDate(customer.bookingDate);
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(customer);
        return acc;
      }, {});
      setGroupedCustomers(updatedGroupedCustomers);

      toast.success('Pelanggan berjaya dipadamkan');
    } catch (error) {
      console.error('Error deleting customer:', error);
      toast.error('Gagal memadam pelanggan');
    } finally {
      setDeletingId(null);
    }
  };

  // Confirm single delete
  const confirmDelete = (id, name) => {
    if (window.confirm(`Adakah anda pasti untuk memadam pelanggan ${name || 'ini'}?`)) {
      deleteCustomer(id);
    }
  };

  const getStatusBadgeClass = (status) => {
    return status === 'completed' ? 'status-badge completed' : 'status-badge in-progress';
  };

  const getRepeatedBadgeClass = (count) => {
    if (count >= 3) return 'repeated-badge vvip';
    if (count === 2) return 'repeated-badge regular';
    return 'repeated-badge new';
  };

  const formatDate = (date) => {
    if (!date) return '-';
    return new Date(date).toLocaleDateString('ms-MY', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
  };

  if (loading) return <div className="loading-state">Memuatkan pelanggan...</div>;
  if (error) return <div className="error-state">Ralat memuatkan pelanggan. Sila cuba lagi.</div>;
  if (!customers.length) return <div className="empty-state">Tiada pelanggan ditemui.</div>;

  return (
    <div className="customer-list">
      <div className="list-header">
        <h2>Senarai Pelanggan</h2>
        <div className="filters"> {/* Tambah div untuk filters */}
          <input
            type="date"
            value={selectedDate}
            onChange={handleDateChange}
            className="date-input"
          />
          <input
            type="text"
            placeholder="Cari pelanggan..."
            value={searchTerm}
            onChange={handleSearch}
            className="search-input"
          />
          {selectedDate && (
            <button
              className="clear-filter-button"
              onClick={() => setSelectedDate('')}
            >
              Clear Tarikh
            </button>
          )}
        </div>
        {selectedCustomers.length > 0 && (
          <button 
            onClick={deleteMultipleCustomers}
            className="delete-selected-button"
            disabled={isDeleting}
          >
            {isDeleting ? 'Memadam...' : `Padam (${selectedCustomers.length})`}
          </button>
        )}
      </div>
      <div className="table-container">
        <table className="customer-table">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  checked={selectedCustomers.length === Object.values(filteredGroupedCustomers).flat().length && selectedCustomers.length > 0}
                  onChange={handleSelectAll}
                  className="checkbox-input"
                />
              </th>
              <th>Nama</th>
              <th>No Telefon</th>
              <th>Rawatan</th>
              <th>Tarikh Tempahan</th>
              <th>Masa</th>
              <th>Nama Therapist</th>
              <th>Status</th>
              <th>Jumlah Tempahan</th>
              <th>Tindakan</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(filteredGroupedCustomers).map(date => (
              <React.Fragment key={date}>
                <tr className="date-row">
                  <td colSpan="10">
                    <strong>{date}</strong> - Jumlah Pelanggan: {filteredGroupedCustomers[date].length}
                  </td>
                </tr>
                {filteredGroupedCustomers[date].map((customer) => (
                  <tr key={customer._id} className={selectedCustomers.includes(customer._id) ? 'selected-row' : ''}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedCustomers.includes(customer._id)}
                        onChange={() => handleSelectCustomer(customer._id)}
                        className="checkbox-input"
                      />
                    </td>
                    <td>{customer.name}</td>
                    <td>{customer.phone}</td>
                    <td>{customer.treatment}</td>
                    <td>{formatDate(customer.bookingDate)}</td>
                    <td>{customer.startTime} - {customer.endTime}</td>
                    <td>{customer.therapistName}</td>
                    <td>
                      <span className={getStatusBadgeClass(customer.status)}>
                        {customer.status === 'completed' ? 'Selesai' : 'Dalam Proses'}
                      </span>
                    </td>
                    <td>
                      <span className={getRepeatedBadgeClass(repeatedBookings[customer.phone] || 0)}>
                        {repeatedBookings[customer.phone] || 0} kali
                      </span>
                    </td>
                    <td>
                      <button
                        onClick={() => confirmDelete(customer._id, customer.name)}
                        className="delete-button"
                        disabled={deletingId === customer._id}
                      >
                        {deletingId === customer._id ? 'Memadam...' : 'Padam'}
                      </button>
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Customer;
