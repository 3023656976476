import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './LocationSelector.css';
import config from '../config';


const malaysianStates = [
  'Johor', 'Kedah', 'Kelantan', 'Melaka', 'Negeri Sembilan', 'Pahang', 'Perak', 'Perlis', 
  'Pulau Pinang', 'Sabah', 'Sarawak', 'Selangor', 'Terengganu', 'Wilayah Persekutuan'
];

const UserLocationSelector = ({ selectedState, selectedCity, onChange }) => {
  const [cities, setCities] = useState([]);

  useEffect(() => {
    if (selectedState) {
      fetchCities(selectedState);
    }
  }, [selectedState]);

  const fetchCities = async (state) => {
    try {
      const response = await axios.get(`${config.API_URL}/api/cities/${state}`);
      setCities(response.data);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  const handleStateChange = (e) => {
    const state = e.target.value;
    onChange({ state, city: '' });
  };

  const handleCityChange = (e) => {
    const city = e.target.value;
    onChange({ state: selectedState, city });
  };

  return (
    <div className="location-selector">
      <select value={selectedState} onChange={handleStateChange}>
        <option value="">Pilih Negeri</option>
        {malaysianStates.map((state) => (
          <option key={state} value={state}>{state}</option>
        ))}
      </select>
      
      {selectedState && (
        <select value={selectedCity} onChange={handleCityChange}>
          <option value="">Pilih Bandar</option>
          {cities.map((city) => (
            <option key={city} value={city}>{city}</option>
          ))}
        </select>
      )}
    </div>
  );
};

export default UserLocationSelector;