// SalesReport.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './SalesReport.css';
import { toast } from 'react-toastify';
import config from '../config';

function SalesReport({ booking, onClose }) {
  const [salesData, setSalesData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${config.API_URL}/api/sales/booking/${booking._id}`, {
          headers: { 'x-auth-token': token },
        });
        setSalesData(response.data);
      } catch (error) {
        console.error('Error fetching sales data:', error);
        toast.error('Gagal mendapatkan data jualan.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchSalesData();
  }, [booking._id]);

  if (isLoading) {
    return (
      <div className="spinner-container">
        <span>Loading...</span>
      </div>
    );
  }

  if (!salesData) {
    return (
      <div className="no-sales-data">
        <p>Tiada data jualan untuk tempahan ini.</p>
        <button className="close-button" onClick={onClose}>Tutup</button>
      </div>
    );
  }

return (
  <div className="sales-report-wrapper">
    <div className="sales-report">
      <h2>Laporan Jualan</h2>
      <div className="report-details">
        <p><strong>ID Tempahan:</strong> {salesData.booking}</p>
        <p><strong>Dibuat Oleh:</strong> {salesData.createdBy}</p>
        <p><strong>Tarikh Dibuat:</strong> {new Date(salesData.createdAt).toLocaleDateString()}</p>
      </div>
      <table className="sales-table">
        <thead>
          <tr>
            <th>Nama Therapist</th>
            <th>Rawatan</th>
            <th>Jumlah Sales (RM)</th>
          </tr>
        </thead>
        <tbody>
          {salesData.therapists && Array.isArray(salesData.therapists) && salesData.therapists.length > 0 ? (
            salesData.therapists.map((t, index) => (
              <tr key={index}>
                <td>{t.therapist && t.therapist.name ? t.therapist.name : 'N/A'}</td>
                <td>{t.treatment && t.treatment.name ? t.treatment.name : 'N/A'}</td>
                <td>{t.salesAmount.toFixed(2)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">Tiada data jualan untuk dipaparkan.</td>
            </tr>
          )}
        </tbody>
      </table>
      <button className="close-button" onClick={onClose}>Tutup</button>
    </div>
  </div>
);

}

export default SalesReport;