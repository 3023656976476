import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import './ManageTherapistContacts.css';
import { toast } from 'react-toastify';
import config from '../config';
import debounce from 'lodash.debounce';

function ManageTherapistContacts() {
  const [therapists, setTherapists] = useState([]);
  const [groupedTherapists, setGroupedTherapists] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');

  const STATUS = {
    DISCONNECTED: 'Tiada Sambungan',
    CONNECTED: 'Terhubung',
    SESSION_NOT_CONNECTED: 'Tidak Terhubung',
    SESSION_NOT_FOUND: 'Tiada Sambungan',
    QR_PENDING: 'Dalam Proses Sambungan (QR Diperlukan)',
    UNKNOWN: 'Tidak Diketahui',
  };

  // Semak status WhatsApp untuk seorang therapist
  const fetchWhatsAppStatus = async (therapist) => {
    const sessionId = therapist._id;

    if (!sessionId) {
      console.warn(`Therapist ${therapist._id} tidak mempunyai sessionId.`);
      return STATUS.SESSION_NOT_FOUND;
    }

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('Token tidak ditemui dalam localStorage.');
        return STATUS.UNKNOWN;
      }

      // Elakkan cache dengan menambah parameter unik
      const url = `${config.API_URL}/api/whatsapp/session-status/${sessionId}?t=${Date.now()}`;
      const response = await axios.get(url, {
        headers: {
          'x-auth-token': token,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        },
      });

      const { status } = response.data;
      console.log(`Status WhatsApp untuk Therapist ${therapist._id}:`, response.data);

      if (!status) {
        localStorage.setItem(`whatsappStatus_${sessionId}`, 'UNKNOWN');
        return STATUS.UNKNOWN;
      }

      const normalizedStatus = status.toUpperCase();

      let mappedStatus;
      switch (normalizedStatus) {
        case 'CONNECTED':
          mappedStatus = STATUS.CONNECTED;
          break;
        case 'DISCONNECTED':
          mappedStatus = STATUS.DISCONNECTED;
          break;
        case 'SESSION_NOT_CONNECTED':
          mappedStatus = STATUS.SESSION_NOT_CONNECTED;
          break;
        case 'SESSION_NOT_FOUND':
          mappedStatus = STATUS.SESSION_NOT_FOUND;
          break;
        case 'QR_PENDING':
          mappedStatus = STATUS.QR_PENDING;
          break;
        default:
          mappedStatus = STATUS.UNKNOWN;
          break;
      }

      // Simpan dalam localStorage jika diperlukan
      localStorage.setItem(`whatsappStatus_${sessionId}`, mappedStatus);

      return mappedStatus;

    } catch (err) {
      console.error(`Error fetching WhatsApp status for therapist ${therapist._id}:`, err);
      return STATUS.UNKNOWN;
    }
  };

  // Dapatkan data therapist dan semak status WhatsApp mereka
  const fetchTherapists = async () => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) throw new Error('Auth token tidak ditemui.');

      const response = await axios.get(`${config.API_URL}/therapists`, {
        headers: { 'x-auth-token': token },
      });

      const therapistsData = response.data;

      const therapistsWithStatus = await Promise.all(
        therapistsData.map(async (therapist) => {
          const status = await fetchWhatsAppStatus(therapist);
          console.log(`Therapist ID ${therapist._id} dikemaskini dengan status: ${status}`);
          return { ...therapist, whatsappStatus: status };
        })
      );

      console.log('Senarai terapist dengan status terkini:', therapistsWithStatus);

      setTherapists(therapistsWithStatus); // Update state
      groupByState(therapistsWithStatus);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching therapists:', err);
      setError(true);
      setLoading(false);
      toast.error('Gagal mendapatkan senarai terapis.');
    }
  };

  // Kumpulkan therapist mengikut negeri
  const groupByState = (therapistsList) => {
    const grouped = therapistsList.reduce((acc, therapist) => {
      const state = therapist.location?.state || 'Tidak Diketahui';
      if (!acc[state]) {
        acc[state] = [];
      }
      acc[state].push(therapist);
      return acc;
    }, {});
    setGroupedTherapists(grouped);
  };

  // Fungsi untuk mengaplikasikan kedua-dua penapis carian dan status
  const applyFilters = (query, status) => {
    let filtered = therapists;

    if (query.trim() !== '') {
      const lowerCaseQuery = query.toLowerCase();
      filtered = filtered.filter((therapist) => {
        const idMatch = therapist._id?.toLowerCase().includes(lowerCaseQuery);
        const nameMatch = therapist.name?.toLowerCase().includes(lowerCaseQuery);
        const phoneMatch = therapist.phone?.toLowerCase().includes(lowerCaseQuery);
        const stateMatch = therapist.location?.state?.toLowerCase().includes(lowerCaseQuery);
        const statusMatch = therapist.whatsappStatus?.toLowerCase().includes(lowerCaseQuery);

        return idMatch || nameMatch || phoneMatch || stateMatch || statusMatch;
      });
    }

    if (status !== '') {
      filtered = filtered.filter(
        (therapist) => therapist.whatsappStatus === status
      );
    }

    groupByState(filtered);
  };

  // Fungsi untuk menangani perubahan dalam kotak carian dengan debouncing
  const debouncedApplyFilters = useCallback(
    debounce((query, status) => {
      applyFilters(query, status);
    }, 300),
    [therapists]
  );

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    debouncedApplyFilters(query, selectedStatus);
  };

  // Fungsi untuk menangani perubahan dalam dropdown status dengan debouncing
  const handleStatusChange = (e) => {
    const status = e.target.value;
    setSelectedStatus(status);
    debouncedApplyFilters(searchQuery, status);
  };

  // Semak data therapist ketika komponen dimount
  useEffect(() => {
    fetchTherapists();
    // Cleanup debounced function on unmount
    return () => {
      debouncedApplyFilters.cancel();
    };
  }, []); // Kosong dependency array

  if (loading) {
    return <div className="manage-therapist-contacts"><p>Loading...</p></div>;
  }

  if (error) {
    return <div className="manage-therapist-contacts"><p>Error fetching therapists.</p></div>;
  }

  return (
    <div className="manage-therapist-contacts">
      <h2>Senarai Terapis dan Maklumat Perhubungan</h2>
      
      {/* Kotak Carian dan Dropdown Status */}
      <div className="search-container">
        <input
          type="text"
          placeholder="Cari berdasarkan ID, Nama, Telefon, Negeri, atau Status..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="search-input"
        />
        {/* Dropdown Status */}
        <select
          value={selectedStatus}
          onChange={handleStatusChange}
          className="status-select"
        >
          <option value="">Semua Status</option>
          {Object.values(STATUS).map((status) => (
            <option key={status} value={status}>{status}</option>
          ))}
        </select>
      </div>

      {Object.keys(groupedTherapists).length === 0 ? (
        <p className="no-results">Tiada hasil dijumpai.</p>
      ) : (
        Object.keys(groupedTherapists).map((state) => (
          <div key={state} className="state-section">
            <h3 className="state-title">
              {state} ({groupedTherapists[state].length})
            </h3>
            <div className="table-container">
              <table className="therapist-table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Nama</th>
                    <th>Email</th>
                    <th>Telefon</th>
                    <th>Bandar</th>
                    <th>Whatsapp</th>
                    <th>Status Whatsapp</th>
                  </tr>
                </thead>
                <tbody>
                  {groupedTherapists[state].map((therapist) => (
                    <tr key={therapist._id}>
                      <td>{therapist._id || 'N/A'}</td>
                      <td>{therapist.name || 'Unknown'}</td>
                      <td>{therapist.email || 'Tidak disediakan'}</td>
                      <td>{therapist.phone || 'Tidak disediakan'}</td>
                      <td>{therapist.location?.city || 'Tidak Diketahui'}</td>
                      <td>
                        {therapist.phone ? (
                          <a href={`https://wa.me/${therapist.phone}`} target="_blank" rel="noopener noreferrer">
                            {therapist.phone}
                          </a>
                        ) : (
                          'N/A'
                        )}
                      </td>
                      <td>{therapist.whatsappStatus || 'Memuatkan...'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default ManageTherapistContacts;
