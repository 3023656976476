// ForgotPassword.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './ForgotPassword.css';
import config from '../config';


function ForgotPassword() {
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleForgotPassword = async (e) => {
  e.preventDefault();
  setError('');
  setSuccess('');
  setLoading(true);

  try {
    const response = await axios.post(`${config.API_URL}/therapists/forgot-password`, { phone });
    setSuccess(response.data.message || 'Link reset password telah dihantar melalui WhatsApp.');
  } catch (error) {
    setError(error.response?.data?.error || 'Gagal untuk reset password');
  } finally {
    setLoading(false);
  }
};



  return (
    <div className="forgot-password-form">
      <h2>Forgot Password</h2>
      <form onSubmit={handleForgotPassword}>
        <div>
          <label htmlFor="phone">Nombor Telefon:</label>
          <input
            type="text"
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? 'Processing...' : 'Send Reset Link'}
        </button>
      </form>
      {error && <p className="error-message">{error}</p>}
      {success && <p className="success-message">{success}</p>}
      <button onClick={() => navigate('/login')} className="back-button">Back to Login</button>
    </div>
  );
}

export default ForgotPassword;