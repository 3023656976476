// InputField.js

import React from 'react';
import './InputField.css';

function InputField({ label, type, id, name, value, onChange, required, error }) {
  return (
    <div className="input-field-group">
      <label htmlFor={id}>{label}</label>
      <input
        type={type}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
      />
      {error && <span className="error-message">{error}</span>}
    </div>
  );
}

export default InputField;
