// BookingForm.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './BookingForm.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faMapMarkerAlt, faCar, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import { toZonedTime, formatDate, parseAndZone, isAfterWithZone, addMinutesWithZone, isSameDayWithZone } from './dateUtils';
import config from '../config';

const TimeSlot = ({ time, isSelected, onClick }) => (
  <button
    type="button"
    className={`time-slot ${isSelected ? 'selected' : ''}`}
    onClick={onClick}
  >
    {time}
  </button>
);

// BookingConfirmation Component
const BookingConfirmation = ({ bookingDetails, onClose }) => {
  const {
    therapistName,
    treatment,
    bookingDate,
    startTime,
    endTime,
    userName,
    userPhone,
    serviceType,
    outcallAddress,
    transportCharges,
  } = bookingDetails;

  return (
    <div className="booking-confirmation-container">
      <div className="confirmation-header">
        <FontAwesomeIcon icon={faCheckCircle} className="confirmation-icon" />
        <h2>Tempahan Berjaya!</h2>
      </div>
      <div className="confirmation-details">
        <div className="detail-group">
          <h3>Maklumat Terapi</h3>
          <p><strong>Terapi:</strong> {therapistName}</p>
          <p><strong>Rawatan:</strong> {treatment}</p>
          <p><strong>Tarikh:</strong> {formatDate(parseISO(bookingDate), 'dd/MM/yyyy')}</p>
          <p><strong>Waktu:</strong> {startTime} - {endTime}</p>
          <p><strong>Jenis Perkhidmatan:</strong> {serviceType === 'incall' ? 'In-call' : 'Out-call'}</p>
          {serviceType === 'outcall' && (
            <>
              <p><strong>Alamat Out-call:</strong> {outcallAddress}</p>
              <p><strong>Caj Pengangkutan:</strong></p>
              <ul className="transport-charges-list">
                {transportCharges.map((charge, index) => (
                  <li key={index}>
                    {charge.km !== null ? `${charge.km} km - RM${charge.price}` : `Flat Rate - RM${charge.price}`}
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
        <div className="detail-group">
          <h3>Maklumat Peribadi</h3>
          <p><strong>Nama:</strong> {userName}</p>
          <p><strong>Nombor Telefon:</strong> +{userPhone}</p>
        </div>
      </div>
      <button className="btn-close-confirmation" onClick={onClose}>
        Tutup
      </button>
    </div>
  );
};

const BookingForm = ({ selectedTherapist, onClose }) => {
  const [treatments, setTreatments] = useState([]);
  const [selectedTreatment, setSelectedTreatment] = useState('');
  const [availableSlots, setAvailableSlots] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedSlotId, setSelectedSlotId] = useState('');
  const [userName, setUserName] = useState('');
  const [userPhone, setUserPhone] = useState('60');
  const [selectedDate, setSelectedDate] = useState(null);
  const [availableTreatments, setAvailableTreatments] = useState([]);
  const [countryCode, setCountryCode] = useState('60');
  const [serviceType, setServiceType] = useState('');
  const [outcallAddress, setOutcallAddress] = useState('');
  const [loading, setLoading] = useState({ isLoading: false, message: '' });
  const [bookingDetails, setBookingDetails] = useState(null); // New state for booking confirmation

  // Auto-set service type based on therapist capabilities
  useEffect(() => {
    if (selectedTherapist) {
      // If therapist only offers incall
      if (selectedTherapist.inCall && !selectedTherapist.outCall) {
        setServiceType('incall');
      }
      // If therapist only offers outcall
      else if (!selectedTherapist.inCall && selectedTherapist.outCall) {
        setServiceType('outcall');
      }
      // If therapist offers both or neither, reset selection
      else {
        setServiceType('');
      }
    }
  }, [selectedTherapist]);

  useEffect(() => {
    const fetchAvailableTreatments = async () => {
      if (selectedTherapist) {
        try {
          setLoading({ isLoading: true, message: 'Memuatkan rawatan tersedia...' });
          const response = await axios.get(`${config.API_URL}/slots/${selectedTherapist._id}`);
          const slots = response.data.filter((slot) => !slot.isBooked);

          // **Perubahan Utama: Menapis rawatan hanya dari jenisRawatan.rawatanLain**
          const rawatanLain = selectedTherapist.jenisRawatan.rawatanLain || [];
          const availableTreatments = [...new Set(slots
            .map((slot) => slot.treatment)
            .filter((treatment) => rawatanLain.includes(treatment))
          )];
          setAvailableTreatments(availableTreatments);
        } catch (error) {
          console.error('Error fetching available treatments:', error);
          toast.error('Gagal mendapatkan rawatan yang tersedia.');
        } finally {
          setLoading({ isLoading: false, message: '' });
        }
      }
    };

    fetchAvailableTreatments();
  }, [selectedTherapist]);

  useEffect(() => {
    if (selectedTherapist) {
      setTreatments(selectedTherapist.jenisRawatan.rawatanLain || []);
    }
  }, [selectedTherapist]);

  useEffect(() => {
    if (selectedTherapist && selectedTreatment) {
      fetchAvailableDates(selectedTherapist._id, selectedTreatment);
    }
  }, [selectedTherapist, selectedTreatment]);

  useEffect(() => {
    if (selectedTherapist && selectedTreatment && selectedDate) {
      fetchAvailableSlots(selectedTherapist._id, selectedTreatment, selectedDate);
    }
  }, [selectedTherapist, selectedTreatment, selectedDate]);

  const fetchAvailableDates = async (therapistId, treatment) => {
    try {
      setLoading({ isLoading: true, message: 'Memuatkan tarikh tersedia...' });
      const response = await axios.get(
        `${config.API_URL}/slots/${therapistId}/${encodeURIComponent(treatment)}`
      );
      const slots = response.data.filter((slot) => !slot.isBooked);

      const bookingCutoffTime = selectedTherapist.bookingCutoffTime || 30;
      const now = toZonedTime(new Date());
      const cutoffTime = addMinutesWithZone(now, bookingCutoffTime);

      const dates = slots
        .map((slot) => {
          const slotDateTime = parseAndZone(`${slot.date}T${slot.startTime}`);
          return isAfterWithZone(slotDateTime, cutoffTime) ? parseISO(slot.date) : null;
        })
        .filter(Boolean);

      const uniqueDates = [...new Set(dates.map((date) => formatDate(date, 'yyyy-MM-dd')))];
      setAvailableDates(uniqueDates.map((dateString) => toZonedTime(parseISO(dateString))));

      if (uniqueDates.length === 0) {
        toast.warn('Maaf, tiada slot tersedia untuk rawatan ini.');
      }
    } catch (error) {
      console.error('Error fetching available dates:', error);
      toast.error('Gagal mendapatkan tarikh slot. Sila cuba lagi.');
    } finally {
      setLoading({ isLoading: false, message: '' });
    }
  };

  const fetchAvailableSlots = async (therapistId, treatment, date) => {
    try {
      setLoading({ isLoading: true, message: 'Memuatkan slot tersedia...' });
      const formattedDate = formatDate(date, 'yyyy-MM-dd');
      const response = await axios.get(
        `${config.API_URL}/slots/${therapistId}/${encodeURIComponent(treatment)}?date=${formattedDate}`
      );

      const bookingCutoffTime = selectedTherapist.bookingCutoffTime || 30;
      const now = toZonedTime(new Date());
      const cutoffTime = addMinutesWithZone(now, bookingCutoffTime);

      const slots = response.data.filter((slot) => {
        const slotDateTime = parseAndZone(`${slot.date}T${slot.startTime}`);
        return !slot.isBooked && isAfterWithZone(slotDateTime, cutoffTime);
      });

      setAvailableSlots(slots);

      if (slots.length === 0) {
        toast.warn('Maaf, tiada slot tersedia pada tarikh ini.');
      }
    } catch (error) {
      console.error('Error fetching available slots:', error);
      toast.error('Gagal mendapatkan slot. Sila cuba lagi.');
    } finally {
      setLoading({ isLoading: false, message: '' });
    }
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setShowCalendar(false);
    setSelectedSlotId('');
  };

  const handleCountryCodeChange = (e) => {
    const newCountryCode = e.target.value;
    setCountryCode(newCountryCode);
    setUserPhone(newCountryCode);
  };

  const handleBooking = async (e) => {
    e.preventDefault();

    if (!selectedSlotId) {
      toast.error('Sila pilih slot yang sah.');
      return;
    }

    // Validasi alamat outcall
    if (serviceType === 'outcall' && !outcallAddress.trim()) {
      toast.error('Sila masukkan alamat lengkap untuk outcall service.');
      return;
    }

    try {
      // Tunjukkan spinner
      setLoading({ isLoading: true, message: 'Sedang memproses tempahan anda...' });

      const selectedSlot = availableSlots.find((slot) => slot._id === selectedSlotId);

      if (!selectedSlot) {
        toast.error('Slot yang dipilih tidak sah.');
        return;
      }

      const bookingData = {
        therapistId: selectedTherapist._id,
        treatment: selectedTreatment,
        bookingDate: selectedSlot.date,
        slotId: selectedSlot._id,
        userName,
        userPhone,
        serviceType,
        outcallAddress: serviceType === 'outcall' ? outcallAddress : '',
        status: 'Progress',
      };

      // Hantar data booking ke server
      const bookingResponse = await axios.post(`${config.API_URL}/bookings`, bookingData);

      if (bookingResponse.status === 201) {
        // Tandakan slot sebagai ditempah
        await axios.put(`${config.API_URL}/slots/book/${selectedSlot._id}`);

        // Semak dan hantar mesej hanya jika flag sendBookingMessage adalah true
        if (selectedTherapist.messageTemplates.sendBookingMessage) {
          const sendMessageResponse = await axios.post(`${config.API_URL}/send-booking-message`, {
            therapistId: selectedTherapist._id,
            userName,
            userPhone,
            treatment: selectedTreatment,
            bookingDate: selectedSlot.date,
            startTime: selectedSlot.startTime,
            endTime: selectedSlot.endTime,
            serviceType,
            outcallAddress,
          });

          if (sendMessageResponse.status === 200) {
            toast.success(
              `Tempahan berjaya untuk ${selectedSlot.date} ${selectedSlot.startTime} - ${selectedSlot.endTime}`
            );
          } else {
            toast.warning('Tempahan berjaya, tetapi gagal menghantar mesej kepada pengguna.');
          }
        }

        // Simpan butiran tempahan untuk paparan pengesahan
        setBookingDetails({
          therapistName: selectedTherapist.name,
          treatment: selectedTreatment,
          bookingDate: selectedSlot.date,
          startTime: selectedSlot.startTime,
          endTime: selectedSlot.endTime,
          userName,
          userPhone,
          serviceType,
          outcallAddress,
          transportCharges: selectedTherapist.transportCharges || [],
        });

        // Jangan tutup modal secara automatik
        // onClose(); 
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (error) {
      console.error('Error creating booking:', error.response ? error.response.data : error);
      toast.error('Gagal membuat tempahan. Sila cuba lagi.');
    } finally {
      // Sembunyikan spinner
      setLoading({ isLoading: false, message: '' });
    }
  };

  const renderServiceTypeInfo = () => {
    if (!serviceType) return null;

    if (serviceType === 'incall' && selectedTherapist.inCall && selectedTherapist.alamatKedai) {
      return (
        <div className="service-info incall">
          <FontAwesomeIcon icon={faMapMarkerAlt} className="info-icon" />
          <div className="info-content">
            <h4>In-call Service</h4>
            <p>Sila hadir ke alamat pusat terapi kami:</p>
            <p className="address">{selectedTherapist.alamatKedai}</p>
            <ul className="instructions">
              <li>Sila hadir 5-10 minit lebih awal</li>
              <li>Pastikan anda memakai pakaian yang sesuai</li>
              <li>Sila maklumkan jika ada sebarang kelewatan</li>
            </ul>
          </div>
        </div>
      );
    }

    if (
      serviceType === 'outcall' &&
      selectedTherapist.outCall &&
      Array.isArray(selectedTherapist.transportCharges)
    ) {
      return (
        <div className="service-info outcall">
          <FontAwesomeIcon icon={faCar} className="info-icon" />
          <div className="info-content">
            <h4>Out-call Service</h4>
            <p>Caj pengangkutan berdasarkan jarak:</p>
            <ul className="transport-charges">
              {selectedTherapist.transportCharges.map((charge, index) => (
                <li key={index}>
                  {charge.km !== null ? `${charge.km} km - RM${charge.price}` : `Flat Rate - RM${charge.price}`}
                </li>
              ))}
            </ul>
            <div className="form-group mt-3">
              <label className="required-field">Alamat Lengkap:</label>
              <textarea
                value={outcallAddress}
                onChange={(e) => setOutcallAddress(e.target.value)}
                placeholder="Sila masukkan alamat lengkap termasuk poskod dan negeri"
                className="form-input"
                rows="4"
                required
              />
              <small className="form-info-text">
                * Pastikan alamat yang dimasukkan adalah tepat untuk memudahkan terapis sampai ke lokasi anda
              </small>
            </div>
            <p className="note">* Harga pengangkutan akan ditambah kepada harga rawatan</p>
          </div>
        </div>
      );
    }

    return null;
  };

  if (bookingDetails) {
    return (
      <BookingConfirmation
        bookingDetails={bookingDetails}
        onClose={onClose}
      />
    );
  }

  return (
    <div className="booking-form-container">
      {loading.isLoading ? (
        <div className="loading-container">
          <div className="spinner"></div>
          <p>{loading.message}</p>
        </div>
      ) : (
        <>
          <div>
            <button onClick={onClose} className="btn-close">
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <h2 className="form-title">
              Buat Tempahan {selectedTherapist ? `dengan ${selectedTherapist.name}` : ''}
            </h2>
          </div>

          <form className="booking-form" onSubmit={handleBooking}>
            <div className="form-section personal-info">
              <h3>Maklumat Peribadi</h3>
              <div className="form-group">
                <label>Nama:</label>
                <input
                  type="text"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  required
                  placeholder="Masukkan nama penuh anda"
                  className="form-input"
                />
              </div>

              <div className="form-group">
                <label>Country Code:</label>
                <select
                  value={countryCode}
                  onChange={handleCountryCodeChange}
                  className="form-select"
                >
                  <option value="60">Malaysia (+60)</option>
                  <option value="65">Singapore (+65)</option>
                  <option value="66">Thailand (+66)</option>
                </select>
              </div>

              <div className="form-group">
                <label>Nombor Telefon:</label>
                <input
                  type="tel"
                  value={userPhone}
                  onChange={(e) => setUserPhone(e.target.value)}
                  placeholder="Contoh: 60123456789"
                  required
                  className="form-input"
                />
              </div>
            </div>

            <div className="form-section booking-details">
              <h3>Maklumat Tempahan</h3>

              <div className="form-group">
                <label>Jenis Perkhidmatan:</label>
                <select
                  value={serviceType}
                  onChange={(e) => setServiceType(e.target.value)}
                  required
                  className="form-select"
                  disabled={
                    selectedTherapist &&
                    ((selectedTherapist.inCall && !selectedTherapist.outCall) ||
                      (!selectedTherapist.inCall && selectedTherapist.outCall))
                  }
                >
                  <option value="">Pilih jenis perkhidmatan</option>
                  {selectedTherapist?.inCall && (
                    <option value="incall">In-call (Di pusat terapi)</option>
                  )}
                  {selectedTherapist?.outCall && (
                    <option value="outcall">Out-call (Di lokasi anda)</option>
                  )}
                </select>
              </div>

              {renderServiceTypeInfo()}

              <div className="form-group">
                <label>Rawatan:</label>
                <select
                  value={selectedTreatment}
                  onChange={(e) => {
                    setSelectedTreatment(e.target.value);
                    setSelectedDate(null);
                    setSelectedSlotId('');
                    setAvailableSlots([]);
                  }}
                  required
                  className="form-select"
                >
                  <option value="">Pilih Rawatan</option>
                  {availableTreatments.map((treatment, index) => (
                    <option key={index} value={treatment}>
                      {treatment}
                    </option>
                  ))}
                </select>
              </div>

              {selectedTreatment && (
                <div className="form-group">
                  <label>Tarikh:</label>
                  {availableDates.length > 0 ? (
                    <button
                      type="button"
                      onClick={() => setShowCalendar(true)}
                      className="calendar-button"
                    >
                      {selectedDate ? formatDate(selectedDate, 'dd/MM/yyyy') : 'Pilih Tarikh'}
                    </button>
                  ) : (
                    <p className="no-slots">Tiada slot tersedia untuk rawatan ini.</p>
                  )}
                </div>
              )}

              {availableSlots.length > 0 && (
                <div className="form-group">
                  <label>Slot Tersedia:</label>
                  <div className="time-slots-container">
                    {availableSlots.map((slot) => (
                      <TimeSlot
                        key={slot._id}
                        time={`${slot.startTime} - ${slot.endTime}`}
                        isSelected={selectedSlotId === slot._id}
                        onClick={() => setSelectedSlotId(slot._id)}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>

            <button type="submit" className="btn-submit">
              Tempah Sekarang
            </button>
          </form>

          {showCalendar && (
            <div className="calendar-modal" onClick={() => setShowCalendar(false)}>
              <div className="calendar-container" onClick={(e) => e.stopPropagation()}>
                <ReactDatePicker
                  selected={selectedDate}
                  onChange={handleDateSelect}
                  inline
                  highlightDates={availableDates}
                  dayClassName={(date) =>
                    availableDates.some((d) => isSameDayWithZone(d, date))
                      ? 'highlighted-date'
                      : undefined
                  }
                  minDate={toZonedTime(new Date())}
                  filterDate={(date) =>
                    availableDates.some((d) => isSameDayWithZone(d, date))
                  }
                  monthsShown={1}
                  showDisabledMonthNavigation
                  renderCustomHeader={({
                    date,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div className="custom-header">
                      <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                        {'<'}
                      </button>
                      <span className="react-datepicker__current-month">
                        {formatDate(date, 'MMMM yyyy')}
                      </span>
                      <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                        {'>'}
                      </button>
                    </div>
                  )}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BookingForm;