// WebhookTab.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './WebhookTab.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { 
  FaChevronDown, FaChevronUp, FaEdit, FaTrash, FaCopy, 
  FaSave, FaTimes, FaCheckCircle, FaTimesCircle, FaInfoCircle 
} from 'react-icons/fa';

const WebhookTab = () => {
  const [loading, setLoading] = useState(false);
  const [webhooks, setWebhooks] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [tempName, setTempName] = useState('');
  const [expandedWebhooks, setExpandedWebhooks] = useState([]);
  const [tempWebhookData, setTempWebhookData] = useState({});
  const [hasShownNoWebhooksToast, setHasShownNoWebhooksToast] = useState(false); // State baru

  // State untuk condition builder per webhook
  const [conditionBuilder, setConditionBuilder] = useState({});

  // Senarai operator logik
  const comparisonOperators = [
    { value: '==', label: 'Is Equal To (==)' },
    { value: '!=', label: 'Is Not Equal To (!=)' },
    { value: '>', label: 'Greater Than (>)' },
    { value: '<', label: 'Less Than (<)' },
    { value: '>=', label: 'Greater Than or Equal To (>=)' },
    { value: '<=', label: 'Less Than or Equal To (<=)' },
  ];

  const logicalOperators = [
    { value: '&&', label: 'AND (&&)' },
    { value: '||', label: 'OR (||)' },
  ];

  useEffect(() => {
    fetchWebhooks(); // Fetch awal

    const interval = setInterval(() => {
      fetchWebhooks(false); // Jangan tunjuk loading semasa polling
    }, 5000); // Ambil data setiap 5 saat

    return () => clearInterval(interval); // Bersihkan interval semasa unmount
  }, []);

  const fetchWebhooks = async (showLoading = true) => {
    if (showLoading) setLoading(true);
    try {
      const response = await axios.get('/webhook/user-webhooks', {
        headers: { 'x-auth-token': localStorage.getItem('token') },
      });

      // Gabungkan data dari backend dengan tempWebhookData
      const newWebhooks = response.data.map(webhook => {
        const tempData = tempWebhookData[webhook.webhookId];
        if (tempData) {
          return {
            ...webhook,
            messageTemplate: tempData.messageTemplate !== undefined ? tempData.messageTemplate : webhook.messageTemplate,
            phoneNumberField: tempData.phoneNumberField !== undefined ? tempData.phoneNumberField : webhook.phoneNumberField,
            condition: tempData.condition !== undefined ? tempData.condition : webhook.condition,
          };
        }
        return webhook;
      });

      setWebhooks(newWebhooks);

      if (response.data.length === 0) {
        if (!hasShownNoWebhooksToast) {
          toast.info('Sila tambah webhook di sini.');
          setHasShownNoWebhooksToast(true);
        }
      } else {
        // Terdapat webhook, reset state supaya toast boleh dipaparkan semula jika tiada webhook pada masa akan datang
        if (hasShownNoWebhooksToast) {
          setHasShownNoWebhooksToast(false);
        }
      }
    } catch (error) {
      console.error('Error fetching webhooks:', error);
      toast.error('Gagal memuatkan webhook.');
    } finally {
      if (showLoading) setLoading(false);
    }
  };

  const generateWebhook = async () => {
    setLoading(true);
    try {
      const response = await axios.post('/webhook/generate-url', {}, {
        headers: { 'x-auth-token': localStorage.getItem('token') },
      });
      const newWebhook = {
        webhookUrl: response.data.webhookUrl,
        webhookId: response.data.webhookId,
        name: `Webhook ${webhooks.length + 1}`,
        receivedData: [],
        isActive: false,
        sendMessage: false,
        messageTemplate: '',
        phoneNumberField: '',
        condition: '',
        hasReceivedPayload: false,
      };
      setWebhooks([...webhooks, newWebhook]);
      toast.success('Webhook URL berjaya dijana! Sila lakukan pengujian payload.');
    } catch (error) {
      console.error('Error generating webhook:', error);
      toast.error('Gagal untuk menjana webhook URL.');
    } finally {
      setLoading(false);
    }
  };

  const toggleExpand = (webhookId) => {
    setExpandedWebhooks(prev => 
      prev.includes(webhookId) ? prev.filter(id => id !== webhookId) : [...prev, webhookId]
    );
  };

  const startEditing = (index) => {
    setEditingIndex(index);
    setTempName(webhooks[index].name);
  };

  const cancelEditing = () => {
    setEditingIndex(null);
    setTempName('');
  };

  const saveWebhookName = async (index) => {
    const webhook = webhooks[index];
    const newName = tempName.trim();

    if (!newName) {
      toast.error('Nama webhook tidak boleh kosong.');
      return;
    }

    setLoading(true);
    try {
      const response = await axios.put(`/webhook/${webhook.webhookId}/name`, { name: newName }, {
        headers: { 'x-auth-token': localStorage.getItem('token') },
      });

      const updatedWebhooks = [...webhooks];
      updatedWebhooks[index].name = response.data.webhook.name;
      setWebhooks(updatedWebhooks);

      toast.success('Nama webhook berjaya dikemaskini!');
      setEditingIndex(null);
      setTempName('');
    } catch (error) {
      console.error('Error updating webhook name:', error);
      toast.error('Gagal mengemaskini nama webhook.');
    } finally {
      setLoading(false);
    }
  };

  const handleCopyWebhook = (url) => {
    navigator.clipboard.writeText(url);
    toast.success('Webhook URL berjaya disalin!');
  };

  const deleteWebhook = async (index) => {
    const webhook = webhooks[index];
    const webhookId = webhook.webhookId;

    try {
      await axios.delete(`/webhook/${webhookId}`, {
        headers: { 'x-auth-token': localStorage.getItem('token') },
      });

      const updatedWebhooks = webhooks.filter((_, i) => i !== index);
      setWebhooks(updatedWebhooks);

      toast.success('Webhook berjaya dipadam!');
    } catch (error) {
      console.error('Error deleting webhook:', error);
      toast.error('Gagal memadam webhook.');
    }
  };

  const toggleWebhookStatus = async (index) => {
    const webhook = webhooks[index];
    const webhookId = webhook.webhookId;

    if (!webhook.hasReceivedPayload) {
      toast.error('Sila hantar payload ujian terlebih dahulu.');
      return;
    }

    try {
      const response = await axios.put(`/webhook/${webhookId}/toggle`, {}, {
        headers: { 'x-auth-token': localStorage.getItem('token') },
      });

      const updatedWebhooks = [...webhooks];
      updatedWebhooks[index].isActive = response.data.isActive;
      setWebhooks(updatedWebhooks);

      toast.success(`Webhook telah ${response.data.isActive ? 'diaktifkan' : 'dinonaktifkan'}!`);
    } catch (error) {
      console.error('Error toggling webhook status:', error);
      toast.error('Gagal mengubah status webhook.');
    }
  };

  const toggleSendMessage = async (index) => {
    const webhook = webhooks[index];
    const webhookId = webhook.webhookId;

    if (!webhook.hasReceivedPayload) {
      toast.error('Sila hantar payload ujian terlebih dahulu.');
      return;
    }

    try {
      const token = localStorage.getItem('token');

      const response = await axios.put(
        `/webhook/${webhookId}/send-message-toggle`,
        {},
        {
          headers: { 'x-auth-token': token },
        }
      );

      const updatedWebhooks = [...webhooks];
      updatedWebhooks[index].sendMessage = response.data.sendMessage;
      setWebhooks(updatedWebhooks);

      toast.success(`Hantar mesej ${response.data.sendMessage ? 'diaktifkan' : 'dinonaktifkan'}!`);
    } catch (error) {
      console.error('Error toggling send message:', error.response?.data || error.message);
      toast.error(error.response?.data?.error || 'Gagal mengubah hantar mesej.');
    }
  };

  const handleTemplateChange = (webhookId, value) => {
    setTempWebhookData(prev => ({
      ...prev,
      [webhookId]: {
        ...prev[webhookId],
        messageTemplate: value,
      },
    }));
  };

  const handlePhoneFieldChange = (webhookId, value) => {
    setTempWebhookData(prev => ({
      ...prev,
      [webhookId]: {
        ...prev[webhookId],
        phoneNumberField: value,
      },
    }));
  };

  const handleConditionChange = (webhookId, condition) => {
    setTempWebhookData(prev => ({
      ...prev,
      [webhookId]: {
        ...prev[webhookId],
        condition,
      },
    }));
  };




  // Fungsi untuk mengemas kini condition builder per webhook
  const handleConditionBuilderChange = (webhookId, field, value) => {
    setConditionBuilder(prev => ({
      ...prev,
      [webhookId]: {
        ...prev[webhookId],
        [field]: value,
      },
    }));
  };

const addCondition = (webhookId) => {
  const cb = conditionBuilder[webhookId] || {};
  const { selectedField1, selectedOperator, selectedField2, manualValue, isManualValue, logicalOperator } = cb;

  // Dapatkan kondisi sedia ada (jika ada)
  const existingCondition = tempWebhookData[webhookId]?.condition || '';
  const conditionIsEmpty = !existingCondition.trim(); // true jika tiada kondisi sebelumnya

  // Validasi input
  if (!selectedField1 || !selectedOperator) {
    toast.error('Sila pilih medan pertama dan operator perbandingan.');
    return;
  }

  // Jika guna nilai manual tetapi tiada nilai manual dimasukkan
  if (isManualValue && (manualValue === undefined || manualValue === '')) {
    toast.error('Sila masukkan nilai manual.');
    return;
  }

  // Jika tak guna nilai manual, mesti pilih medan kedua
  if (!isManualValue && !selectedField2) {
    toast.error('Sila pilih medan kedua jika tidak menggunakan nilai manual.');
    return;
  }

  // Jika ada kondisi sedia ada, barulah logicalOperator diperlukan
  if (!conditionIsEmpty && !logicalOperator) {
    toast.error('Sila pilih operator logik (AND/OR) untuk menggabungkan kondisi.');
    return;
  }

  // Sediakan value
  let value;
  if (isManualValue) {
    // Jika manualValue adalah string, bungkus dengan quotes
    // Jika anda yakin semua manualValue string, bungkus sahaja terus
    // Jika mahu logic lebih pintar, boleh check isNaN() untuk tentukan numeric atau tidak
    value = `'${manualValue}'`;
  } else {
    // Jika guna medan lain, terus guna nama medannya
    // Contoh: status, phoneNumber dsb.
    // Dalam VM, medan payload menjadi variable terus, jadi kita cuma guna `selectedField2` tanpa {}
    value = selectedField2;
  }

  const newCondition = `${selectedField1} ${selectedOperator} ${value}`;

  // Gabungkan condition
  const combinedCondition = conditionIsEmpty
    ? newCondition
    : `${existingCondition} ${logicalOperator} ${newCondition}`;

  handleConditionChange(webhookId, combinedCondition);

  // Reset condition builder selepas tambah condition
  setConditionBuilder(prev => ({
    ...prev,
    [webhookId]: {
      selectedField1: '',
      selectedOperator: '',
      selectedField2: '',
      manualValue: '',
      isManualValue: false,
      // Jika anda mahu default logicalOperator hanya muncul bila dah ada condition,
      // boleh biarkan nilai default. Untuk mudah, set && tapi nanti user pilih bila add condition seterusnya
      logicalOperator: '&&',
    }
  }));
};


  const saveSettings = async (webhookId) => {
    const webhook = webhooks.find(w => w.webhookId === webhookId);
    const tempData = tempWebhookData[webhookId] || {};
    const template = tempData.messageTemplate ? tempData.messageTemplate.trim() : webhook.messageTemplate;
    const field = tempData.phoneNumberField ? tempData.phoneNumberField.trim() : webhook.phoneNumberField;
    const condition = tempData.condition !== undefined ? tempData.condition : webhook.condition;

    if (!template || !field) {
      toast.error('Template mesej dan medan nombor telefon tidak boleh kosong.');
      return;
    }

    setLoading(true);
    try {
      const response = await axios.put(`/webhook/${webhookId}/settings`, {
        messageTemplate: template,
        phoneNumberField: field,
        condition, // Sertakan kondisi di sini
      }, {
        headers: { 'x-auth-token': localStorage.getItem('token') },
      });

      const updatedWebhooks = webhooks.map(w => {
        if (w.webhookId === webhookId) {
          return {
            ...w,
            messageTemplate: response.data.webhook.messageTemplate,
            phoneNumberField: response.data.webhook.phoneNumberField,
            condition: response.data.webhook.condition, // Pastikan kondisi dikemaskini
          };
        }
        return w;
      });
      setWebhooks(updatedWebhooks);

      // Kosongkan data temp selepas simpan
      setTempWebhookData(prev => {
        const newData = { ...prev };
        delete newData[webhookId];
        return newData;
      });

      toast.success('Tetapan berjaya dikemaskini!');
    } catch (error) {
      console.error('Ralat mengemaskini tetapan:', error);
      toast.error('Gagal mengemaskini tetapan.');
    } finally {
      setLoading(false);
    }
  };

  const generateFieldsList = (payload) => {
    const flatPayload = flattenObject(payload);
    return Object.keys(flatPayload).map((field) => (
      <tr key={field}>
        <td>{field}</td>
        <td>
          <button
            onClick={() => {
              navigator.clipboard.writeText(`{${field}}`);
              toast.success(`Medan {${field}} berjaya disalin!`);
            }}
            className="copy-button"
          >
            <FaCopy /> Salin
          </button>
        </td>
      </tr>
    ));
  };

  const flattenObject = (obj, prefix = '') => {
    return Object.keys(obj).reduce((acc, key) => {
      const pre = prefix.length ? `${prefix}.` : '';
      if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
        Object.assign(acc, flattenObject(obj[key], `${pre}${key}`));
      } else if (Array.isArray(obj[key])) {
        obj[key].forEach((item, index) => {
          Object.assign(acc, flattenObject(item, `${pre}${key}[${index}]`));
        });
      } else {
        acc[`${pre}${key}`] = obj[key];
      }
      return acc;
    }, {});
  };

  const toggleDataVisibility = (uniqueId) => {
    setExpandedWebhooks(prev => 
      prev.includes(uniqueId) ? prev.filter(id => id !== uniqueId) : [...prev, uniqueId]
    );
  };

  // Mengambil semua medan unik dari semua payload
  const getAllUniqueFields = (receivedData) => {
    const fieldsSet = new Set();
    receivedData.forEach(data => {
      const flatPayload = flattenObject(data.payload);
      Object.keys(flatPayload).forEach(field => fieldsSet.add(field));
    });
    return Array.from(fieldsSet);
  };

  return (
    <div className="webhook-container">
      <h2>Pengurusan Webhook</h2>

      {/* Seksyen Arahan */}
      <div className="instructions">
        <h3><FaInfoCircle /> Apa itu Webhook?</h3>
        <p>Webhook membolehkan sistem anda menerima data secara automatik dari aplikasi lain apabila peristiwa tertentu berlaku. Dengan webhook, anda boleh mengautomasi penghantaran mesej WhatsApp kepada pelanggan anda apabila sistem anda menerima data baru.</p>
        <h3>Bagaimana Menggunakan Webhook Ini?</h3>
        <ol>
          <li>Klik butang <strong>"Jana URL Webhook"</strong> untuk mendapatkan URL unik.</li>
          <li>Hantar <strong>payload ujian</strong> dari sistem anda ke URL tersebut untuk mendapatkan medan dan nilai.</li>
          <li>Selepas menerima payload, anda boleh melihat medan yang tersedia dan menggunakan medan tersebut dalam template mesej.</li>
          <li>Tetapkan <strong>template mesej</strong> dan pilih <strong>medan nombor telefon</strong> yang akan digunakan.</li>
          <li>Aktifkan <strong>"Hantar Mesej"</strong> dan pastikan webhook dihidupkan untuk mengautomasi penghantaran mesej.</li>
        </ol>
      </div>

      <button onClick={generateWebhook} disabled={loading} className="generate-button">
        {loading ? 'Menjana...' : 'Jana URL Webhook'}
      </button>
      
      {webhooks.length === 0 && !loading && (
        <p className="no-webhooks-message">Tiada webhook tersedia. Klik butang di atas untuk menjana webhook baru.</p>
      )}

      {webhooks.map((webhook, index) => {
        const tempData = tempWebhookData[webhook.webhookId] || {};
        const messageTemplate = tempData.messageTemplate !== undefined ? tempData.messageTemplate : webhook.messageTemplate;
        const phoneNumberField = tempData.phoneNumberField !== undefined ? tempData.phoneNumberField : webhook.phoneNumberField;
        const condition = tempData.condition !== undefined ? tempData.condition : webhook.condition;
        const conditionDisplay = condition || '';

        // Ambil semua medan unik dari semua payload
        const availableFields = getAllUniqueFields(webhook.receivedData);
        console.log('Fields yang ada:', availableFields);


        return (
          <div key={webhook.webhookId} className="webhook-item">
            <div className="webhook-header">
              {editingIndex === index ? (
                <>
                  <input
                    type="text"
                    value={tempName}
                    onChange={(e) => setTempName(e.target.value)}
                    className="webhook-name-input"
                    placeholder="Masukkan nama webhook"
                  />
                  <div className="edit-buttons">
                    <button onClick={() => saveWebhookName(index)} disabled={loading} className="save-button">
                      <FaSave /> Simpan
                    </button>
                    <button onClick={cancelEditing} disabled={loading} className="cancel-button">
                      <FaTimes /> Batal
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="header-left">
                    <span className="webhook-name">{webhook.name}</span>
                    <span className={`webhook-status ${webhook.isActive ? 'active' : 'inactive'}`}>
                      {webhook.isActive ? 'Aktif' : 'Tidak Aktif'}
                    </span>
                  </div>
                  <div className="header-right">
                    <button onClick={() => startEditing(index)} className="edit-button">
                      <FaEdit /> Edit
                    </button>
                    <button
                      onClick={() => toggleWebhookStatus(index)}
                      className="toggle-button"
                      disabled={!webhook.hasReceivedPayload}
                      title={!webhook.hasReceivedPayload ? 'Sila hantar payload ujian terlebih dahulu.' : ''}
                    >
                      {webhook.isActive ? 'Matikan' : 'Hidupkan'}
                    </button>
                  </div>
                </>
              )}
            </div>
            <div className="table-wrapper">
              <table className="webhook-table">
                <thead>
                  <tr>
                    <th>URL Webhook</th>
                    <th>Tindakan</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="url-cell">
                      <a href={webhook.webhookUrl} target="_blank" rel="noopener noreferrer">
                        {webhook.webhookUrl}
                      </a>
                    </td>
                    <td className="actions-cell">
                      <button onClick={() => handleCopyWebhook(webhook.webhookUrl)} className="copy-button">
                        <FaCopy /> Salin
                      </button>
                      <button onClick={() => toggleExpand(webhook.webhookId)} className="fetch-button">
                        {expandedWebhooks.includes(webhook.webhookId) ? <FaChevronUp /> : <FaChevronDown />} 
                        {expandedWebhooks.includes(webhook.webhookId) ? ' Sembunyikan Data' : ' Tunjukkan Data'}
                      </button>
                      <button onClick={() => deleteWebhook(index)} className="delete-button">
                        <FaTrash /> Padam
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Fasa Pengujian */}
            {!webhook.hasReceivedPayload ? (
              <div className="testing-phase-message">
                <p><FaInfoCircle /> Sila hantar payload ujian dari sistem anda ke URL webhook ini untuk mendapatkan medan dan nilai.</p>
              </div>
            ) : (
              <>
                {/* Toggle Send Message */}
                <button
                  onClick={() => toggleSendMessage(index)}
                  className="toggle-data-button"
                >
                  {webhook.sendMessage ? 'Matikan Hantar Mesej' : 'Aktifkan Hantar Mesej'}
                </button>

                {/* Jika Send Message diaktifkan, paparkan konfigurasi */}
                {webhook.sendMessage && (
                  <div className="send-message-section">
                    <h3>Konfigurasi Hantar Mesej</h3>
                    
                    {/* Pengurusan Multiple Conditions */}
                    <div className="condition-builder-section">
                      <h4>Membina Kondisi Logik</h4>
                      <div className="condition-builder">
                        {/* Dropdown Operator Logik antara Kondisi (hanya untuk kondisi kedua dan seterusnya) */}
                        {webhook.condition && (
                          <select
                            value={conditionBuilder[webhook.webhookId]?.logicalOperator || '&&'}
                            onChange={(e) => handleConditionBuilderChange(webhook.webhookId, 'logicalOperator', e.target.value)}
                            className="condition-select"
                          >
                            <option value="&&">AND (&&)</option>
                            <option value="||">OR (||)</option>
                          </select>
                        )}

                        {/* Dropdown Medan Pertama */}
                        <select
                          value={conditionBuilder[webhook.webhookId]?.selectedField1 || ''}
                          onChange={(e) => handleConditionBuilderChange(webhook.webhookId, 'selectedField1', e.target.value)}
                          className="condition-select"
                        >
                          <option value="">-- Pilih Medan --</option>
                          {availableFields.map((field) => (
                            <option key={field} value={field}>{field}</option>
                          ))}
                        </select>

                        {/* Dropdown Operator */}
                        <select
                          value={conditionBuilder[webhook.webhookId]?.selectedOperator || ''}
                          onChange={(e) => handleConditionBuilderChange(webhook.webhookId, 'selectedOperator', e.target.value)}
                          className="condition-select"
                        >
                          <option value="">-- Pilih Operator --</option>
                          {comparisonOperators.map(op => (
                            <option key={op.value} value={op.value}>{op.label}</option>
                          ))}
                        </select>

                        {/* Pilih Medan Kedua atau Nilai Manual */}
                        <div className="condition-value-section">
                          <label>
                            <input
                              type="radio"
                              name={`valueType-${webhook.webhookId}`}
                              checked={!conditionBuilder[webhook.webhookId]?.isManualValue}
                              onChange={() => handleConditionBuilderChange(webhook.webhookId, 'isManualValue', false)}
                            />
                            Medan
                          </label>
                          <label>
                            <input
                              type="radio"
                              name={`valueType-${webhook.webhookId}`}
                              checked={conditionBuilder[webhook.webhookId]?.isManualValue}
                              onChange={() => handleConditionBuilderChange(webhook.webhookId, 'isManualValue', true)}
                            />
                            Nilai Manual
                          </label>
                        </div>

                        {/* Dropdown Medan Kedua */}
                        {!conditionBuilder[webhook.webhookId]?.isManualValue ? (
                          <select
                            value={conditionBuilder[webhook.webhookId]?.selectedField2 || ''}
                            onChange={(e) => handleConditionBuilderChange(webhook.webhookId, 'selectedField2', e.target.value)}
                            className="condition-select"
                          >
                            <option value="">-- Pilih Medan --</option>
                            {availableFields.map((field) => (
                              <option key={field} value={field}>{field}</option>
                            ))}
                          </select>
                        ) : (
                          <input
                            type="text"
                            value={conditionBuilder[webhook.webhookId]?.manualValue || ''}
                            onChange={(e) => handleConditionBuilderChange(webhook.webhookId, 'manualValue', e.target.value)}
                            placeholder="Masukkan nilai manual"
                            className="condition-input"
                          />
                        )}

                        {/* Butang untuk Menambah Kondisi */}
                        <button
                          onClick={() => addCondition(webhook.webhookId)}
                          className="add-condition-button"
                        >
                          Tambah Kondisi
                        </button>
                      </div>

                      {/* Paparkan Kondisi yang Dibina */}
                      {conditionDisplay && (
                        <div className="current-condition">
                          <strong>Kondisi Terkini:</strong> {conditionDisplay}
                        </div>
                      )}
                    </div>

                    {/* Template Mesej */}
                    <label>Template Mesej:</label>
                    <textarea
                      value={messageTemplate}
                      onChange={(e) => handleTemplateChange(webhook.webhookId, e.target.value)}
                      placeholder="Contoh: Hi {Nama}, terima kasih kerana..."
                      className="template-textarea"
                    ></textarea>

                    {/* Pilih Medan Nombor Telefon */}
                    <div className="phone-field-section">
                      <label htmlFor={`phone-field-${index}`}>Pilih Medan Nombor Telefon:</label>
                      <select
                        id={`phone-field-${index}`}
                        value={phoneNumberField}
                        onChange={(e) => handlePhoneFieldChange(webhook.webhookId, e.target.value)}
                        className="phone-field-select"
                      >
                        <option value="">-- Pilih Medan --</option>
                        {availableFields.map((field) => (
                          <option key={field} value={field}>{field}</option>
                        ))}
                      </select>
                    </div>

                    <button
                      onClick={() => saveSettings(webhook.webhookId)}
                      className="save-template-button"
                      disabled={loading}
                    >
                      <FaSave /> Simpan Tetapan
                    </button>
                  </div>
                )}
              </>
            )}

            {/* Paparkan Data Diterima dan Medan */}
            {webhook.hasReceivedPayload && webhook.receivedData.length > 0 && expandedWebhooks.includes(webhook.webhookId) && (
              <>
                <div className="received-data-container">
                  <h3>Medan yang Tersedia untuk Mesej</h3>
                  <p>Klik butang "Salin" untuk menggunakan medan dalam template mesej anda.</p>
                  <div className="data-table-wrapper">
                    <table className="webhook-data-table">
                      <thead>
                        <tr>
                          <th>Nama Medan</th>
                          <th>Tindakan</th>
                        </tr>
                      </thead>
                      <tbody>
                        {generateFieldsList(getAllUniqueFields(webhook.receivedData).reduce((acc, field) => {
                          acc[field] = true;
                          return acc;
                        }, {}))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="received-data-container">
                  <h3>Data Diterima:</h3>
                  {webhook.receivedData.map((data, dataIndex) => {
                    const flatPayload = flattenObject(data.payload); // Definisi di sini
                    const uniqueId = `${webhook.webhookId}-${dataIndex}`;

                    return (
                      <div key={dataIndex} className="webhook-data-entry">
                        <h4>Diterima Pada: {new Date(data.receivedAt).toLocaleString()}</h4>
                        <div className="message-status">
                          {data.messageSent ? (
                            <span className="message-sent"><FaCheckCircle /> Mesej Berjaya Dihantar</span>
                          ) : (
                            <span className="message-failed"><FaTimesCircle /> Mesej Tidak Dihantar</span>
                          )}
                        </div>
                        <div className="data-display">
                          <div className="data-summary">
                            {Object.entries(flatPayload).slice(0, 5).map(([key, value]) => (
                              <div key={key}>
                                <strong>{key}:</strong> {String(value)}
                              </div>
                            ))}
                          </div>
                          {Object.entries(flatPayload).length > 5 && (
                            <button
                              onClick={() => toggleDataVisibility(uniqueId)}
                              className="toggle-data-visibility-button"
                            >
                              {expandedWebhooks.includes(uniqueId) ? 'Sembunyikan' : 'Lihat Lebih Banyak'}
                            </button>
                          )}
                          {expandedWebhooks.includes(uniqueId) && (
                            <div className="full-data">
                              {Object.entries(flatPayload).map(([key, value]) => (
                                <div key={key}>
                                  <strong>{key}:</strong> {String(value)}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default WebhookTab;
