import { formatInTimeZone, toDate } from 'date-fns-tz';
import { parseISO, isAfter, addMinutes, isSameDay } from 'date-fns';

const timeZone = 'Asia/Kuala_Lumpur';

export const toZonedTime = (date) => toDate(date, { timeZone });
export const formatDate = (date, formatStr) => formatInTimeZone(date, timeZone, formatStr);
export const parseAndZone = (dateStr) => toZonedTime(parseISO(dateStr));
export const isAfterWithZone = (date1, date2) => isAfter(toZonedTime(date1), toZonedTime(date2));
export const addMinutesWithZone = (date, minutes) => toZonedTime(addMinutes(toZonedTime(date), minutes));
export const isSameDayWithZone = (date1, date2) => isSameDay(toZonedTime(date1), toZonedTime(date2));
