// EditSlotForm.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './EditSlotForm.css';
import { toast } from 'react-toastify';
import { startOfDay, addDays, format } from 'date-fns';
import { toZonedTime, formatDate } from './dateUtils'; // Import dari dateUtils.js
import config from '../config';

function EditSlotForm({ therapist, treatment, onClose, onSave }) {
  // Tentukan bulan yang dibenarkan berdasarkan langganan
  const getAllowedMonths = () => {
    if (therapist.subscriptionPlan === 'plus') {
      return [1, 2, 3];
    }
    return [1]; // Untuk 'free_trial' dan 'basic'
  };

  const allowedMonths = getAllowedMonths();

  const [monthsAhead, setMonthsAhead] = useState(allowedMonths.includes(1) ? 1 : allowedMonths[0]);
  
  // Jika langganan berubah, pastikan monthsAhead masih valid
  useEffect(() => {
    if (!allowedMonths.includes(monthsAhead)) {
      setMonthsAhead(allowedMonths[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [therapist.subscriptionPlan]);

  const [availableDays, setAvailableDays] = useState({
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
  });
  const [startTime, setStartTime] = useState('09:30'); // Pastikan konsisten dengan therapistState
  const [endTime, setEndTime] = useState('19:00'); // Sesuaikan dengan therapistState
  const [duration, setDuration] = useState(30); // Pastikan ini adalah Number
  const [breakTime, setBreakTime] = useState(0);
  
  // Initialize lunchBreakTimes with default values
  const [lunchBreakTimes, setLunchBreakTimes] = useState({
    monday: { start: '13:00', end: '14:00' },
    tuesday: { start: '13:00', end: '14:00' },
    wednesday: { start: '13:00', end: '14:00' },
    thursday: { start: '13:00', end: '14:00' },
    friday: { start: '13:00', end: '14:00' },
    saturday: { start: '13:00', end: '14:00' },
    sunday: { start: '13:00', end: '14:00' },
  });

  const [bookingCutoffTime, setBookingCutoffTime] = useState(therapist.bookingCutoffTime || 30);

  const handleToggleChange = (day) => {
    setAvailableDays((prevState) => ({
      ...prevState,
      [day]: !prevState[day],
    }));
  };

  const handleLunchBreakChange = (day, field, value) => {
    setLunchBreakTimes((prevState) => ({
      ...prevState,
      [day]: {
        ...prevState[day],
        [field]: value,
      },
    }));
  };

  const validateLunchBreakTimes = () => {
    for (const day in lunchBreakTimes) {
      const { start, end } = lunchBreakTimes[day];
      if (start >= end) {
        toast.error(`Masa mula rehat makan tengah hari pada ${day} tidak boleh selepas atau sama dengan masa tamat.`);
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validasi monthsAhead berdasarkan langganan
    if (!allowedMonths.includes(parseInt(monthsAhead, 10))) {
      toast.error('Bilangan bulan yang dipilih tidak dibenarkan berdasarkan langganan anda.');
      return;
    }

    // Validate lunch break times
    if (!validateLunchBreakTimes()) {
      return;
    }

    const start = new Date();
    start.setHours(12, 0, 0, 0);
    const end = new Date();
    end.setMonth(end.getMonth() + parseInt(monthsAhead, 10));
    end.setHours(12, 0, 0, 0);

    const slots = generateSlots({
      startDate: start,
      endDate: end,
      availableDays,
      startTime,
      endTime,
      duration,
      breakTime,
      lunchBreakTimes, // Pass the lunchBreakTimes object
    });

    // Update therapist object dengan setting baru
    const updatedTherapist = {
      ...therapist,
      startTime,
      endTime,
      slotDuration: duration, // Pastikan ini adalah Number
      lunchBreakTimes, // Include the lunchBreakTimes object
    };

    console.log('Data therapist yang akan dihantar ke onSave:', updatedTherapist);

    try {
      const response = await axios.post(`${config.API_URL}/slots`, 
        { 
          slots,
          bookingCutoffTime: Number(bookingCutoffTime), // Pastikan ini adalah Number
          startTime,
          endTime,
          slotDuration: Number(duration), // Pastikan ini adalah Number
          lunchBreakTimes, // Include the lunchBreakTimes object
          monthsAhead: Number(monthsAhead)
        }, 
        {
          headers: { 'x-auth-token': localStorage.getItem('token') },
        }
      );

      if (response.status === 201) {
        toast.success('Slot berjaya ditambah.');
        onSave(updatedTherapist);
        onClose();
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (error) {
      console.error('Error saving slots:', error);
      toast.error('Gagal menambah slot. Sila cuba lagi.');
    }
  };

  const generateSlots = ({
    startDate,
    endDate,
    availableDays,
    startTime,
    endTime,
    duration,
    breakTime,
    lunchBreakTimes,
  }) => {
    const slots = [];
    const timeZone = 'Asia/Kuala_Lumpur';
    const today = startOfDay(toZonedTime(new Date(), timeZone));

    for (let date = new Date(startDate); date <= endDate; date = addDays(date, 1)) {
      const zonedDate = toZonedTime(date);
      const currentDate = startOfDay(zonedDate);

      if (currentDate < today) {
        continue;
      }

      const dayName = format(zonedDate, 'EEEE', { timeZone }).toLowerCase();
      if (availableDays[dayName]) {
        const dailySlots = generateDailySlots(zonedDate, {
          startTime,
          endTime,
          duration,
          breakTime,
          lunchBreakStart: lunchBreakTimes[dayName].start,
          lunchBreakEnd: lunchBreakTimes[dayName].end,
        });
        slots.push(...dailySlots);
      }
    }

    const slotsWithDetails = slots.map((slot) => ({
      ...slot,
      therapistId: therapist._id,
      treatment: treatment,
    }));

    return slotsWithDetails;
  };

  const generateDailySlots = (
    date,
    {
      startTime,
      endTime,
      duration,
      breakTime,
      lunchBreakStart,
      lunchBreakEnd,
    }
  ) => {
    const slots = [];
    let currentTime = startTime;

    while (isTimeLessThan(currentTime, endTime)) {
      if (
        isTimeGreaterThanOrEqual(currentTime, lunchBreakStart) &&
        isTimeLessThan(currentTime, lunchBreakEnd)
      ) {
        currentTime = lunchBreakEnd;
        continue;
      }

      const slotEndTime = addMinutes(currentTime, duration);
      if (isTimeGreaterThan(slotEndTime, endTime)) break;

      const slotDate = toZonedTime(date);
      slots.push({
        date: formatDate(slotDate, 'yyyy-MM-dd'),
        startTime: currentTime,
        endTime: slotEndTime,
      });

      currentTime = addMinutes(slotEndTime, breakTime);
    }

    return slots;
  };

  // Gunakan formatDate ini
  const formatDate = (date) => {
    const timeZone = 'Asia/Kuala_Lumpur';
    const zonedDate = toZonedTime(date, timeZone);

    return format(zonedDate, 'yyyy-MM-dd', { timeZone });
  };

  const addMinutes = (time, minutesToAdd) => {
    const [hours, minutes] = time.split(':').map(Number);
    const totalMinutes = hours * 60 + minutes + parseInt(minutesToAdd, 10);
    const newHours = Math.floor(totalMinutes / 60);
    const newMinutes = totalMinutes % 60;
    return `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`;
  };

  const isTimeLessThan = (time1, time2) => {
    const [hours1, minutes1] = time1.split(':').map(Number);
    const [hours2, minutes2] = time2.split(':').map(Number);
    return hours1 < hours2 || (hours1 === hours2 && minutes1 < minutes2);
  };

  const isTimeGreaterThan = (time1, time2) => {
    const [hours1, minutes1] = time1.split(':').map(Number);
    const [hours2, minutes2] = time2.split(':').map(Number);
    return hours1 > hours2 || (hours1 === hours2 && minutes1 > minutes2);
  };

  const isTimeGreaterThanOrEqual = (time1, time2) => {
    const [hours1, minutes1] = time1.split(':').map(Number);
    const [hours2, minutes2] = time2.split(':').map(Number);
    return hours1 > hours2 || (hours1 === hours2 && minutes1 >= minutes2);
  };

  const lunchBreakDuration = (startTime, endTime) => {
    const [startHours, startMinutes] = startTime.split(':').map(Number);
    const [endHours, endMinutes] = endTime.split(':').map(Number);
    const startTotalMinutes = startHours * 60 + startMinutes;
    const endTotalMinutes = endHours * 60 + endMinutes;
    return endTotalMinutes - startTotalMinutes;
  };

  return (
    <form onSubmit={handleSubmit} className="edit-slot-form">
      <h2>Edit Slot Tersedia untuk {treatment}</h2>

      <div className="form-group">
        <label>Tetapkan slot untuk berapa bulan ke depan?</label>
        <select
          value={monthsAhead}
          onChange={(e) => setMonthsAhead(e.target.value)}
          disabled={therapist.subscriptionPlan !== 'plus'} // Nyahdayakan jika bukan 'plus'
        >
          {allowedMonths.includes(1) && <option value={1}>1 Bulan</option>}
          {allowedMonths.includes(2) && <option value={2}>2 Bulan</option>}
          {allowedMonths.includes(3) && <option value={3}>3 Bulan</option>}
        </select>
        {therapist.subscriptionPlan !== 'plus' && (
          <p style={{ color: 'blue', marginTop: '5px' }}>
            Hanya langganan <strong>Plus</strong> yang boleh menambah hingga 3 bulan.
          </p>
        )}
      </div>

      {/* Pilih hari yang tersedia dengan toggle switch */}
      <div className="form-group">
        <label>Hari Tersedia:</label>
        <div className="toggle-group">
          {Object.keys(availableDays).map((day) => (
            <div key={day} className="toggle-item">
              <span>{day.charAt(0).toUpperCase() + day.slice(1)}</span>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={availableDays[day]}
                  onChange={() => handleToggleChange(day)}
                />
                <span className="slider round"></span>
              </label>
            </div>
          ))}
        </div>
      </div>

      {/* Tetapkan waktu mula dan tamat */}
      <div className="form-group">
        <label>Masa Mula:</label>
        <input
          type="time"
          value={startTime}
          onChange={(e) => setStartTime(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Masa Tamat:</label>
        <input
          type="time"
          value={endTime}
          onChange={(e) => setEndTime(e.target.value)}
        />
      </div>

      {/* Tetapkan durasi slot */}
      <div className="form-group">
        <label>Durasi per Slot (minit):</label>
        <input
          type="number"
          value={duration}
          onChange={(e) => setDuration(parseInt(e.target.value, 10))}
          min={15}
          step={15}
        />
      </div>

      {/* Tetapkan masa rehat antara slot */}
      <div className="form-group">
        <label>Masa Rehat antara Slot (minit):</label>
        <input
          type="number"
          value={breakTime}
          onChange={(e) => setBreakTime(parseInt(e.target.value, 10))}
          min={0}
          step={5}
        />
      </div>

      {/* Tetapkan masa rehat makan tengah hari untuk setiap hari */}
      <div className="form-group">
        <label>Masa Rehat Makan Tengah Hari:</label>
        
        {/* Paparkan notis jika tiada hari diaktifkan */}
        {Object.keys(availableDays).every((day) => !availableDays[day]) && (
          <p style={{ color: 'red' }}>Tiada hari yang diaktifkan untuk masa rehat makan tengah hari.</p>
        )}
      
        {/* Tapis hari yang diaktifkan dan paparkan masa rehat */}
        {Object.keys(lunchBreakTimes)
          .filter((day) => availableDays[day]) // Tunjuk hanya hari yang diaktifkan
          .map((day) => (
            <div key={day} className="lunch-break-group">
              <h4>{day.charAt(0).toUpperCase() + day.slice(1)}</h4>
              <div className="time-inputs">
                <label>
                  Mula:
                  <input
                    type="time"
                    value={lunchBreakTimes[day].start}
                    onChange={(e) => handleLunchBreakChange(day, 'start', e.target.value)}
                    required
                  />
                </label>
                <label>
                  Tamat:
                  <input
                    type="time"
                    value={lunchBreakTimes[day].end}
                    onChange={(e) => handleLunchBreakChange(day, 'end', e.target.value)}
                    required
                  />
                </label>
              </div>
            </div>
          ))}
      </div>

      <div className="form-group">
        <label>Masa Cutoff Tempahan (minit):</label>
        <input
          type="number"
          value={bookingCutoffTime}
          onChange={(e) => setBookingCutoffTime(parseInt(e.target.value, 10))}
          min={0}
        />
      </div>

      {/* Butang Simpan dan Batal */}
      <div className="form-actions">
        <button type="submit" className="save-button">
          Simpan Slot
        </button>
        <button type="button" onClick={onClose} className="cancel-button">
          Batal
        </button>
      </div>
    </form>
  );
}

export default EditSlotForm;
