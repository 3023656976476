// src/components/WhatsappIntegrationAdmin.jsx

import React, { useState, useEffect, useRef } from 'react';
import QRCode from "react-qr-code";
import './WhatsappIntegrationAdmin.css';
import { ClipLoader } from 'react-spinners'; // Spinner dari react-spinners
import axios from 'axios';


const WhatsappIntegrationAdmin = ({ adminId }) => {
  const [qrCodeData, setQrCodeData] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('disconnected');
  const [showGetQrButton, setShowGetQrButton] = useState(false);
  const sessionId = localStorage.getItem('userId');
  const pollingRef = useRef(null);

  // Enum untuk status
  const STATUS = {
    DISCONNECTED: 'disconnected',
    SESSION_STARTED: 'session_started',
    QR_PENDING: 'qr_pending',
    CONNECTED: 'connected',
  };

  // Tambahkan useEffect untuk memeriksa status semasa komponen dimuatkan
useEffect(() => {
  const checkInitialStatus = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token'); // Jika autentikasi diperlukan
      const response = await axios.get(`/api/whatsapp/session-status/${sessionId}`, {
        headers: {
          'x-auth-token': token,
        },
      });
      const currentStatus = response.data.status;
      console.log('Status Sesi Semasa:', currentStatus);
      if (currentStatus === 'CONNECTED') {
        setStatus(STATUS.CONNECTED);
      } else if (currentStatus === 'FAILED' || currentStatus === 'DISCONNECTED') {
        setStatus(STATUS.DISCONNECTED);
      } else if (currentStatus === 'SESSION_STARTED') {
        setStatus(STATUS.SESSION_STARTED);
        setShowGetQrButton(true);
      } else {
        setStatus(STATUS.DISCONNECTED);
      }
    } catch (error) {
      console.error('Ralat semasa semak status awal:', error);
      setStatus(STATUS.DISCONNECTED);
    } finally {
      setLoading(false);
    }
  };

  checkInitialStatus();
}, [sessionId]);



  // Mulakan sesi apabila butang "Sambung WhatsApp" ditekan
  const handleConnectButtonClick = async () => {
  try {
    setLoading(true);
    const token = localStorage.getItem('token'); // Jika autentikasi diperlukan
    await axios.get(`/api/whatsapp/start-session/${sessionId}`, {
      headers: {
        'x-auth-token': token,
      },
    });
    console.log('Sesi dimulakan dengan jayanya');
    setStatus(STATUS.SESSION_STARTED);

    // Tambahkan delay sebelum paparkan butang QR Code
    setTimeout(() => {
      setShowGetQrButton(true);
    }, 5000);

  } catch (error) {
    console.error('Ralat semasa menyambung:', error);
    // Tambahkan notifikasi ralat jika perlu
  } finally {
    setLoading(false);
  }
};




  // Dapatkan QR Code apabila butang "Dapatkan QR Code" ditekan
const handleGetQrCodeClick = async () => {
  try {
    setLoading(true);
    const token = localStorage.getItem('token'); // Jika autentikasi diperlukan
    const response = await axios.get(`/api/whatsapp/session-qr/${sessionId}`, {
      headers: {
        'x-auth-token': token,
      },
    });
    const qrData = response.data.qrCodeData;
    if (qrData) {
      setQrCodeData(qrData);
      setStatus(STATUS.QR_PENDING);
      setShowGetQrButton(false);
      startPollingStatus();
    } else {
      console.error('Ralat: Data QR tidak tersedia');
      // Tambahkan notifikasi ralat jika perlu
    }
  } catch (error) {
    console.error('Ralat semasa mendapatkan QR code:', error);
    // Tambahkan notifikasi ralat jika perlu
  } finally {
    setLoading(false);
  }
};



  // Mulakan polling untuk semak status
const startPollingStatus = () => {
  if (pollingRef.current) return;

  let attempts = 0;
  const maxAttempts = 12;

  pollingRef.current = setInterval(async () => {
    try {
      const token = localStorage.getItem('token'); // Jika autentikasi diperlukan
      const response = await axios.get(`/api/whatsapp/session-status/${sessionId}`, {
        headers: {
          'x-auth-token': token,
        },
      });
      const currentStatus = response.data.status;
      console.log('Semak Status Sambungan -', currentStatus);
      attempts += 1;

      if (currentStatus === 'CONNECTED') {
        setStatus(STATUS.CONNECTED);
        setQrCodeData('');
        clearInterval(pollingRef.current);
        pollingRef.current = null;
        console.log('WhatsApp berjaya disambungkan');
      } else if (currentStatus === 'FAILED' || currentStatus === 'DISCONNECTED') {
        console.warn('QR Code tidak diimbas atau sambungan gagal.');
        await axios.get(`/api/whatsapp/terminate-session/${sessionId}`, {
          headers: {
            'x-auth-token': token,
          },
        });
        setStatus(STATUS.DISCONNECTED);
        setQrCodeData('');
        clearInterval(pollingRef.current);
        pollingRef.current = null;
      } else if (attempts >= maxAttempts) {
        console.warn('Polling telah mencapai bilangan percubaan maksimum.');
        clearInterval(pollingRef.current);
        pollingRef.current = null;
      }
    } catch (error) {
      console.error('Ralat semasa semak status:', error);
      clearInterval(pollingRef.current);
      pollingRef.current = null;
      setStatus(STATUS.DISCONNECTED);
      setQrCodeData('');
    }
  }, 5000);
};



  // Reset sambungan
const handleResetConnectionClick = async () => {
  try {
    setLoading(true);
    const token = localStorage.getItem('token'); // Jika autentikasi diperlukan
    await axios.get(`/api/whatsapp/terminate-session/${sessionId}`, {
      headers: {
        'x-auth-token': token,
      },
    });
    console.log('Semua sesi telah ditamatkan');
    setStatus(STATUS.DISCONNECTED);
    setQrCodeData('');
    setShowGetQrButton(false);
    if (pollingRef.current) {
      clearInterval(pollingRef.current);
      pollingRef.current = null;
    }
  } catch (error) {
    console.error('Ralat semasa reset sambungan:', error);
    // Tambahkan notifikasi ralat jika perlu
  } finally {
    setLoading(false);
  }
};

const handleDisconnectButtonClick = async () => {
  try {
    setLoading(true);
    const token = localStorage.getItem('token'); // Jika autentikasi diperlukan
    await axios.get(`/api/whatsapp/terminate-session/${sessionId}`, {
      headers: {
        'x-auth-token': token,
      },
    });
    console.log('Sesi telah diputuskan');
    setStatus(STATUS.DISCONNECTED);
    setQrCodeData('');
    setShowGetQrButton(false);
    if (pollingRef.current) {
      clearInterval(pollingRef.current);
      pollingRef.current = null;
    }
  } catch (error) {
    console.error('Ralat semasa putuskan sambungan:', error);
    // Tambahkan notifikasi ralat jika perlu
  } finally {
    setLoading(false);
  }
};



  // Pastikan polling dihentikan apabila komponen dibuang
  useEffect(() => {
    return () => {
      if (pollingRef.current) {
        clearInterval(pollingRef.current);
      }
    };
  }, []);

  return (
    <div className="whatsapp-integration-container">
      <h2>Integrasi WhatsApp</h2>
<p>
  Status:{' '}
  {loading
    ? 'Sila tunggu, sedang menyemak status sambungan anda...'
    : status === STATUS.CONNECTED
    ? 'WhatsApp Berjaya Disambung'
    : status === STATUS.QR_PENDING
    ? 'Dalam Proses Sambungan (QR Diperlukan)'
    : status === STATUS.SESSION_STARTED
    ? 'Sesi Dimulakan. Sila tunggu button "Dapatkan QR Code".'
    : 'Tidak Disambung'}
</p>


      {/* Spinner semasa loading */}
      {loading && (
        <div className="spinner-container">
          <ClipLoader color="#4CAF50" loading={loading} size={50} />
        </div>
      )}

      {/* Butang "Sambung WhatsApp" */}
      {status === STATUS.DISCONNECTED && (
        <button
          id="connectButton"
          onClick={handleConnectButtonClick}
          disabled={loading}
        >
          Sambung WhatsApp
        </button>
      )}

      {/* Butang "Dapatkan QR Code" */}
      {showGetQrButton && status === STATUS.SESSION_STARTED && (
        <button
          id="getQrButton"
          onClick={handleGetQrCodeClick}
          disabled={loading}
        >
          Dapatkan QR Code
        </button>
      )}

      {/* Butang "Putuskan Sambungan" */}
      {status === STATUS.CONNECTED && (
        <button id="disconnectButton" onClick={handleDisconnectButtonClick} disabled={loading}>
          Putuskan Sambungan
        </button>
      )}

{/* Butang "Reset Sambungan" Sentiasa Ada */}
<button id="resetButton" onClick={handleResetConnectionClick} disabled={loading}>
  {loading ? 'Reset...' : 'Reset Sambungan'}
</button>
      {/* Paparkan QR Code */}
      {qrCodeData && status === STATUS.QR_PENDING && (
        <div id="qrCodeContainer">
          <QRCode value={qrCodeData} size={200} />
          <p>Sila imbas QR Code ini dengan WhatsApp anda.</p>
        </div>
      )}
    </div>
  );
};

export default WhatsappIntegrationAdmin;
