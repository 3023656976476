// components/CRMDashboard.js
import React, { useState, useEffect } from 'react';
import { FaWhatsapp, FaUser, FaUsers, FaStar, FaBroadcastTower, FaTags } from 'react-icons/fa';
import './CRMDashboard.css';
import WhatsappIntegration from './WhatsappIntegration';
import ProspectList from './ProspectList';
import Customer from './Customer';
import VVIPCustomer from './VVIPCustomer';
import Broadcast from './Broadcast';
import Labels from './Labels';
import PinForm from './PinForm';
import { toast } from 'react-toastify';

const CRMDashboard = () => {
  const [activeTab, setActiveTab] = useState('whatsapp');
  const [therapistId, setTherapistId] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showPinForm, setShowPinForm] = useState(false);
  const [pinChecked, setPinChecked] = useState(false);

  useEffect(() => {
    const storedTherapistId = localStorage.getItem('userId');
    if (!storedTherapistId) {
      toast.error('Sesi terputus. Sila log masuk semula.');
      return;
    }
    setTherapistId(storedTherapistId);
  }, []);

  const handlePinSuccess = () => {
    setIsAuthenticated(true);
    setShowPinForm(false);
  };

  useEffect(() => {
    const checkPin = async () => {
      try {
        const res = await fetch('/pin', {
          headers: {
            'Cache-Control': 'no-cache',
            'Therapist-ID': therapistId,
          },
        });
        const data = await res.json();
        if (data.exists) {
          setPinChecked(true);
          // Always show PIN form for verification if PIN exists
          setShowPinForm(true);
        } else {
          setPinChecked(false);
          setShowPinForm(true);
        }
      } catch (error) {
        toast.error('Ralat semasa menyemak PIN.');
      }
    };

    if (therapistId) {
      checkPin();
    }
  }, [therapistId]);

  // If therapist ID is not found, show loading
  if (!therapistId) {
    return <div className="loading-container">Memuat...</div>;
  }

  // If PIN form needs to be shown, render PinForm
  if (showPinForm) {
    return <PinForm 
      therapistId={therapistId} 
      onSuccess={handlePinSuccess} 
      isSetting={!pinChecked}
    />;
  }

  // Main CRM Dashboard render
  return (
    <div className="crm-dashboard">
      <div className="crm-tabs">
        <button
          className={`crm-tab ${activeTab === 'whatsapp' ? 'active' : ''}`}
          onClick={() => setActiveTab('whatsapp')}
        >
          <FaWhatsapp /> <span>Integrasi WhatsApp</span>
        </button>
        <button
          className={`crm-tab ${activeTab === 'broadcast' ? 'active' : ''}`}
          onClick={() => setActiveTab('broadcast')}
        >
          <FaBroadcastTower /> <span>Broadcast</span>
        </button>
        <button
          className={`crm-tab ${activeTab === 'prospects' ? 'active' : ''}`}
          onClick={() => setActiveTab('prospects')}
        >
          <FaUser /> <span>Prospek</span>
        </button>
        <button
          className={`crm-tab ${activeTab === 'customers' ? 'active' : ''}`}
          onClick={() => setActiveTab('customers')}
        >
          <FaUsers /> <span>Pelanggan</span>
        </button>
        <button
          className={`crm-tab vvip ${activeTab === 'vvip' ? 'active' : ''}`}
          onClick={() => setActiveTab('vvip')}
        >
          <FaStar /> <span>VVIP</span>
        </button>
        <button
          className={`crm-tab ${activeTab === 'labels' ? 'active' : ''}`}
          onClick={() => setActiveTab('labels')}
        >
          <FaTags /> <span>Labels</span>
        </button>
      </div>

      <div className="tab-content">
        {activeTab === 'whatsapp' && <WhatsappIntegration therapistId={therapistId} />}
        {activeTab === 'broadcast' && <Broadcast therapistId={therapistId} />}
        {activeTab === 'prospects' && <ProspectList therapistId={therapistId} />}
        {activeTab === 'customers' && <Customer therapistId={therapistId} />}
        {activeTab === 'vvip' && <VVIPCustomer therapistId={therapistId} />}
        {activeTab === 'labels' && <Labels therapistId={therapistId} />}
      </div>
    </div>
  );
};

export default CRMDashboard;