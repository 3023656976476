// DiagnosForm.js

import React, { useState, useEffect } from 'react';
import './DiagnosForm.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import config from '../config';
import { format, parseISO } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStethoscope } from '@fortawesome/free-solid-svg-icons';

function DiagnosForm({ booking, onClose, onDiagnosSubmitted, isEdit }) {
  const [diagnosData, setDiagnosData] = useState([
    {
      issueDescription: [],
      findings: [],
      recommendations: [],
    },
  ]);

  const [isEditMode, setIsEditMode] = useState(isEdit); // State untuk mod edit
  const [showEditRecommendations, setShowEditRecommendations] = useState(false);
  const [showEditFindings, setShowEditFindings] = useState(false);
  const [showEditOptions, setShowEditOptions] = useState(false);
  const [newOption, setNewOption] = useState('');
  const [newOptionField, setNewOptionField] = useState('');
  const [deletedDiagnosIds, setDeletedDiagnosIds] = useState([]);
  const [options, setOptions] = useState({
    masalahPelangganOptions: [],
    recommendationsOptions: [],
    findingsOptions: [],
  });

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${config.API_URL}/diagnos/options`, {
          headers: { 'x-auth-token': token },
        });
        setOptions(response.data);
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    if (booking.diagnos && Array.isArray(booking.diagnos) && booking.diagnos.length > 0) {
      setDiagnosData(booking.diagnos);
      setIsEditMode(isEdit); // Tetapkan mod berdasarkan prop isEdit
    } else {
      setDiagnosData([{ issueDescription: [], findings: [], recommendations: [] }]);
      setIsEditMode(true); // Dalam mod tambah baru
    }
  }, [booking, isEdit]);

  // Fungsi Pembantu untuk Konfirmasi
  const confirmAction = (message) => {
    return window.confirm(message);
  };

  const handleCheckboxChange = (e, field, index) => {
    const value = e.target.value;
    const checked = e.target.checked;

    setDiagnosData((prevData) => {
      const updatedDiagnos = [...prevData];
      const currentValues = updatedDiagnos[index][field];

      if (checked) {
        updatedDiagnos[index][field] = [...currentValues, value];
      } else {
        updatedDiagnos[index][field] = currentValues.filter((item) => item !== value);
      }

      return updatedDiagnos;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!confirmAction('Adakah anda pasti ingin menghantar borang diagnos ini?')) {
      return;
    }
    try {
      const token = localStorage.getItem('token');
      
      // Mengemas kini Diagnos yang Sedia Ada
      if (isEditMode && booking.diagnos && booking.diagnos.length > 0) {
        for (let i = 0; i < diagnosData.length; i++) {
          const diagnos = diagnosData[i];
          if (diagnos._id) {
            await axios.put(
              `${config.API_URL}/diagnos/${diagnos._id}`,
              {
                issueDescription: diagnos.issueDescription,
                findings: diagnos.findings,
                recommendations: diagnos.recommendations,
              },
              {
                headers: { 'x-auth-token': token },
              }
            );
          } else {
            // Jika diagnos baru tanpa ID, hantar POST
            const response = await axios.post(
              `${config.API_URL}/diagnos`,
              {
                bookingId: booking._id,
                diagnoses: [diagnos],
              },
              {
                headers: { 'x-auth-token': token },
              }
            );
            
            // Mengemas kini Booking dengan ID Diagnos Baru
            await axios.put(
              `${config.API_URL}/bookings/${booking._id}`,
              { diagnosIds: response.data.map(d => d._id) },
              {
                headers: { 'x-auth-token': token },
              }
            );
          }
        }
        
        // Menghantar Permintaan DELETE untuk Diagnos yang Dipadamkan
        for (let id of deletedDiagnosIds) {
          await axios.delete(`${config.API_URL}/diagnos/${id}`, {
            headers: { 'x-auth-token': token },
          });
        }

        toast.success('Diagnos berjaya dikemaskini.');
      } else {
        // Jika dalam mod tambah baru
        const response = await axios.post(
          `${config.API_URL}/diagnos`,
          {
            bookingId: booking._id,
            diagnoses: diagnosData,
          },
          {
            headers: { 'x-auth-token': token },
          }
        );

        await axios.put(
          `${config.API_URL}/bookings/${booking._id}`,
          { diagnosIds: response.data.map(d => d._id) },
          {
            headers: { 'x-auth-token': token },
          }
        );

        toast.success('Diagnos berjaya disimpan.');
      }

      // Reset deletedDiagnosIds selepas simpanan berjaya
      setDeletedDiagnosIds([]);
      onDiagnosSubmitted();
      onClose();
    } catch (error) {
      console.error('Error submitting diagnos:', error);
      toast.error('Gagal menyimpan diagnos. Sila cuba lagi.');
    }
  };

  const handleAddOption = async () => {
    if (!confirmAction('Adakah anda pasti ingin menambah pilihan baru?')) {
      return;
    }
    try {
      if (!newOptionField) {
        toast.error('Sila pilih bahagian untuk menambah pilihan.');
        return;
      }

      if (!newOption.trim()) {
        toast.error('Sila masukkan pilihan baru.');
        return;
      }

      const token = localStorage.getItem('token');
      const response = await axios.put(
        `${config.API_URL}/diagnos/options`,
        { newOption: newOption.trim(), field: newOptionField },
        {
          headers: { 'x-auth-token': token },
        }
      );
      setOptions((prevOptions) => ({
        ...prevOptions,
        [newOptionField]: response.data,
      }));
      setNewOption('');
      setNewOptionField('');
      toast.success('Pilihan baru berjaya ditambah.');
    } catch (error) {
      console.error('Error adding new option:', error);
      toast.error('Gagal menambah pilihan baru.');
    }
  };

  const handleDeleteOption = async (optionToDelete, field) => {
    if (!confirmAction(`Adakah anda pasti ingin memadam pilihan "${optionToDelete}"?`)) {
      return;
    }
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(
        `${config.API_URL}/diagnos/options`,
        {
          headers: { 'x-auth-token': token },
          data: { optionToDelete, field },
        }
      );
      setOptions((prevOptions) => ({
        ...prevOptions,
        [field]: response.data,
      }));
      toast.success('Pilihan berjaya dipadam.');
    } catch (error) {
      console.error('Error deleting option:', error);
      toast.error('Gagal memadam pilihan.');
    }
  };

  const removeDiagnos = (index) => {
    if (!confirmAction('Adakah anda pasti ingin menghapus individu ini?')) {
      return;
    }
    const diagnosToRemove = diagnosData[index];
    if (diagnosToRemove._id) {
      setDeletedDiagnosIds((prev) => [...prev, diagnosToRemove._id]);
    }
    const updatedDiagnos = diagnosData.filter((_, i) => i !== index);
    setDiagnosData(updatedDiagnos);
  };

  const addDiagnos = () => {
    if (!confirmAction('Adakah anda ingin menambah individu baru?')) {
      return;
    }
    setDiagnosData([
      ...diagnosData,
      { issueDescription: [], findings: [], recommendations: [] },
    ]);
  };

  // Menghapus deklarasi kedua removeDiagnos
  // const removeDiagnos = (index) => {
  //   const updatedDiagnos = diagnosData.filter((_, i) => i !== index);
  //   setDiagnosData(updatedDiagnos);
  // };

  const formatDate = (date) => {
    if (!date) return '-';
    return format(parseISO(date), 'dd/MM/yyyy');
  };

  const toggleEditMode = () => {
    if (isEditMode) return; // Jika sudah dalam mod edit, jangan lakukan apa-apa
    if (!confirmAction('Adakah anda ingin beralih ke mod edit?')) {
      return;
    }
    setIsEditMode(true);
  };

  return (
    <div className="diagnos-form">
      <h2>{isEditMode ? 'Borang Edit Diagnos' : 'Borang Paparan Diagnos'}</h2>
      {/* Bahagian 1: Detail Booking */}
      <div className="booking-details">
        <h3>Maklumat Tempahan</h3>
        <p>Nama Klien: {booking.userName}</p>
        <p>No Telefon Klien: {booking.userPhone}</p>
        <p>
          Tarikh Tempahan: {format(parseISO(booking.bookingDate), 'dd/MM/yyyy')}
        </p>
        <p>Jenis Perkhidmatan: {booking.serviceType}</p>
        <p>Rawatan: {booking.treatment}</p>
      </div>

      {/* Bahagian 2: Diagnos */}
      {isEditMode ? (
        <form onSubmit={handleSubmit}>
          <h3>Diagnos</h3>

          {diagnosData.map((diagnos, index) => (
            <div key={index} className="diagnos-section">
              <h4>Individu {index + 1}</h4>

              {/* Masalah Pelanggan */}
              <div className="form-group">
                <h5>Masalah Pelanggan:</h5>
                <div className="checkbox-group">
                  {options.masalahPelangganOptions.map((option, idx) => (
                    <label key={idx} className="checkbox-label">
                      <input
                        type="checkbox"
                        value={option}
                        checked={diagnos.issueDescription.includes(option)}
                        onChange={(e) =>
                          handleCheckboxChange(e, 'issueDescription', index)
                        }
                      />
                      {option}
                    </label>
                  ))}
                </div>

                {/* Button Edit Options */}
                <button
                  type="button"
                  onClick={() => setShowEditOptions(!showEditOptions)}
                  className="edit-options-button"
                >
                  {showEditOptions ? 'Sembunyi Pilihan' : 'Edit Pilihan'}
                </button>

                {/* Senarai pilihan dengan butang delete */}
                {showEditOptions && (
                  <div className="dropdown-options-with-delete">
                    {options.masalahPelangganOptions.map((option, idx) => (
                      <div key={idx} className="dropdown-option-item">
                        <span>{option}</span>
                        <button
                          type="button"
                          className="delete-option-button"
                          onClick={() =>
                            handleDeleteOption(option, 'masalahPelangganOptions')
                          }
                        >
                          X
                        </button>
                      </div>
                    ))}
                  </div>
                )}

                {/* Input untuk tambah pilihan baru */}
                <div className="new-option-container">
                  <input
                    type="text"
                    placeholder="Tambah pilihan baru"
                    value={
                      newOptionField === 'masalahPelangganOptions'
                        ? newOption
                        : ''
                    }
                    onChange={(e) => {
                      setNewOption(e.target.value);
                      setNewOptionField('masalahPelangganOptions');
                    }}
                  />
                  <button
                    type="button"
                    onClick={handleAddOption}
                    className="add-option-button"
                  >
                    Tambah Masalah Pelanggan
                  </button>
                </div>
              </div>

              {/* Cadangan Rawatan/Senaman */}
              <div className="form-group">
                <h5>Cadangan Rawatan/Senaman:</h5>
                <div className="checkbox-group">
                  {options.recommendationsOptions.map((option, idx) => (
                    <label key={idx} className="checkbox-label">
                      <input
                        type="checkbox"
                        value={option}
                        checked={diagnos.recommendations.includes(option)}
                        onChange={(e) =>
                          handleCheckboxChange(e, 'recommendations', index)
                        }
                      />
                      {option}
                    </label>
                  ))}
                </div>

                {/* Button Edit Options */}
                <button
                  type="button"
                  onClick={() =>
                    setShowEditRecommendations(!showEditRecommendations)
                  }
                  className="edit-options-button"
                >
                  {showEditRecommendations ? 'Sembunyi Pilihan' : 'Edit Pilihan'}
                </button>

                {/* Senarai pilihan dengan butang delete */}
                {showEditRecommendations && (
                  <div className="dropdown-options-with-delete">
                    {options.recommendationsOptions.map((option, idx) => (
                      <div key={idx} className="dropdown-option-item">
                        <span>{option}</span>
                        <button
                          type="button"
                          className="delete-option-button"
                          onClick={() =>
                            handleDeleteOption(option, 'recommendationsOptions')
                          }
                        >
                          X
                        </button>
                      </div>
                    ))}
                  </div>
                )}

                {/* Input untuk tambah pilihan baru */}
                <div className="new-option-container">
                  <input
                    type="text"
                    placeholder="Tambah pilihan baru"
                    value={
                      newOptionField === 'recommendationsOptions'
                        ? newOption
                        : ''
                    }
                    onChange={(e) => {
                      setNewOption(e.target.value);
                      setNewOptionField('recommendationsOptions');
                    }}
                  />
                  <button
                    type="button"
                    onClick={handleAddOption}
                    className="add-option-button"
                  >
                    Tambah Cadangan
                  </button>
                </div>
              </div>

              {/* Hasil Diagnosis */}
              <div className="form-group">
                <h5>Hasil Diagnosis:</h5>
                <div className="checkbox-group">
                  {options.findingsOptions.map((option, idx) => (
                    <label key={idx} className="checkbox-label">
                      <input
                        type="checkbox"
                        value={option}
                        checked={diagnos.findings.includes(option)}
                        onChange={(e) => handleCheckboxChange(e, 'findings', index)}
                      />
                      {option}
                    </label>
                  ))}
                </div>

                {/* Button Edit Options */}
                <button
                  type="button"
                  onClick={() => setShowEditFindings(!showEditFindings)}
                  className="edit-options-button"
                >
                  {showEditFindings ? 'Sembunyi Pilihan' : 'Edit Pilihan'}
                </button>

                {/* Senarai pilihan dengan butang delete */}
                {showEditFindings && (
                  <div className="dropdown-options-with-delete">
                    {options.findingsOptions.map((option, idx) => (
                      <div key={idx} className="dropdown-option-item">
                        <span>{option}</span>
                        <button
                          type="button"
                          className="delete-option-button"
                          onClick={() =>
                            handleDeleteOption(option, 'findingsOptions')
                          }
                        >
                          X
                        </button>
                      </div>
                    ))}
                  </div>
                )}

                {/* Input untuk tambah pilihan baru */}
                <div className="new-option-container">
                  <input
                    type="text"
                    placeholder="Tambah pilihan baru"
                    value={
                      newOptionField === 'findingsOptions' ? newOption : ''
                    }
                    onChange={(e) => {
                      setNewOption(e.target.value);
                      setNewOptionField('findingsOptions');
                    }}
                  />
                  <button
                    type="button"
                    onClick={handleAddOption}
                    className="add-option-button"
                  >
                    Tambah Hasil Diagnosis
                  </button>
                </div>
              </div>

              {/* Butang untuk menghapus diagnosis */}
              {isEditMode && diagnosData.length > 1 && (
                <button
                  type="button"
                  onClick={() => removeDiagnos(index)}
                  className="remove-diagnos-button"
                >
                  Hapus Individu
                </button>
              )}
            </div>
          ))}

          {/* Butang untuk menambah diagnosis baru */}
          {isEditMode && (
            <button type="button" onClick={addDiagnos} className="add-diagnos-button">
              Tambah Individu
            </button>
          )}

          {/* Button Actions */}
          <div className="form-actions">
            <button
              type="submit"
              className="submit-button"
            >
              {booking.diagnos && booking.diagnos.length > 0 ? 'Simpan Perubahan' : 'Hantar Diagnos'}
            </button>
            <button
              type="button"
              onClick={() => {
                if (confirmAction('Adakah anda pasti ingin membatalkan perubahan?')) {
                  onClose();
                }
              }}
              className="cancel-button"
            >
              Batal
            </button>
          </div>
        </form>
      ) : (
        <div className="view-mode">
          <h3>Diagnos</h3>

          {diagnosData.map((diagnos, index) => (
            <div key={index} className="diagnos-section">
              <h4>Individu {index + 1}</h4>

              {/* Masalah Pelanggan */}
              <div className="form-group">
                <h5>Masalah Pelanggan:</h5>
                <p>{diagnos.issueDescription.join(', ') || '-'}</p>
              </div>

              {/* Cadangan Rawatan/Senaman */}
              <div className="form-group">
                <h5>Cadangan Rawatan/Senaman:</h5>
                <p>{diagnos.recommendations.join(', ') || '-'}</p>
              </div>

              {/* Hasil Diagnosis */}
              <div className="form-group">
                <h5>Hasil Diagnosis:</h5>
                <p>{diagnos.findings.join(', ') || '-'}</p>
              </div>
            </div>
          ))}

          {/* Button Actions */}
          <div className="form-actions">
            <button
              type="button"
              onClick={toggleEditMode}
              className="edit-button"
            >
              <FontAwesomeIcon icon={faStethoscope} /> Edit
            </button>
            <button
              type="button"
              onClick={() => {
                if (confirmAction('Adakah anda pasti ingin menutup borang ini?')) {
                  onClose();
                }
              }}
              className="cancel-button"
            >
              Tutup
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default DiagnosForm;
