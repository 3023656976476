// src/components/Spinner.js

import React from 'react';
import { css } from '@emotion/react';
import {
  ClipLoader,
  BeatLoader,
  BounceLoader,
  SyncLoader,
  RingLoader,
} from 'react-spinners';
import PropTypes from 'prop-types';

const spinnerStyles = css`
  display: block;
  margin: 0 auto;
`;

function Spinner({
  type = 'ClipLoader',
  size = 50,
  color = '#2980b9',
  loading = true,
  label = 'Loading...',
  ...props
}) {
  if (!loading) return null;

  const renderSpinner = () => {
    switch (type) {
      case 'BeatLoader':
        return (
          <BeatLoader
            css={spinnerStyles}
            size={size}
            color={color}
            loading={loading}
            {...props}
          />
        );
      case 'BounceLoader':
        return (
          <BounceLoader
            css={spinnerStyles}
            size={size}
            color={color}
            loading={loading}
            {...props}
          />
        );
      case 'SyncLoader':
        return (
          <SyncLoader
            css={spinnerStyles}
            size={size}
            color={color}
            loading={loading}
            {...props}
          />
        );
      case 'RingLoader':
        return (
          <RingLoader
            css={spinnerStyles}
            size={size}
            color={color}
            loading={loading}
            {...props}
          />
        );
      case 'ClipLoader':
      default:
        return (
          <ClipLoader
            css={spinnerStyles}
            size={size}
            color={color}
            loading={loading}
            {...props}
          />
        );
    }
  };

  return (
    <div role="status" aria-live="polite" aria-label={label}>
      {renderSpinner()}
    </div>
  );
}

Spinner.propTypes = {
  type: PropTypes.oneOf([
    'ClipLoader',
    'BeatLoader',
    'BounceLoader',
    'SyncLoader',
    'RingLoader',
  ]),
  size: PropTypes.number,
  color: PropTypes.string,
  loading: PropTypes.bool,
  label: PropTypes.string,
};

export default Spinner;
