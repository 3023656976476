// ManageTherapists.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ManageTherapists.css';
import { toast } from 'react-toastify';
import { FaToggleOn, FaToggleOff } from 'react-icons/fa';
import config from '../config';

function ManageTherapists() {
  const [therapists, setTherapists] = useState([]);
  const [filteredTherapists, setFilteredTherapists] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editTherapist, setEditTherapist] = useState(null);
  const [errors, setErrors] = useState({});
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [subscriptionPlan, setSubscriptionPlan] = useState('basic');
  const [subscriptionStartDate, setSubscriptionStartDate] = useState('');
  const [subscriptionEndDate, setSubscriptionEndDate] = useState('');

  // Tambah state untuk filter
  const [filterName, setFilterName] = useState('');
  const [filterState, setFilterState] = useState('');
  const [filterPhone, setFilterPhone] = useState('');
  const [filterSubscription, setFilterSubscription] = useState('all');

  // Tambah state untuk sub-tab
  const [activeTab, setActiveTab] = useState('manageTherapists');
  const [selectedStateForCities, setSelectedStateForCities] = useState('');
  const [citiesList, setCitiesList] = useState([]);

  useEffect(() => {
    fetchTherapists();
    fetchStates();
  }, []);

  const fetchTherapists = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await axios.get(`${config.API_URL}/therapists`, { // Pastikan endpoint betul
        headers: {
          'x-auth-token': token,
        },
      });
      console.log('Data Terapis Diterima:', response.data);

      // Sesuaikan mengikut struktur data yang diterima
      let therapistData = [];
      if (Array.isArray(response.data.therapists)) {
        therapistData = response.data.therapists;
      } else if (Array.isArray(response.data)) {
        therapistData = response.data;
      } else {
        // Jika data tidak dalam bentuk yang dijangka
        therapistData = [];
        toast.error('Struktur data terapis tidak dijangka.');
      }

      // Susun senarai berdasarkan pelan langganan: Plus, free_trial, Basic
      therapistData.sort((a, b) => {
        const planOrder = { plus: 1, free_trial: 2, basic: 3 };
        return planOrder[a.subscriptionPlan] - planOrder[b.subscriptionPlan];
      });

      setTherapists(therapistData);
      setFilteredTherapists(therapistData);
    } catch (error) {
      console.error('Error fetching therapists:', error);
      toast.error('Gagal mendapatkan senarai terapis. Sila cuba lagi.');
    }
  };

  const fetchStates = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/api/states`);
      setStates(response.data);
    } catch (error) {
      console.error('Error fetching states:', error);
      toast.error('Gagal mendapatkan senarai negeri. Sila cuba lagi.');
    }
  };

  const handleEdit = (therapist) => {
    setEditTherapist({ ...therapist });
    setSubscriptionPlan(therapist.subscriptionPlan || 'basic'); // Set pelan langganan semasa
    setSubscriptionStartDate(therapist.subscriptionStartDate ? therapist.subscriptionStartDate.slice(0,10) : '');
    setSubscriptionEndDate(therapist.subscriptionEndDate ? therapist.subscriptionEndDate.slice(0,10) : '');
    if (therapist.location && therapist.location.state) {
      fetchCities(therapist.location.state);
    }
    fetchAvailableSlots(therapist._id);
    setIsEditModalOpen(true);
  };

  const fetchCities = async (state) => {
    try {
      const response = await axios.get(`${config.API_URL}/api/cities/${state}`);
      setCities(response.data);
    } catch (error) {
      console.error('Error fetching cities:', error);
      toast.error('Gagal mendapatkan senarai bandar. Sila cuba lagi.');
    }
  };

  const fetchAvailableSlots = async (therapistId) => {
    try {
      const response = await axios.get(
        `${config.API_URL}/api/therapists/${therapistId}/available-slots` // Pastikan endpoint betul
      );
      setAvailableSlots(response.data);
    } catch (error) {
      console.error('Error fetching available slots:', error);
      toast.error('Gagal mendapatkan slot tersedia. Sila cuba lagi.');
    }
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setEditTherapist(null);
    setErrors({});
    setCities([]);
    setAvailableSlots([]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditTherapist((prev) => ({ ...prev, [name]: value }));
  };

  const handleLocationChange = (e) => {
    const { name, value } = e.target;
    setEditTherapist((prev) => ({
      ...prev,
      location: { ...prev.location, [name]: value },
    }));

    if (name === 'state') {
      fetchCities(value);
      setEditTherapist((prev) => ({
        ...prev,
        location: { ...prev.location, city: '' },
      }));
    }
  };

  const handleJenisRawatanChange = (gender) => {
    setEditTherapist((prev) => ({
      ...prev,
      jenisRawatan: {
        ...prev.jenisRawatan,
        [gender]: !prev.jenisRawatan[gender],
      },
    }));
  };

  const handleRawatanLainChange = (index, value) => {
    const updatedRawatanLain = [...editTherapist.jenisRawatan.rawatanLain];
    updatedRawatanLain[index] = value;
    setEditTherapist((prev) => ({
      ...prev,
      jenisRawatan: { ...prev.jenisRawatan, rawatanLain: updatedRawatanLain },
    }));
  };

  const addRawatanLain = () => {
    setEditTherapist((prev) => ({
      ...prev,
      jenisRawatan: {
        ...prev.jenisRawatan,
        rawatanLain: [...prev.jenisRawatan.rawatanLain, ''],
      },
    }));
  };

  const removeRawatanLain = (index) => {
    setEditTherapist((prev) => ({
      ...prev,
      jenisRawatan: {
        ...prev.jenisRawatan,
        rawatanLain: prev.jenisRawatan.rawatanLain.filter(
          (_, i) => i !== index
        ),
      },
    }));
  };

  const validate = () => {
    let tempErrors = {};
    if (!editTherapist.name) tempErrors.name = 'Nama diperlukan.';
    if (!editTherapist.email) {
      tempErrors.email = 'Email diperlukan.';
    } else if (!/\S+@\S+\.\S+/.test(editTherapist.email)) {
      tempErrors.email = 'Email tidak sah.';
    }
    if (!editTherapist.phone) tempErrors.phone = 'Telefon diperlukan.';
    if (
      !editTherapist.location ||
      !editTherapist.location.state ||
      !editTherapist.location.city
    ) {
      tempErrors.location = 'Lokasi (negeri dan bandar) diperlukan.';
    }

    // Validasi Tarikh Langganan
    if (subscriptionStartDate && subscriptionEndDate) {
      const start = new Date(subscriptionStartDate);
      const end = new Date(subscriptionEndDate);
      if (end <= start) {
        tempErrors.subscriptionEndDate = 'Tarikh tamat harus selepas tarikh mula.';
      }
    } else {
      tempErrors.subscriptionDates = 'Tarikh mula dan tamat langganan diperlukan.';
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

const handleSaveEdit = async () => {
  if (!validate()) return;

  try {
    const token = localStorage.getItem('authToken');

    // Jika ada gambar profil yang ingin diupload
    const formData = new FormData();
    formData.append('name', editTherapist.name);
    formData.append('email', editTherapist.email);
    formData.append('phone', editTherapist.phone);
    formData.append('location', JSON.stringify(editTherapist.location));
    formData.append('jenisRawatan', JSON.stringify(editTherapist.jenisRawatan));
    // Tambahkan medan lain yang perlu dikemaskini

    // Jika ada gambar yang dipilih
    if (editTherapist.profileImage) {
      formData.append('profileImage', editTherapist.profileImage);
    }

    // Kemaskini maklumat terapis
    await axios.put(
      `${config.API_URL}/admin/update-therapist/${editTherapist._id}`,
      formData,
      {
        headers: {
          'x-auth-token': token,
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    // Kemaskini langganan terapis
    await axios.put(
      `${config.API_URL}/admin/therapists/${editTherapist._id}/subscription`,
      {
        subscriptionPlan,
        subscriptionStartDate,
        subscriptionEndDate,
      },
      {
        headers: {
          'x-auth-token': token,
        },
      }
    );

    // Kemas kini senarai terapis
    fetchTherapists();
    handleCloseEditModal();
    toast.success('Terapis berjaya dikemaskini.');
  } catch (error) {
    console.error('Error updating therapist:', error);
    toast.error('Ralat semasa mengemaskini terapis. Sila cuba lagi.');
  }
};



  const handleDelete = async (therapistId) => {
    if (window.confirm('Adakah anda pasti ingin memadam terapis ini?')) {
      try {
        const token = localStorage.getItem('authToken');
        await axios.delete(`${config.API_URL}/api/therapists/${therapistId}`, {
          headers: {
            'x-auth-token': token,
          },
        });
        setTherapists(
          therapists.filter((therapist) => therapist._id !== therapistId)
        );
        setFilteredTherapists(
          filteredTherapists.filter((therapist) => therapist._id !== therapistId)
        );
        toast.success('Terapis berjaya dipadam.');
      } catch (error) {
        console.error('Error deleting therapist:', error);
        toast.error('Ralat semasa memadam terapis. Sila cuba lagi.');
      }
    }
  };

  // Fungsi untuk menapis dan menyusun terapis
  useEffect(() => {
    let tempTherapists = [...therapists];

    // Penapisan berdasarkan nama
    if (filterName.trim() !== '') {
      tempTherapists = tempTherapists.filter((therapist) =>
        therapist.name.toLowerCase().includes(filterName.toLowerCase())
      );
    }

    // Penapisan berdasarkan negeri
    if (filterState !== '') {
      tempTherapists = tempTherapists.filter(
        (therapist) => therapist.location?.state === filterState
      );
    }

    // Penapisan berdasarkan telefon
    if (filterPhone.trim() !== '') {
      tempTherapists = tempTherapists.filter((therapist) =>
        therapist.phone.toLowerCase().includes(filterPhone.toLowerCase())
      );
    }

    // Penapisan berdasarkan pelan langganan
    if (filterSubscription !== 'all') {
      tempTherapists = tempTherapists.filter(
        (therapist) => therapist.subscriptionPlan === filterSubscription
      );
    }

    // Susun senarai berdasarkan pelan langganan: Plus, free_trial, Basic
    tempTherapists.sort((a, b) => {
      const planOrder = { plus: 1, free_trial: 2, basic: 3 };
      return planOrder[a.subscriptionPlan] - planOrder[b.subscriptionPlan];
    });

    setFilteredTherapists(tempTherapists);
  }, [filterName, filterState, filterPhone, filterSubscription, therapists]);

  // Fungsi untuk mengambil senarai bandar berdasarkan negeri untuk sub-tab
  const handleFetchCitiesForSubTab = async () => {
    if (selectedStateForCities.trim() === '') {
      toast.error('Sila pilih negeri untuk melihat bandar.');
      return;
    }
    try {
      const token = localStorage.getItem('authToken');
      const response = await axios.get(`${config.API_URL}/api/cities/${selectedStateForCities}`, {
        headers: {
          'x-auth-token': token,
        },
      });
      setCitiesList(response.data);
    } catch (error) {
      console.error('Error fetching cities:', error);
      toast.error('Gagal mendapatkan senarai bandar. Sila cuba lagi.');
    }
  };

  // Fungsi untuk memadam bandar
  const handleDeleteCity = async (state, city) => {
    if (window.confirm(`Adakah anda pasti ingin memadam bandar "${city}" dari negeri "${state}"?`)) {
      try {
        const token = localStorage.getItem('authToken');
        await axios.delete(`${config.API_URL}/api/cities/${state}/${city}`, {
          headers: {
            'x-auth-token': token,
          },
        });
        toast.success(`Bandar "${city}" berjaya dipadam.`);
        // Kemas kini senarai bandar dalam UI
        setCitiesList(citiesList.filter((c) => c !== city));
      } catch (error) {
        console.error('Error deleting city:', error);
        if (error.response && error.response.data && error.response.data.error) {
          toast.error(error.response.data.error);
        } else {
          toast.error('Ralat semasa memadam bandar. Sila cuba lagi.');
        }
      }
    }
  };


const formatDate = (dateString) => {
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  return new Intl.DateTimeFormat('en-GB', options).format(new Date(dateString));
};



  return (
    <div className="manage-therapists-container">
      <h2>Urus Terapis</h2>

      {/* Navigasi Sub-Tab */}
      <div className="sub-tab-container">
        <button
          className={`sub-tab-button ${activeTab === 'manageTherapists' ? 'active' : ''}`}
          onClick={() => setActiveTab('manageTherapists')}
        >
          Urus Terapis
        </button>
        <button
          className={`sub-tab-button ${activeTab === 'listOfCities' ? 'active' : ''}`}
          onClick={() => setActiveTab('listOfCities')}
        >
          Senarai Bandar
        </button>
      </div>

      {/* Kandungan Sub-Tab */}
      {activeTab === 'manageTherapists' && (
        <>
          {/* Bahagian Filter */}
          <div className="filter-container">
            <input
              type="text"
              placeholder="Cari berdasarkan Nama..."
              value={filterName}
              onChange={(e) => setFilterName(e.target.value)}
              className="filter-input"
            />
            <select
              value={filterState}
              onChange={(e) => setFilterState(e.target.value)}
              className="filter-select"
            >
              <option value="">Semua Negeri</option>
              {states.map((state, index) => (
                <option key={index} value={state}>
                  {state}
                </option>
              ))}
            </select>
            <input
              type="text"
              placeholder="Cari berdasarkan Telefon..."
              value={filterPhone}
              onChange={(e) => setFilterPhone(e.target.value)}
              className="filter-input"
            />
            <select
              value={filterSubscription}
              onChange={(e) => setFilterSubscription(e.target.value)}
              className="filter-select"
            >
              <option value="all">Semua Pelan</option>
              <option value="plus">Plus</option>
              <option value="free_trial">free_trial</option>
              <option value="basic">Basic</option>
            </select>
          </div>

          {/* Senarai Terapis */}
          <div className="therapists-list">
            {Array.isArray(filteredTherapists) && filteredTherapists.length > 0 ? (
              filteredTherapists.map((therapist) => (
                <div key={therapist._id} className="therapist-card">
                  <img
                    src={therapist.imagePublicId 
                      ? `https://res.cloudinary.com/dwewjymkr/image/upload/${therapist.imagePublicId}.jpg` 
                      : `${config.API_URL}/images/user.png`}
                    alt={`${therapist.name || 'Unknown'} profile`}
                    className="therapist-image"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = '/images/user.png';
                    }}
                  />
                  <h3 className="therapist-name">{therapist.name || 'Unknown'}</h3>
                  <div className="therapist-details">
                    <p>
                      <strong>Email:</strong> {therapist.email || 'Tidak disediakan'}
                    </p>
                    <p>
                      <strong>Telefon:</strong> {therapist.phone || 'Tidak disediakan'}
                    </p>
                    <p>
                      <strong>Lokasi:</strong> {therapist.location?.state || 'Unknown'}
                      , {therapist.location?.city || 'Unknown'}
                    </p>
                    <p>
                      <strong>Jenis Rawatan:</strong>
                    </p>
                    <ul className="treatment-list">
                      <li>
                        Lelaki: {therapist.jenisRawatan?.lelaki ? 'Ya' : 'Tidak'}
                      </li>
                      <li>
                        Wanita: {therapist.jenisRawatan?.wanita ? 'Ya' : 'Tidak'}
                      </li>
                      {Array.isArray(therapist.jenisRawatan?.rawatanLain) &&
                        therapist.jenisRawatan.rawatanLain.map((rawatan, index) => (
                          <li key={index}>{rawatan}</li>
                        ))
                      }
                    </ul>
                    <p>
                      <strong>Tarikh Daftar:</strong>{' '}
                      {therapist.createdAt
                        ? new Date(therapist.createdAt).toLocaleDateString()
                        : 'Unknown'}
                    </p>
                    <p>
                      <strong>Pelan Langganan:</strong> {therapist.subscriptionPlan || 'Tidak ditetapkan'}
                    </p>
                    <p>
                      <strong>Tarikh Mula Langganan:</strong> {therapist.subscriptionStartDate ? formatDate(therapist.subscriptionStartDate) : 'Tidak ditetapkan'}
                    </p>
                    <p>
                       <strong>Tarikh Tamat Langganan:</strong> {therapist.subscriptionEndDate ? formatDate(therapist.subscriptionEndDate) : 'Tidak ditetapkan'}
                    </p>
                  </div>
                  <div className="manage-buttons">
                    <button
                      className="manage-button edit"
                      onClick={() => handleEdit(therapist)}
                    >
                      Edit
                    </button>
                    <button
                      className="manage-button delete"
                      onClick={() => handleDelete(therapist._id)}
                    >
                      Padam
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p className="no-results">Tiada terapis dijumpai.</p>
            )}
          </div>
        </>
      )}

      {activeTab === 'listOfCities' && (
        <div className="list-of-cities-container">
          <h3>Senarai Bandar Mengikut Negeri</h3>
          <div className="city-filter-container">
            <select
              value={selectedStateForCities}
              onChange={(e) => setSelectedStateForCities(e.target.value)}
              className="city-filter-select"
            >
              <option value="">Pilih Negeri</option>
              {states.map((state, index) => (
                <option key={index} value={state}>
                  {state}
                </option>
              ))}
            </select>
            <button
              className="fetch-cities-button"
              onClick={handleFetchCitiesForSubTab}
              disabled={selectedStateForCities === ''}
            >
              Lihat Bandar
            </button>
          </div>

          {citiesList.length > 0 ? (
            <ul className="cities-list">
              {citiesList.map((city, index) => (
                <li key={index} className="city-item">
                  {city}
                  <button
                    className="delete-city-button"
                    onClick={() => handleDeleteCity(selectedStateForCities, city)}
                  >
                    Padam
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            selectedStateForCities !== '' && <p className="no-results">Tiada bandar dijumpai untuk negeri ini.</p>
          )}
        </div>
      )}

      {/* Modal Edit Terapis */}
      {isEditModalOpen && editTherapist && (
        <div className="modal-overlay" onClick={handleCloseEditModal}>
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="modal-header">
              <h2>Edit Terapis</h2>
              <span className="close-button" onClick={handleCloseEditModal}>
                &times;
              </span>
            </div>
            <div className="modal-body">
              <form>
                {/* Nama Terapis */}
                <div className="form-group">
                  <label>Nama Terapis</label>
                  <input
                    type="text"
                    name="name"
                    value={editTherapist.name || ''}
                    onChange={handleChange}
                    placeholder="Nama Terapis"
                  />
                  {errors.name && <span className="error">{errors.name}</span>}
                </div>

                {/* Email Terapis */}
                <div className="form-group">
                  <label>Email Terapis</label>
                  <input
                    type="email"
                    name="email"
                    value={editTherapist.email}
                    onChange={handleChange}
                    placeholder="Email Terapis"
                  />
                  {errors.email && (
                    <span className="error">{errors.email}</span>
                  )}
                </div>

                {/* Telefon Terapis */}
                <div className="form-group">
                  <label>Telefon Terapis</label>
                  <input
                    type="text"
                    name="phone"
                    value={editTherapist.phone}
                    onChange={handleChange}
                    placeholder="Telefon Terapis"
                  />
                  {errors.phone && (
                    <span className="error">{errors.phone}</span>
                  )}
                </div>
                 {/* Gambar Profil */}
<div className="form-group">
  <label>Gambar Profil</label>
  <input
    type="file"
    accept="image/*"
    onChange={(e) =>
      setEditTherapist((prev) => ({
        ...prev,
        profileImage: e.target.files[0],
      }))
    }
  />
</div>

                {/* Lokasi Terapis */}
                <div className="form-group">
                  <label>Negeri</label>
                  <select
                    name="state"
                    value={editTherapist.location?.state || ''}
                    onChange={handleLocationChange}
                  >
                    <option value="">Pilih Negeri</option>
                    {states.map((state, index) => (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                  <label>Bandar</label>
                  <select
                    name="city"
                    value={editTherapist.location?.city || ''}
                    onChange={handleLocationChange}
                  >
                    <option value="">Pilih Bandar</option>
                    {cities.map((city, index) => (
                      <option key={index} value={city}>
                        {city}
                      </option>
                    ))}
                  </select>
                  {errors.location && (
                    <span className="error">{errors.location}</span>
                  )}
                </div>

                {/* Jenis Rawatan */}
                <div className="form-group">
                  <label>Available for Gender:</label>
                  <div className="gender-toggle">
                    <label>
                      <span>Lelaki</span>
                      <button
                        type="button"
                        onClick={() => handleJenisRawatanChange('lelaki')}
                        className={
                          editTherapist.jenisRawatan?.lelaki ? 'active' : ''
                        }
                      >
                        {editTherapist.jenisRawatan?.lelaki ? (
                          <FaToggleOn />
                        ) : (
                          <FaToggleOff />
                        )}
                      </button>
                    </label>
                    <label>
                      <span>Wanita</span>
                      <button
                        type="button"
                        onClick={() => handleJenisRawatanChange('wanita')}
                        className={
                          editTherapist.jenisRawatan?.wanita ? 'active' : ''
                        }
                      >
                        {editTherapist.jenisRawatan?.wanita ? (
                          <FaToggleOn />
                        ) : (
                          <FaToggleOff />
                        )}
                      </button>
                    </label>
                  </div>
                </div>

                {/* Rawatan Lain */}
                <div className="form-group">
                  <label>Jenis Rawatan yang Dilakukan:</label>
                  <div className="rawatan-list">
                    {Array.isArray(editTherapist.jenisRawatan?.rawatanLain) && 
                      editTherapist.jenisRawatan.rawatanLain.map(
                        (rawatan, index) => (
                          <div key={index} className="rawatan-item">
                            <input
                              type="text"
                              value={rawatan}
                              onChange={(e) =>
                                handleRawatanLainChange(index, e.target.value)
                              }
                            />
                            <button
                              type="button"
                              onClick={() => removeRawatanLain(index)}
                            >
                              Hapus
                            </button>
                          </div>
                        )
                      )
                    }
                    <button
                      type="button"
                      onClick={addRawatanLain}
                      className="add-rawatan"
                    >
                      Tambah Rawatan
                    </button>
                  </div>
                </div>

                {/* Tarikh Pendaftaran - Read Only */}
                <div className="form-group">
                  <label>Tarikh Daftar</label>
                  <input
                    type="text"
                    name="registrationDate"
                    value={new Date(
                      editTherapist.createdAt
                    ).toLocaleDateString()}
                    readOnly
                  />
                </div>

                {/* Pelan Langganan */}
                <div className="form-group">
                  <label>Pelan Langganan</label>
                  <select
                    value={subscriptionPlan}
                    onChange={(e) => setSubscriptionPlan(e.target.value)}
                  >
                    <option value="free_trial">Percubaan</option>
                    <option value="basic">Basic</option>
                    <option value="plus">Plus</option>
                  </select>
                </div>

                {/* Tarikh Mula Langganan */}
                <div className="form-group">
                  <label>Tarikh Mula Langganan</label>
                  <input
                    type="date"
                    value={subscriptionStartDate}
                    onChange={(e) => setSubscriptionStartDate(e.target.value)}
                  />
                </div>

                {/* Tarikh Tamat Langganan */}
                <div className="form-group">
                  <label>Tarikh Tamat Langganan</label>
                  <input
                    type="date"
                    value={subscriptionEndDate}
                    onChange={(e) => setSubscriptionEndDate(e.target.value)}
                  />
                  {errors.subscriptionEndDate && (
                    <span className="error">{errors.subscriptionEndDate}</span>
                  )}
                  {errors.subscriptionDates && (
                    <span className="error">{errors.subscriptionDates}</span>
                  )}
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button className="save-button" onClick={handleSaveEdit}>
                Simpan
              </button>
              <button className="cancel-button" onClick={handleCloseEditModal}>
                Batal
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ManageTherapists;
